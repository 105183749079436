<ps-modal #companyTypeModal (onClose)="noChanges()" [closeButton]="false" disableClickOff="true">
  <div class="company-type">
    <div class="company-modal-header">
      <div> <img src="assets/img/pencil-icon.svg" alt="info"></div>
      <h4>Update Your Information</h4>
    </div>
    <div class="company-modal-body">
      <ng-container *ngIf="isContinue;else notContinue">
        <ng-container *ngIf="isNonProfit;else otherType">
          <ng-container *ngIf="progress>= count.FIVE">
            <p>Thinking of changing your company type? If you select non-profit, please update the following info:</p>
            <ol>
              <li>For Employees step: Owners and Lineal Relative Employees aren't required. So the information you
                provided <b>will be removed.</b> </li>
              <li>
                For Authorization step: Add <b>two Authorized Signers.</b>
              </li>
            </ol>
          </ng-container>
          <ng-container *ngIf="progress <= count.FOUR">
            <p>Thinking of changing your company type? If you select non-profit, please update the Employees step:
              Owners and Lineal Relative Employees aren't required. So the information you provided <b>will be
                removed.</b> </p>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <div class="company-modal-footer">
      <cta-button class="border-btn" (click)="close()" *ngIf="isContinue">Don’t change the company type</cta-button>
      <cta-button (click)="isContinue?continue():close()">{{isContinue?'Continue':'Okay'}}</cta-button>
    </div>
  </div>
</ps-modal>
<ng-template #otherType>
  <ng-container>
    <ng-container *ngIf="progress >= count.FIVE">
      <p>Thinking of changing your company type? If you are moving away from non-profit, please update the following
        info:</p>
      <ol>
        <li>For Employees step: Add <b>Owners</b> and <b>Lineal Relative Employees</b> </li>
        <li>For Authorization step: Reassign the <b>Authorized signer.</b>
        </li>
      </ol>
    </ng-container>
    <ng-container *ngIf="progress <= count.FOUR">
      <p>Thinking of changing your company type? If you are moving away from non-profit, please update the Employees
        step: Add <b>Owners</b> and <b>Lineal Relative Employees.</b> </p>
    </ng-container>
  </ng-container>
</ng-template>
<ng-template #notContinue>
  <ng-container *ngIf="isNonProfit; else nonProfitNotContinue">
    <ng-container *ngIf="progress >= count.FIVE && (currentStatus !== count.FOUR)">
      <p>Because you select non-profit, please update the following info:</p>
      <ol>
        <li>For Employees step: Owners and Lineal Relative Employees aren't required. So the information you
          provided <b>will be removed.</b> </li>
        <li>
          For Authorization step: Add <b>two Authorized Signers.</b>
        </li>
      </ol>
    </ng-container>
    <ng-container *ngIf="progress <= count.FOUR && (currentStatus !== count.FOUR)">
      <p>Because you select non-profit, please update the Employees step: Owners and Lineal Relative Employees aren't
        required. So the information you provided <b>will be removed.</b> </p>
    </ng-container>
    <ng-container *ngIf="progress >= count.FIVE && (currentStatus === count.FOUR)">
      <p>Because you select non-profit, please update the Authorization step: Add <b>two Authorized signers.</b> </p>
    </ng-container>
  </ng-container>
</ng-template>
<ng-template #nonProfitNotContinue>
  <ng-container>
    <ng-container *ngIf="progress >= count.FIVE && (currentStatus !==  count.FOUR)">
      <p>Because you are moving away from non-profit, please update the following info:</p>
      <ol>
        <li>For Employees step: Add <b>Owners</b> and <b>Lineal Relative Employees.</b> </li>
        <li>
          For Authorization step: Reassign the <b>Authorized signer.</b>
        </li>
      </ol>
    </ng-container>
    <ng-container *ngIf="progress <= count.FOUR && (currentStatus !=  count.FOUR)">
      <p>Because you are moving away from non-profit, please update the Employees step: Add <b>Owners</b> and <b>Lineal
          Relative Employees.</b> </p>
    </ng-container>
    <ng-container *ngIf="progress >= count.FIVE && (currentStatus ===  count.FOUR)">
      <p>Because you are moving away from non-profit, please update the Authorization step: Reassign the <b>Authorized signer.</b> </p>
    </ng-container>
  </ng-container>
</ng-template>