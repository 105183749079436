import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss'],
})
export class CustomDropdownComponent implements OnInit {
  constructor() {}
  @Input() control: FormControl;
  @Input() useNgModel: boolean;
  @Input() key: string | number;
  @Input() modelValue: number;
  @Input() data = [];
  @Input() placeholder: string = "Select";
  @Output() onUpdate = new EventEmitter();
  @Output() getOptionValue: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit() {
  }

  change(event) {
    this.onUpdate.emit(event);
  }

  ngModelChange(event) {
    this.getOptionValue.emit(event);
  }
}
