<div class="options">
  <button class="btn bool-rect"
          (click)="setValue(true)"
          [class.active]="value === true || control?.value === true"
          [ngbTooltip]="yesTooltip"
          [disableTooltip]="!yesTooltip"
  >YES</button>

  <button class="btn bool-rect"
          (click)="setValue(false)"
          [class.active]="value === false || control?.value === false"
          [ngbTooltip]="noTooltip"
          [disableTooltip]="!noTooltip"
  >NO</button>
</div>
