import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { finalize } from 'rxjs/internal/operators/finalize';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { ProgressService } from 'src/app/shared/services/progress.service';
import { MaskConfigs } from 'src/app/shared/validators/masks';
import { STEP, STEPS } from 'src/app/shared/constants/steps.data';
import { ApiResponse } from 'src/app/shared/enums/api-response.enum';
import { Message } from 'src/app/shared/enums/message.enum';
import { COMPANY_TYPE_STATUS } from 'src/app/shared/enums/reusable.enum';
import { Count } from 'src/app/shared/enums/common-form.enum';

@Component({
  selector: 'ps-retrieve',
  templateUrl: './retrieve.component.html',
  styleUrls: ['./retrieve.component.scss'],
})
export class RetrieveComponent implements OnInit {
  errorMsg!: string | null;
  redirectPath: string = '';
  maskConfig = MaskConfigs.ein;
  isLoading = false;
  form!: FormGroup;
  totalPercentage!: number;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private compService: CompanyService,
    private router: Router,
    private progService: ProgressService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      ein: ['', [Validators.required, Validators.pattern(/\d{2}-\d{7}/)]],
      docRefId: ['', Validators.required]
    });
    const error = this.route.snapshot.queryParams.error;
    if (error) { this.updateErrorMsg(error); }
  }

  onSubmit() {
    this.isLoading = true;
    this.errorMsg = null;
    this.authService
      .retrieveProgress(this.form.value.docRefId, this.form.value.ein)
      .pipe(
        switchMap((data) => {
          this.compService.peoName = data.peo;
          this.compService.peoId = data.peoId;
        let progress = parseInt(data.progress.replace(STEP, ''));
          if (data.status === ApiResponse.INPROGRESS) {
            if (progress > STEPS.length) {
              progress = STEPS.length;
            }
            this.progService.setProgress(progress);
            this.progService.setCompanyTypeChanged(data?.typeChangeStatus);
            this.redirectPath = this.getRedirectPath(data?.typeChangeStatus,progress)
          }
          else {
            this.redirectPath = ApiResponse.REDIRECT;
          }
          return this.compService.getCompanyInfo();
        }),finalize(() => (this.isLoading = false))
      )
      .subscribe(
        () => {
          this.router.navigate([this.redirectPath]);
          this.form.reset();
        },
        ({ error }) => {
          this.isLoading = false;
          this.updateErrorMsg(error.errorMessage);
        }
      );
  }

  updateErrorMsg(error: string = Message.SOMETHING_WENT_WRONG) {
    this.errorMsg = error;
    if (error === ApiResponse.TOKEN_EXPIRED) {
      this.errorMsg = Message.SESSION_ERROR_RETRIEVE
    }
  }

  getRedirectPath(status:number, progress:number): string | undefined{
    if((status === Count.THREE &&  this.progService.progress.getValue()<Count.THREE) || status === Count.TWO){
     return COMPANY_TYPE_STATUS[ Count.TWO]
    }
    else{
    return this.router.config.find(
       (route) => route.data && route.data.step === progress
     )?.path;
    }
   }
}
