import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ps-question-row',
  templateUrl: './question-row.component.html',
  styleUrls: ['./question-row.component.scss']
})
export class QuestionRowComponent implements OnInit {
  @Input() label;
  @Input() description;
  @Input() tooltip;

  constructor() { }

  ngOnInit() {
  }

}
