import { Component, OnInit, ViewChild, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { PlanService } from 'src/app/shared/services/plan.service';
import { IPlanDetails, IVestingData } from 'src/app/shared/models/plan-details.model';
import { MatchCalculatorComponent } from 'src/app/pages/plan-basics/match-calculator/match-calculator.component';
import { BUTTON_VALUE, CARD_LABEL, CLASS_NAME, CURRENT_STAGE, PLAN_SELECTION_TYPE, PLAN_TYPE_VALUE, TOOLTIP_MESSAGE, VESTING_LABEL } from 'src/app/shared/enums/plan-selection.enum';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { vestingModalContent } from 'src/app/pages/plan-basics/plan-cards/plan-card.data';


@Component({
  selector: 'ps-plan-cards',
  templateUrl: './plan-cards.component.html',
  styleUrls: ['./plan-cards.component.scss']
})

export class PlanCardsComponent implements OnInit, OnChanges {

  @Input() currentStep!: number;
  @Output() selectPlanOption = new EventEmitter();
  @Input() planOptions;
  @Input() presentStep;
  @Input() selectedPlanOption: IPlanDetails | null = null;
  @Input() isExpanded: boolean = false;
  @ViewChild('vesting', { static: true }) vesting!: ModalComponent;
  @ViewChild(MatchCalculatorComponent) matchCalculatorComponent!: MatchCalculatorComponent;
  itemsToShowInitially: number = 1;
  planSelectionType = PLAN_SELECTION_TYPE;
  planTypeValue = PLAN_TYPE_VALUE;
  tooltipMessage = TOOLTIP_MESSAGE;
  @Output() isExpandedParent = new EventEmitter();
  @Input() isExpandChild: boolean = false;
  isDataLoading!: boolean;
  vestingData: IVestingData[] = vestingModalContent;
  vestingDetails!: IVestingData;
  vestingLabelName: typeof VESTING_LABEL = VESTING_LABEL;
  className: typeof CLASS_NAME = CLASS_NAME;
  cardLabel: typeof CARD_LABEL = CARD_LABEL;
  currentStage = CURRENT_STAGE;
  button_value: typeof BUTTON_VALUE = BUTTON_VALUE
  @Input() activeSafeHarbor!: boolean;
  constructor(public planService: PlanService) {
    //
  }

  showFullData(): void {
    this.isExpandedParent.emit(true);
  }

  ngOnInit(): void {
  
  }

  ngOnChanges(type: SimpleChanges): void {
    this.planOptions = type.PlanOption?.currentValue || this.planOptions;
  }

  onSelectPlanOption(PlanOption): void {
    this.selectedPlanOption = PlanOption;
    this.selectPlanOption.emit(PlanOption);
  }


  openVestingPopUp(vestingLabel: string): void {
    if (!(vestingLabel === this.vestingLabelName.NOT_APPLICABLE || vestingLabel === this.vestingLabelName.CUSTOMIZE)) {
      this.vesting.open();
      this.vestingDetails = this.vestingData.find(res => res.heading.toLocaleLowerCase() === vestingLabel.toLocaleLowerCase());
    }
  }

  getLabelColor(value: string): string {
    return value === this.vestingLabelName.NOT_APPLICABLE || value === this.vestingLabelName.CUSTOMIZE ? this.className.ADD_COLOR : '';
  }

}
