import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cta-button',
  templateUrl: './cta-button.component.html',
  styleUrls: ['./cta-button.component.scss']
})
export class CtaButtonComponent {
  @Input() isLoading: boolean;
  @Input() disabled: boolean;
  @Input() invert: boolean;
  @Output() ctaClick = new EventEmitter();
}
