import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { finalize } from 'rxjs/internal/operators/finalize';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { NgbDatepickerConfig, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { PLAN_SELECTION_TYPE, STEP_LABELS, CURRENT_STAGE, PLAN_TYPE_VALUE, DEFAULT_VALUE, DEFAULT_VALUES, STEP_DESCRIPTION, OPTION_VALUE } from 'src/app/shared/enums/plan-selection.enum';
import { PlanService } from 'src/app/shared/services/plan.service';
import { DateService } from 'src/app/shared/services/date.service';
import { Question } from 'src/app/shared/services/question.service';
import { ProgressService } from 'src/app/shared/services/progress.service';
import { AUTH, EMPLOYEES, PLAN_BASICS } from 'src/app/shared/constants/steps.data';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { PlanCardsComponent } from 'src/app/pages/plan-basics/plan-cards/plan-cards.component';
import { Contributions, ContributionsService } from 'src/app/shared/services/contributions.service';
import { CompanyContributionComponent } from 'src/app/pages/plan-basics/company-contribution/company-contribution.component';
import { planOptions } from 'src/app/pages/plan-basics/plan-cards/plan-card.data';
import { STATUS } from 'src/app/shared/enums/status.enum';
import { ApiResponse } from 'src/app/shared/enums/api-response.enum';
import { Message } from 'src/app/shared/enums/message.enum';
import { Redirect } from 'src/app/shared/utils/redirect.util';
import { environment } from 'src/environments/environment';
import { QUESTION_TYPES } from 'src/app/pages/plan-basics/plan-basic-enum';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { ICalculation } from 'src/app/shared/models/calculation.models';
import { COMMON_REUSABLE_NUMBER } from 'src/app/shared/enums/common-number-validation.enum';
import { LABEL } from 'src/app/shared/enums/reusable.enum';
import { VestingSchedule, VestingService } from 'src/app/shared/services/vesting.service';

@Component({
  selector: 'ps-plan-basics',
  templateUrl: './plan-basics.component.html',
  styleUrls: ['./plan-basics.component.scss'],
  providers: [NgbDatepickerConfig]
})
export class PlanBasicsComponent implements OnInit, OnDestroy {
  isDisabled = false;
  isLoading = false;
  isSubmit = false;
  disableSafeHarbor = false;
  disableCalenderValue = false;
  previousSelectedDate!: string;
  effectiveDate!: number;
  selectedYear!: number;
  currentYear!: number;
  globalSelectedDate!: Date;
  selectedDate!: Date;
  unSelectPlan!: boolean;
  questions!: Question[];
  form!: FormGroup;
  currentStage = CURRENT_STAGE;
  currentStep = CURRENT_STAGE.CURRENT_STEP_ONE;
  isCompanyContribution!: boolean;
  planSelectionType = PLAN_SELECTION_TYPE;
  planTypeValue = PLAN_TYPE_VALUE;
  contributions!: Contributions;
  stepLabels = STEP_LABELS;
  stepDescriptions: typeof STEP_DESCRIPTION = STEP_DESCRIPTION;
  stepLabel!: string;
  stepDescription!: string;
  status!: string;
  canEdit!: boolean;
  endStatus!: string;
  message = Message;
  docRefId!: string;
  submittedDate!: string;
  editedParentType!: string;
  isParentExpand: boolean = false;
  isChildExpand: boolean = false;
  questionTypes = QUESTION_TYPES;
  public planOptions = planOptions;
  public displayPlanOptions = this.planOptions;
  selectedPlanOption = this.planOptions[0];
  isExpanded!: boolean;
  isEdit: boolean = false;
  parentOption: any = planOptions;
  parentSelectedOption = this.planOptions[0];
  childPlanOption: any = planOptions[0].childPlan
  childSelectedOption: any = this.planOptions[0].childPlan[0];
  editedChildType!: string;
  deferral: number = DEFAULT_VALUES.DEFERRAL_PERCENTAGE;
  matchPercentage: number = DEFAULT_VALUES.MATCH_PERCENTAGE;
  maximumContribution: number = DEFAULT_VALUES.MAX_CONTRIBUTION;
  reusableLabel: typeof LABEL = LABEL;
  activeSafeHarbor!: boolean;
  loadingData: boolean = true;
  vestingOptions: any;
  isShowDontSave = false;
  isSaveShow = false;
  isSavedQuarter = false;
  selectedPlanHistory = [
    {
      step: 2,
      isExpanded: false,
      selectedOption: this.selectedPlanOption,
      listOptions: this.displayPlanOptions,
    },
    {
      step: 3,
      isExpanded: false,
      selectedOption: null,
      listOptions: null,
    }
  ]
  optionValue!: number;
  removedDataList: Array<string> = [];

  public previousPlanOption = planOptions;

  constructor(
    private planService: PlanService,
    private dateService: DateService,
    private contributionService: ContributionsService,
    private router: Router,
    private progressService: ProgressService,
    private fb: FormBuilder,
    private authService: AuthService,
    private dpConfig: NgbDatepickerConfig,
    private elem: ElementRef,
    private calendar: NgbCalendar,
    private eeService: EmployeeService,
    private vestingService: VestingService
  ) {
    progressService.onSave = this.onSave.bind(this);
    const config = this.dateService.getEffectiveDateConfig(false);
    dpConfig.minDate = this.authService.isPep ? calendar.getNext(this.calendar.getToday(), 'm', 1) : config.minDate;
    dpConfig.markDisabled = config.markDisabled;
    this.initForm();
  }

  @ViewChild('confirmPopup', { static: true })
  confirmPopup!: ModalComponent;

  @ViewChild('reConfirmPopup', { static: true })
  reConfirmPopup!: ModalComponent;

  /**
   * Opens the confirm modal and sets 'isLoading' to false.
   */
  confirmModal(): void {
    this.isLoading = false;
    this.confirmPopup.open();
  }

  @ViewChild('helpPopup', { static: true })
  helpPopup!: ModalComponent;

  /**
   * Opens the help modal.
   */
  helpModal(): void {
    this.helpPopup.open();
  }

  @ViewChild(PlanCardsComponent) planCardComponent!: PlanCardsComponent;
  @ViewChild(CompanyContributionComponent) companyContributionComponent!: CompanyContributionComponent;

  /**
   * Initializes component data in the 'ngOnInit' lifecycle hook.
   */
  ngOnInit(): void {
    this.planService.getEntryScheduleOptions();
    this.initialLoadingApi();
    this.questions = this.planService.basicQuestions;
    this.planService.getPlanBasics()
      .pipe(finalize(() => this.form.valueChanges.subscribe(
        () => this.progressService.hasChanges = true
      )))
      .subscribe(data => {
        if (!data.effectiveDate) {
          this.effectiveDate = COMMON_REUSABLE_NUMBER.ZERO;
        } else {
          this.previousSelectedDate = data.effectiveDate;
          const getEffectiveDate = new Date(data.effectiveDate);
          this.effectiveDate = getEffectiveDate.getFullYear();
        }
        Object.keys(data).forEach(key => {
          if (data[key] === null) delete data[key];
        });
        this.form.patchValue(data);
      });
      this.setEntryScheduleValue();
  }

  /**
   * Sets the 'optionValue' based on the provided 'value'.
   * @param value - The value to set 'optionValue' to.
   */
  getValue(value): void {
    this.optionValue = value;
  }

  /**
   * Unsubscribes from progress service onSave and performs cleanup when the component is destroyed.
   */
  ngOnDestroy(): void {
    this.progressService.onSave = null;
    this.progressService.changedToQuarterDate$.next(false);
  }

  /**
   * Navigates to the HCE page with an optional 'step' parameter.
   * @param step - The step to navigate to (default is '2').
   */
  goToHCE(step: string | number = EMPLOYEES.step): void {
    this.isSubmit = true;
    if (this.unSelectPlan) {
      this.deletePlan();
      if (!this.isSaveShow)
      this.router.navigate([EMPLOYEES.url], { queryParams: { step } });
    }
    this.reConfirmPopup.close();
    this.onSave().subscribe(() => {
      this.isLoading = false;
      this.form.invalid;
      this.isSavedQuarter = this.isSaveShow ? true : false;
      this.fetchData();
      this.initialLoadingApi();
      this.currentStep++;
      this.stepLabel = this.stepLabels.STEP_TWO;
      this.stepDescription = this.stepDescriptions.STEP_TWO_DESCRIPTION;
      this.isSubmit = false;
      this.unSelectPlan = false;
      if (!this.isSaveShow)
      this.router.navigate([EMPLOYEES.url], { queryParams: { step } });
    });
  }

  /**
 * if there is no effective date change
 */
  noDateChange(): void {
    this.form.patchValue({ effectiveDate: this.previousSelectedDate });
    this.isLoading = false;
    this.unSelectPlan = false;
    this.reConfirmPopup.close();
    this.progressService.hasChanges = false;
  }

  /**
 * Scroll top function
 */
  scrollToSelf(timeOut?: 100): void {
    const top = this.elem.nativeElement.offsetTop;
    setTimeout(() => {
      document.querySelector('.content').scrollTo({ top, left: COMMON_REUSABLE_NUMBER.ZERO });
    }, timeOut);
  }

  /**
   * Handles form submission logic based on the current step and selected options.
   */
  onSubmit(): void {
    this.isLoading = true;
    if (this.currentStep === this.currentStage.CURRENT_STEP_ONE) {
       this.planDateChecking();
      if (this.effectiveDate !== DEFAULT_VALUE.EFFECTIVE_DATE) {
        if (this.effectiveDate == this.selectedYear && !this.unSelectPlan  || (this.isSavedQuarter && !(this.selectedYear > this.currentYear))) {
          this.loadingData = true;
          this.onSave().subscribe(() => {
            this.fetchData();
            this.stepLabel = this.stepLabels.STEP_TWO;
            this.stepDescription = this.stepDescriptions.STEP_TWO_DESCRIPTION;
            this.currentStep++;
            this.isLoading = false;
            this.form.invalid;
          });
        }
        else {
          this.openPopUpCheck();
        }
      }
      else {
        if (this.selectedYear > this.currentYear) {
          this.openPopUpCheck();
        }
        else {
          this.loadingData = true;
          this.onSave().subscribe(() => {
            this.fetchData();
            this.currentStep++;
            this.stepLabel = this.stepLabels.STEP_TWO;
            this.stepDescription = this.stepDescriptions.STEP_TWO_DESCRIPTION;
            this.isLoading = false;
            this.form.invalid;
          });
        }
      }

      this.scrollToSelf();
    }
    else if (this.currentStep === this.currentStage.CURRENT_STEP_TWO) {
      this.isDisabled = (this.isEdit && ((this.editedChildType === PLAN_SELECTION_TYPE.MATCH) || (this.editedParentType === PLAN_SELECTION_TYPE.STARTER)) && this.selectedPlanOption.value === PLAN_SELECTION_TYPE.SAFE_HARBOR);
      switch (this.selectedPlanOption.value) {
        case this.planSelectionType.SAFE_HARBOR:
          this.childPlanOption = this.planOptions[0].childPlan;
          if (!this.isEdit) {
            this.childSelectedOption = this.planOptions[0].childPlan[0];
            this.planCardComponent.isExpanded = false;
          }
          else {
            this.childSelectedOption = this.planOptions[0].childPlan.find(x => x.type === this.editedChildType);
          }
          this.displayPlanOptions = this.selectedPlanOption.childPlan;
          this.isCompanyContribution = false;
          this.currentStep++;
          this.isLoading = false;
          this.stepLabel = this.stepLabels.STEP_THREE;
          this.stepDescription = this.stepDescriptions.STEP_THREE_DESCRIPTION;
          this.selectedPlanOption = this.displayPlanOptions[0];
          this.selectedPlanHistory[1].selectedOption = this.displayPlanOptions[0];
          this.selectedPlanHistory[1].isExpanded = this.planCardComponent.isExpanded;
          this.selectedPlanHistory[1].listOptions = this.displayPlanOptions;
          this.scrollToSelf();
          break;
        case this.planSelectionType.MATCH:
          this.fetchSelectOptions(this.planSelectionType.MATCH);
          this.childPlanOption = [];
          this.childPlanOption.push(this.planOptions[1]);
          this.childSelectedOption = this.planOptions[1];
          this.displayPlanOptions = [];
          this.displayPlanOptions.push(this.selectedPlanOption);
          this.isCompanyContribution = true;
          this.currentStep++;
          this.stepLabel = this.stepLabels.STEP_THREE_MATCH;
          this.stepDescription = this.stepDescriptions.STEP_THREE_DESCRIPTION;
          this.selectedPlanOption = this.displayPlanOptions[0];
          this.selectedPlanHistory[1].selectedOption = this.displayPlanOptions[0];
          this.selectedPlanHistory[1].isExpanded = this.planCardComponent.isExpanded;
          this.selectedPlanHistory[1].listOptions = this.displayPlanOptions;
          this.isLoading = false;
          this.scrollToSelf();
          break;
        case this.planSelectionType.STARTER:
          this.saveQacaMatch(false, true);
          break;
        case this.planSelectionType.CUSTOM:
          this.confirmModal();
          break;
      }
      if (!this.planSelectionType.CUSTOM && !this.planSelectionType.STARTER) {
        this.selectedPlanOption = null;
      }
      this.form.invalid;
    }
    else if (this.currentStep === this.currentStage.CURRENT_STEP_THREE) {
      switch (this.childSelectedOption.type) {
        case this.planSelectionType.QACA_NON_ELECTIVE:
          this.saveQacaNonElective();
          break;
        case this.planSelectionType.SH_MATCH:
          this.saveQacaMatch(false, false);
          break;
        case this.planSelectionType.QACA_MATCH:
          this.saveQacaMatch(false, false);
          break;
        case this.planSelectionType.MATCH:
          this.saveMatch();
          break;
      }
      this.form.invalid
      this.progressService.hasChanges = false;
    }
    else if (this.currentStep === this.currentStage.CURRENT_STEP_FOUR) {
      this.progressService.hasChanges = false;
      this.progressService.updateProgress(AUTH.step);
      this.router.navigateByUrl(AUTH.url);
    }
    else {
      console.log("Submitting form incomplete");
    }
  }

  /**
   * Saves QACA Non-Elective contributions, deletes existing contributions if necessary, and fetches contributions.
   */
  saveQacaNonElective(): void {
    if (this.contributions.matchList.length || this.contributions.nonElectiveList.length) {
      this.progressService.onSaving$.next(true);
      const matchList = this.contributions.matchList;
      const nonElectiveList = this.contributions.nonElectiveList;
      if (matchList.length > COMMON_REUSABLE_NUMBER.ZERO) {
        const matchIdToDelete = matchList[0].id;
        this.contributionService.deletePlanMatch(matchIdToDelete)
          .subscribe({
            next: () => {
              this.planSubmitQacaNonElective();
            },
            error: () => { //
            }
          });
      }
      else {
        const nonElectiveIdToDelete = nonElectiveList[0].id;
        this.contributionService.deletePlanNonElective(nonElectiveIdToDelete)
          .subscribe({
            next: () => {
              this.planSubmitQacaNonElective();
            },
            error: () => {
              //
            }
          });
      }
    }
    else {
      this.planSubmitQacaNonElective();
    }
  }

  /**
   * Saves QACA Match contributions, deletes existing contributions if necessary.
   */
   saveQacaMatch(isCustom: boolean, isEnd: boolean): void {
    if (this.contributions.matchList.length || this.contributions.nonElectiveList.length) {
      this.progressService.onSaving$.next(true);
      const matchList = this.contributions.matchList;
      const nonElectiveList = this.contributions.nonElectiveList;
      if (matchList.length > COMMON_REUSABLE_NUMBER.ZERO) {
        const matchIdToDelete = matchList[0].id;
        this.contributionService.deletePlanMatch(matchIdToDelete)
          .subscribe({
            next: () => {
              if (isCustom) {
                this.planSubmitCustomMatch();
              }
              else {
                this.planSubmitQacaMatch(isEnd);
              }
            },
            error: () => {
              this.progressService.onSaving$.next(false);
              //
            }
          });
      }
      else {
        const nonElectiveIdToDelete = nonElectiveList[0].id;
        this.contributionService.deletePlanNonElective(nonElectiveIdToDelete)
          .subscribe({
            next: () => {
              if (isCustom) {
                this.planSubmitCustomMatch();
              }
              else {
                this.planSubmitQacaMatch(isEnd);
              }
            },
            error: () => {
              //
              this.progressService.onSaving$.next(false);
            }
          });
      }
    }
    else {
      if (isCustom) {
        this.planSubmitCustomMatch();
      }
      else {
        this.planSubmitQacaMatch(isEnd);
      }
    }
  }

  /**
   * Saves Match contributions, deletes existing contributions if necessary.
   */
  saveMatch(): void {
    if (this.contributions.matchList.length || this.contributions.nonElectiveList.length) {
      this.progressService.onSaving$.next(true);
      const matchList = this.contributions.matchList;
      const nonElectiveList = this.contributions.nonElectiveList;
      if (matchList.length > COMMON_REUSABLE_NUMBER.ZERO) {
        const matchIdToDelete = matchList[0].id;
        this.contributionService.deletePlanMatch(matchIdToDelete)
          .subscribe({
            next: () => {
              this.planSubmitMatch();
            },
            error: () => {
              //
            }
          });
      }
      else {
        const nonElectiveIdToDelete = nonElectiveList[0].id;
        this.contributionService.deletePlanNonElective(nonElectiveIdToDelete)
          .subscribe({
            next: () => {
              this.planSubmitMatch();
            },
            error: () => {
              //
            }
          });
      }
    }
    else {
      this.planSubmitMatch();
    }
  }

  /**
   * Submits the plan for QACA Non-Elective contributions.
   */
  async planSubmitQacaNonElective(): Promise<void> {
    await this.contributionService.planSubmission({
      type: this.planCardComponent.selectedPlanOption.type,
      percent: this.planCardComponent.selectedPlanOption.percent,
      vestingId: this.planCardComponent.selectedPlanOption.vesting.id
    }).toPromise();
    this.isLoading = false;
    this.currentStep++;
    this.progressService.onSaving$.next(false);

  }

  /**
 * Submits the plan for QACA Match contributions.
 */
  async planSubmitQacaMatch(isEnd?: boolean): Promise<void> {
    const res = await this.contributionService.planSubmissionMatch({
      formula: {
        id: this.planCardComponent.selectedPlanOption.formula.id,
        label: this.planCardComponent.selectedPlanOption.formula.label,
        tiers: this.planCardComponent.selectedPlanOption.formula.tiers,
      },
      formulaId: this.planCardComponent.selectedPlanOption.formulaId,
      id: this.planCardComponent.selectedPlanOption.id,
      matchLimit: this.planCardComponent.selectedPlanOption.matchLimit,
      type: this.planCardComponent.selectedPlanOption.type,
      vestingId: this.planCardComponent.selectedPlanOption.vesting.id
    }).toPromise();
    this.isLoading = false;
    if (isEnd) {
      this.progressService.updateProgress(AUTH.step);
      this.router.navigateByUrl(AUTH.url);
      this.progressService.onSaving$.next(false);
    }
    else {
      this.currentStep++;
      this.progressService.onSaving$.next(false);
      this.scrollToSelf();
    }
  }

  /**
* Submits the plan for Custom Match contributions.
*/
  async planSubmitCustomMatch(): Promise<void> {
    const res = await this.contributionService.planSubmissionMatch({
      formula: {
        id: this.planCardComponent.selectedPlanOption.formula.id,
        label: this.planCardComponent.selectedPlanOption.formula.label,
        tiers: this.planCardComponent.selectedPlanOption.formula.tiers,
      },
      formulaId: this.planCardComponent.selectedPlanOption.formulaId,
      id: this.planCardComponent.selectedPlanOption.id,
      matchLimit: this.planCardComponent.selectedPlanOption.matchLimit,
      type: this.planCardComponent.selectedPlanOption.type,
      vestingId: this.planCardComponent.selectedPlanOption.vesting.id
    }).toPromise();
    this.confirmPopup.close();
    this.planCardComponent.selectedPlanOption = null;
    const data = await this.planService.getPlanStatus().toPromise();
    this.canEdit = data.status === ApiResponse.INPROGRESS;
    this.endStatus = data.status;
    this.submittedDate = data.timestamps.submitted;
    this.docRefId = data.docRefId;
    this.helpModal();
  }

  /**
 * Submits the plan for Match contributions.
 */
  async planSubmitMatch(): Promise<void> {
    const selectedVestingId = Number(this.companyContributionComponent.selectedVestingId);
    const res = await this.contributionService.planSubmissionMatch({
      formula: {
        label: this.childSelectedOption.formula.label,
        tiers: [
          {
            from: 0,
            to: this.companyContributionComponent.deferral,
            matchPercent: this.companyContributionComponent.matchPercentage
          }
        ]
      },
      id: this.childSelectedOption.id,
      matchLimit: this.childSelectedOption.matchLimit,
      type: this.childSelectedOption.type,
      vestingId: selectedVestingId
    }).toPromise();
    this.isLoading = false;
    this.currentStep++;
    this.progressService.onSaving$.next(false);
    this.scrollToSelf();
  }

  /**
   * Retrieves contributions and updates the 'contributions' property.
   */
  async getContribution(): Promise<void> {
    const res = await this.contributionService.getContributions().toPromise();
    this.contributions = res;
    if (res) {
      this.savedPlanOption();
    }
  }

  /**
  * Here checking the plan effective date for safeHarbor
  */
  planDateChecking(): void {
    const currentDate = new Date();
    const startOfRange = new Date(currentDate.getFullYear(), COMMON_REUSABLE_NUMBER.NINE, COMMON_REUSABLE_NUMBER.TWO);
    const endOfRange = new Date(currentDate.getFullYear(), COMMON_REUSABLE_NUMBER.ELEVEN, COMMON_REUSABLE_NUMBER.THIRTY_ONE);
    if (this.disableCalenderValue && (this.globalSelectedDate >= startOfRange && this.globalSelectedDate <= endOfRange && this.selectedPlanOption)) {
      this.progressService.changedToQuarterDate$.next(true);
      this.unSelectPlan = true;
      return;
    }
    else {
      this.progressService.changedToQuarterDate$.next(false);
      this.unSelectPlan = false;
    }
  }

  /**
   * Confirms custom plan submission based on the provided value.
   * @param value - The boolean value indicating whether the custom plan is selected for submission.
   */
  confirmCustomPlanSubmission(value: boolean): void {
    this.isSubmit = true;
    const isSelectedValue = value;
    if (isSelectedValue) {
      this.saveQacaMatch(true, true);
      this.progressService.updateProgress(AUTH.step);
    }
  }

  /**
   * Updates the selected plan based on the current step.
   */
  updateSelectedPlan(): void {
    this.currentStep;
  }

  /**
   * Initializes the form with an 'effectiveDate' field and subscribes to its value changes.
   */
  initForm(): void {
    this.form = this.fb.group({
      effectiveDate: ['', Validators.required],
      entrySchedule: [''],
    });
    this.form.get('effectiveDate').valueChanges.subscribe((newValue) => {
      this.selectedDate = new Date(newValue);
      this.globalSelectedDate = this.selectedDate;
      this.selectedYear = this.selectedDate.getFullYear();
      const currentDate = new Date();
      this.currentYear = currentDate.getFullYear();
      this.planDateChecking();
    });
  }

  /**
   * Returns an Observable for saving plan basics by calling 'updatePlanBasics' with the form value.
   */
  onSave(): Observable<any> {
    return this.planService.updatePlanBasics({
      ...this.form.value,
    });
  }

  /**
   * Handles the selection of a plan option and updates relevant properties based on the current step.
   * @param selectedOption - The selected plan option.
   */
  onSelectPlanOption(selectedOption): void {
    this.isDisabled = false;
    if ((this.contributions.matchList.length || this.contributions.nonElectiveList.length) && (this.editedChildType !== selectedOption)) {
      this.progressService.hasChanges = true;
    }
    if (this.currentStep == CURRENT_STAGE.CURRENT_STEP_TWO) {
      this.parentSelectedOption = selectedOption;
    }
    else {
      this.childSelectedOption = selectedOption;
    }
    this.selectedPlanOption = selectedOption;
    switch (this.currentStep) {
      case CURRENT_STAGE.CURRENT_STEP_TWO:
        this.selectedPlanHistory[0].selectedOption = selectedOption;
        this.selectedPlanHistory[0].isExpanded = this.planCardComponent.isExpanded;
        this.selectedPlanHistory[0].listOptions = this.displayPlanOptions;
        break;
      case CURRENT_STAGE.CURRENT_STEP_THREE:
        this.selectedPlanHistory[1].selectedOption = selectedOption;
        this.selectedPlanHistory[1].isExpanded = this.planCardComponent.isExpanded;
        this.selectedPlanHistory[1].listOptions = this.displayPlanOptions;
        break;
      default:
        break;
    }
  }

  /**
   * Handles the expansion of plan options and updates relevant properties based on the current step.
   * @param event - The event indicating the expansion state.
   */
  expand(event): void {
    if (this.currentStep === CURRENT_STAGE.CURRENT_STEP_TWO) {
      this.isParentExpand = event;
    }
    else {
      this.isChildExpand = event;
    }
  }

  /**
* SafeHarbor plan disable event
*/
  safeHarborDisabledStatus(event): void {
    this.disableSafeHarbor = event;
  }

  /**
   * Moves to the previous step and updates relevant properties and options.
   */
  prevStep(): void {
    this.isDisabled = false;
    if (this.currentStep === CURRENT_STAGE.CURRENT_STEP_THREE) {
      this.isChildExpand = false;
      this.stepLabel = this.stepLabels.STEP_TWO;
      this.stepDescription = this.stepDescriptions.STEP_TWO_DESCRIPTION;
    }
    if (this.currentStep == CURRENT_STAGE.CURRENT_STEP_THREE && (this.selectedPlanOption.type == this.planSelectionType.MATCH)) {
      this.currentStep--;
      this.planCardComponent.isExpanded = true;
    }
    else if (this.currentStep > CURRENT_STAGE.CURRENT_STEP_ONE) {
      if (this.currentStep === CURRENT_STAGE.CURRENT_STEP_FOUR) {
        this.initialLoadingApi();
      }
      this.currentStep--;
      switch (this.currentStep) {

        case CURRENT_STAGE.CURRENT_STEP_TWO:
          this.displayPlanOptions = this.selectedPlanHistory[0].listOptions;
          this.planCardComponent.isExpanded = this.selectedPlanHistory[0].isExpanded;
          this.selectedPlanOption = this.selectedPlanHistory[0].selectedOption;
          break;

        case CURRENT_STAGE.CURRENT_STEP_THREE:
          setTimeout(() => {
            this.displayPlanOptions = this.selectedPlanHistory[1].listOptions;
            this.planCardComponent.isExpanded = this.selectedPlanHistory[1].isExpanded;
            this.childSelectedOption = this.selectedPlanHistory[1].selectedOption;
          }, 1000);
          break;
      }
    }
  }

  /**
   * Clears session storage and redirects to the Slavic 401k URL.
   */
  returnToSlavic(): void {
    sessionStorage.clear();
    Redirect.replace(environment.slavic401kUrl);
  }

  /**
   * Determines and sets the saved plan option based on contributions.
   */
  savedPlanOption(): void {
    this.editedParentType = PLAN_SELECTION_TYPE.SAFE_HARBOR;
    if (this.contributions.matchList.length || this.contributions.nonElectiveList.length) {
      this.isEdit = true;
      const matchList = this.contributions.matchList;
      const nonElectiveList = this.contributions.nonElectiveList;
      if (matchList.length > COMMON_REUSABLE_NUMBER.ZERO) {
        this.editedChildType = matchList[0].type;
        if (matchList[0].type == this.planSelectionType.QACA_MATCH) {
          this.editedParentType = PLAN_SELECTION_TYPE.SAFE_HARBOR;
          this.disableCalenderValue = true;
          this.displayPlanOptions = this.planOptions;
          this.selectedPlanOption = this.planOptions[0];
          this.parentSelectedOption = this.planOptions[0];
          this.childPlanOption = this.planOptions[0].childPlan;
          this.childSelectedOption = this.planOptions[0].childPlan[0];
          this.isExpanded = false;
        }
        else if (matchList[0].type == this.planSelectionType.SH_MATCH) {
          this.editedParentType = PLAN_SELECTION_TYPE.SAFE_HARBOR;
          this.disableCalenderValue = true;
          this.displayPlanOptions = this.planOptions;
          this.selectedPlanOption = this.planOptions[0];
          this.parentSelectedOption = this.planOptions[0];
          this.childPlanOption = this.planOptions[0].childPlan;
          this.childSelectedOption = this.planOptions[0].childPlan[1];
          this.isExpanded = true;
        }
        else if (matchList[0].type == this.planSelectionType.MATCH) {
          this.editedParentType = PLAN_SELECTION_TYPE.MATCH;
          this.displayPlanOptions = this.planOptions;
          this.selectedPlanOption = this.planOptions[1];
          this.parentSelectedOption = this.planOptions[1];
          this.isExpanded = true;
          this.matchPercentage = this.contributions.matchList[0].formula.tiers[0].matchPercent;
          this.deferral = this.contributions.matchList[0].formula.tiers[0].to;
          this.maximumContribution = this.contributions.matchList[0].formula.maxEmployerContribRate;
        }
        else if (matchList[0].type == this.planSelectionType.STARTER) {
          this.editedParentType = PLAN_SELECTION_TYPE.STARTER;
          this.displayPlanOptions = this.planOptions;
          this.selectedPlanOption = this.planOptions[2];
          this.parentSelectedOption = this.planOptions[2];
          this.isExpanded = true;
        }
        else if (matchList[0].type == this.planSelectionType.CUSTOM) {
          this.displayPlanOptions = this.planOptions;
          this.selectedPlanOption = this.planOptions[3];
          this.parentSelectedOption = this.planOptions[3];
          this.isExpanded = true;
        }
      }
      else if (nonElectiveList.length > COMMON_REUSABLE_NUMBER.ZERO) {
        this.editedChildType = nonElectiveList[0].type;
        if (nonElectiveList[0].type == this.planSelectionType.QACA_NON_ELECTIVE) {
          this.editedParentType = PLAN_SELECTION_TYPE.SAFE_HARBOR;
          this.disableCalenderValue = true;
          this.displayPlanOptions = this.planOptions;
          this.selectedPlanOption = this.planOptions[0];
          this.parentSelectedOption = this.planOptions[0];
          this.childPlanOption = this.planOptions[0].childPlan;
          this.childSelectedOption = this.planOptions[0].childPlan[2];
          this.isExpanded = true;
        }
      }
    }
    else {
      this.selectedPlanOption = this.planOptions[0];
    }
  }

  /**
 * Plan deletion from matchList or nonElectiveList
 */
  deletePlan(): void {
    const matchList = this.contributions.matchList;
    const nonElectiveList = this.contributions.nonElectiveList;
    if (matchList.length > COMMON_REUSABLE_NUMBER.ZERO) {
      const matchIdToDelete = matchList[0].id;
      this.contributionService.deletePlanMatch(matchIdToDelete)
        .subscribe({
          next: () => {
            this.deleteAuthorization();
            this.progressService.regressToStep(PLAN_BASICS.step);
          },
          error: () => {
            //
          }
        });
    }
    else {
      const nonElectiveIdToDelete = nonElectiveList[0].id;
      this.contributionService.deletePlanNonElective(nonElectiveIdToDelete)
        .subscribe({
          next: () => {
            this.deleteAuthorization();
            this.progressService.regressToStep(PLAN_BASICS.step);
          },
          error: () => {
            //
          }
        });
    }
  }

  /**
* this is for the bulk deletion of authUser and signer
*/
  deleteAuthorization(): void {
    this.planService.getAuthUsers().subscribe((data) => {
      if (data) {
        this.removedDataList.push(data.authUser[0].contactId);
        data.signer?.forEach((el)=> this.removedDataList.push(el.contactId))
        // data.authUser?.map((authUser) => {
        //   this.removedDataList.push(authUser?.contactId)
        // })
        // this.removedDataList.push(data?.signer?.contactId);
        this.eeService.deleteContactList(this.removedDataList).subscribe(() => { });
      }
    });
  }

  /**
  * get latest calculation value. 
   */
  matchCalculationChanged($event: ICalculation): void {
    this.deferral = $event.deferral;
    this.matchPercentage = $event.matchPercentage;
    this.maximumContribution = $event.maximumContribution
  }

  fetchData(): void {
    this.loadingData = true;
    this.planService.getPlanBasics().subscribe(data => {
      const getEffectiveDate = new Date(data.effectiveDate);
      const currentDate = new Date();
      const octDisableFromDate = new Date(currentDate.getFullYear(), COMMON_REUSABLE_NUMBER.NINE, COMMON_REUSABLE_NUMBER.TWO);
      const decDisableToDate = new Date(currentDate.getFullYear(), COMMON_REUSABLE_NUMBER.ELEVEN, COMMON_REUSABLE_NUMBER.THIRTY_ONE);
      if (getEffectiveDate >= octDisableFromDate && getEffectiveDate <= decDisableToDate) {
        this.activeSafeHarbor = true;
        this.disableSafeHarbor = true;
      }
      else {
        this.activeSafeHarbor = false;
        this.disableSafeHarbor = false;
      }
      this.loadingData = false;
    });
  }


  initialLoadingApi(): void {
    this.loadingData = true;
    const planDetails = this.contributionService.getContributions();
    forkJoin([planDetails]).subscribe(res => {
      this.contributions = res[0];
      if (res[0]) {
        this.savedPlanOption();
      }
      this.loadingData = false;
    })
  }

  fetchSelectOptions(type: string): void {
    this.loadingData = true;
    this.vestingService.getVestingOptions(type).subscribe(
      (options: VestingSchedule[]) => {
        this.vestingOptions = options.filter(item => Object.values(OPTION_VALUE).includes(item.id));
        this.loadingData = false;
      },
      (error: string) => {
        this.loadingData = false;
      }
    );
  }

  /* 
  Return selected sh and date b/w quarter
  */
  planDateCheckingForOpenPopUp(): boolean {
    const currentDate = new Date();
    const startOfRange = new Date(currentDate.getFullYear(), COMMON_REUSABLE_NUMBER.NINE, COMMON_REUSABLE_NUMBER.TWO);
    const endOfRange = new Date(currentDate.getFullYear(), COMMON_REUSABLE_NUMBER.ELEVEN, COMMON_REUSABLE_NUMBER.THIRTY_ONE);
    return this.editedParentType === PLAN_SELECTION_TYPE.SAFE_HARBOR && (this.globalSelectedDate >= startOfRange && this.globalSelectedDate <= endOfRange);
  }
  /* 
   Add popup based on selected plan and date
  */
  openPopUpCheck(): void {
    const prevYear = new Date(this.previousSelectedDate)?.getFullYear();
    if (this.isEdit && (this.planDateCheckingForOpenPopUp()) && this.currentYear !== prevYear && this.selectedYear === this.currentYear) {
      this.setPopupValue(true, false);
      this.reConfirmPopup.open();
    }
    else if (this.isEdit && (this.planDateCheckingForOpenPopUp()) && (this.selectedYear === this.currentYear && this.currentYear === prevYear)) {
      this.setPopupValue(true, true);
      this.reConfirmPopup.open();
    }
    else if ((!this.isEdit && (this.currentYear !== this.selectedYear)) || (this.effectiveDate !== DEFAULT_VALUE.EFFECTIVE_DATE && (prevYear !== this.selectedYear))) {
      this.setPopupValue(false, false);
      this.reConfirmPopup.open();
    }
  }
  /* 
   Set popup based on selected plan and date
  */
  setPopupValue(isShowDontSave: boolean, isSaveShow: boolean): void {
    this.isShowDontSave = isShowDontSave;
    this.isSaveShow = isSaveShow;
  }

  setEntryScheduleValue(): void{
    this.planService.entrySchedule.subscribe(val=>{
      this.form.patchValue({ entrySchedule: val});
    })
  }
}

