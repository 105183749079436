export enum COMMON_REUSABLE_NUMBER {
  ZERO = 0,
  NINETY = 90,
  NINE = 9,
  TWO = 2,
  THREE = 3,
  ELEVEN = 11,
  THIRTY_ONE = 31,
  HUNDRED = 100,
  HUNDRED_K = 100000
};