export enum Message {
  SOMETHING_WENT_WRONG = "Something went wrong",
  SESSION_ERROR_IDENTIFY = "We've logged you out for security reason. Please retrieve your progress with reference code and EIN.",
  SESSION_ERROR_RETRIEVE = "You’ve been logged out due to inactivity. Please retrieve your progress with your EIN and the reference code that was sent to you via email.",
  CUSTOM_END_TITLE = "Help is on the way!",
  CUSTOM_END_TEXT = "We have already sent your information to a Plan Design Consultant for follow-up, and someone will be in touch with you within two business days.",
  CONFIRMATION_TITLE ="Your 401(k) plan has been submitted. Further action is required. ",
  CONFIRMATION_TEXT = "Your 401(k) online plan setup application has been submitted. You will receive a link to your Adoption Agreement and other documents via email within the next few minutes. Please <b>complete the electronic signature</b> at your earliest convenience to prevent any delays in the 401(k) plan setup process.",
  RETRIEVE = "retrieve",
  EMPTY_PATH = "",
  START_LINK = "start_link",
  RETRIEVE_LINK = "retrieve_link",
  BREADCRUMB = "breadcrumb",
  MONTHLY = "Monthly"
}