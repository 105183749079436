import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/internal/operators/finalize';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { MaskConfigs } from 'src/app/shared/validators/masks';
import { ProgressService } from 'src/app/shared/services/progress.service';
import { STEP, STEPS } from 'src/app/shared/constants/steps.data';
import { ApiResponse } from 'src/app/shared/enums/api-response.enum';
import { Message } from 'src/app/shared/enums/message.enum';
import { PlanService } from 'src/app/shared/services/plan.service';
import { COMPANY_TYPE_STATUS } from 'src/app/shared/enums/reusable.enum';
import { Count } from 'src/app/shared/enums/common-form.enum';

@Component({
  selector: 'ps-identify',
  templateUrl: './identify.component.html',
  styleUrls: ['./identify.component.scss'],
})
export class IdentifyComponent implements OnInit {
  form!: FormGroup;
  isLoading!: boolean;
  showPasscode: boolean = true;
  isVideoVisited!: boolean;
  callingByReportId!: boolean;
  errorMsg!: string;
  maskConfig = MaskConfigs.ein;
  token!: string;
  redirectPath: string = '';
  planRefId: string = '';
  ein: string = '';
  isRetrieveLoading!: boolean;
  status!: string;
  statuses = ApiResponse;
  totalPercentage;
  isStartOverLoading!: boolean
  @ViewChild(ModalComponent, { static: true }) modal!: ModalComponent;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private compService: CompanyService,
    private progService: ProgressService,
    private planService: PlanService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      repId: [null],
      passcode: ['', [Validators.required]],
      ein: ['', [Validators.required, Validators.pattern(/\d{2}-\d{7}/)]],
    });
    this.token = this.route.snapshot.queryParams['token'];
    const passcode = this.route.snapshot.queryParams['passcode'];

    if (passcode) {
      this.form.get('passcode').setValue(passcode);
      this.form.get('passcode').updateValueAndValidity();
      this.showPasscode = false;
      this.router.navigate(['/']);
    }

    this.route.queryParams.subscribe(({ error }) => {
      if (error) this.updateErrorMsg(error);
    });
  }

  onSubmit(): void {
    this.isLoading = true;
    this.errorMsg = '';
    this.planService.status = undefined;
    this.authService.resetApp();
    this.authService
      .identify(this.form.value)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (data) => {
          this.setData(data);
        },
        ({ error }) => {
          this.showPasscode = true;
          this.updateErrorMsg(error.errorMessage);
        }
      );
  }

  startNewProgress(): void {
    this.isStartOverLoading = true;
    this.router.navigate(['/company-info']);
    setTimeout(() => {
      this.isStartOverLoading = false;
    }, 2000);
  }

  retrieve(): void {
    if (this.token) {
      this.isRetrieveLoading = true;
      this.authService
        .retrieveProgress(this.planRefId)
        .pipe(
          switchMap((data) => {
            this.compService.peoName = data.peo;
            this.compService.ein = data.ein;
            this.compService.peoId = data.peoId;
            let progress = parseInt(data.progress.replace(STEP, ''));
            if (data.status === ApiResponse.INPROGRESS) {
              if (progress > STEPS.length) {
                progress = STEPS.length;
              }
              this.progService.setProgress(progress);
              this.progService.setCompanyTypeChanged(data?.typeChangeStatus);
              // this.redirectPath = (data?.typeChangeStatus === Count.TWO || data?.typeChangeStatus == Count.THREE ) ? COMPANY_TYPE_STATUS[data?.typeChangeStatus] :
              // this.router.config.find(
              //   (route) => route.data && route.data.step === progress
              // )?.path;
              this.redirectPath = this.getRedirectPath(data?.typeChangeStatus,progress);
            }
            else {
              this.redirectPath = ApiResponse.REDIRECT;
            }
            return this.compService.getCompanyInfo();
          })
        )
        .subscribe(
          () => {
            this.router.navigate([this.redirectPath]);
            this.isRetrieveLoading = false;
          },
          ({ error }) => {
            this.isRetrieveLoading = false;
            this.callingByReportId = false;
            this.modal.close();
            this.updateErrorMsg(error.errorMessage);
          }
        );
    } else {
      this.router.navigate(['/retrieve'], {
        queryParams: {
          ein: this.form.value.ein,
        },
      });
    }
  }

  updateErrorMsg(error: string = Message.SOMETHING_WENT_WRONG): void {
    this.errorMsg = error;
    if (error === ApiResponse.NEW_PROGRESS) {
      this.errorMsg = Message.SESSION_ERROR_IDENTIFY;
    }
  }

  goPlanSetup(): void {
    if (this.token) {
      this.errorMsg = '';
      this.planService.status = undefined;
      this.authService.resetApp();
      this.callingByReportId = true;
      this.authService.identifyFromReportId(this.token).subscribe(
        (data) => {
          this.setData(data);
        },
        ({ error }) => {
          this.callingByReportId = false;
          this.updateErrorMsg(error.errorMessage);
        }
      );
    } else {
      this.isVideoVisited = true;
    }
  }

  setData(data): void {
    this.compService.ein = data.ein;
    this.compService.peoId = data.peoId;
    this.compService.peoName = data.peo;
    this.planRefId = data?.planRefId;
    this.status = data.success;
    sessionStorage.setItem('repId', this.form.value.repId);
    if (data.success === ApiResponse.NEW_PROGRESS) {
      this.startNewProgress();
      setTimeout(() => {
        this.callingByReportId = false;
      }, 2000);
    } else {
      this.modal.open();
    }
  }

  getRedirectPath(status: number, progress: number): string | undefined{
    if((status === Count.THREE &&  this.progService.progress.getValue()< Count.THREE) || status === Count.TWO){
     return COMPANY_TYPE_STATUS[ Count.TWO]
    }
    else{
    return this.router.config.find(
       (route) => route.data && route.data.step === progress
     )?.path;
    }
   }
}
