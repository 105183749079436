import { IEmployeeSteps } from 'src/app/shared/models/employees.model';

export const ENTITY = 'Entity';
export const INDIVIDUAL = 'Individual';
export const YES = 'yes';
export const NORMAL_HEADER = "Remove Information";
export const NORMAL_DESC = "Are you sure you want to remove this information?";
export const HEADER_1 = "Remove this owner and their lineal relative employee?";
export const DESC_1 = "By removing this owner’s information, you will also remove any lineal relative employees associated with this owner.";
export const HEADER_2 = "Remove this owner who is an authorized signer, and their lineal relative employee?";
export const DESC_2 = "By removing this owner’s information, you will also remove this owner as an authorized signer, and any lineal relative employees associated with this owner. Please go to the Authorization page and select a new authorized signer from the dropdown list of owners and officers.";
export const HEADER_3 = "Remove this person who is an authorized signer?";
export const DESC_3 = "By removing this person’s information, you will also remove this person as an authorized signer. Please go to the Authorization page and select a new authorized signer from the dropdown list of owners and officers.";
export const TYPE = "type";

export const ACCORDION_STEPS: IEmployeeSteps[] = [
  {
    mainHead: 'Owner Information',
    subHead: 'Please enter the owner information',
    isExpand: true,
    isBackButton: false,
    isNextButton: true,
    accordianType: 1,
    isActive: true,
    step: 0
  },
  {
    mainHead: 'Lineal Relative Employees',
    subHead:
      'Please enter any employees who are lineal relatives of an individual who owns 5% of the company or more',
    isExpand: false,
    isBackButton: true,
    isNextButton: true,
    accordianType: 2,
    isActive: false,
    step: 1
  },
  {
    mainHead: 'Highly Compensated Employees',
    subHead:
      'Please enter any employees who are highly compensated employees (HCEs)',
    isExpand: false,
    isBackButton: true,
    isNextButton: true,
    accordianType: 3,
    isActive: false,
    step: 2
  },
  {
    mainHead: 'Officers',
    subHead: 'Please enter any company officers and their titles',
    isExpand: false,
    isBackButton: true,
    isNextButton: true,
    accordianType: 4,
    isActive: false,
    step: 3
  }
];

export const  PLACE_HOLDER_DATA = [{
  firstName: '1st ',
  lastName: 'Authorized Signer',
  title: 'Title',
  email: 'Email',
  isPlaceHolder: true
},
{
  firstName: '2nd ',
  lastName: 'Authorized Signer',
  title: 'Title',
  email: 'Email',
  isPlaceHolder: true
}];
