import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { concatMap, map, switchMap } from 'rxjs/operators';
import { Observable, forkJoin, of } from 'rxjs';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import {
  CONTRIBUTION_TYPES as types,
  ConType,
} from '../constants/contribution-types.data';
import { DateService } from './date.service';
import { PlanService } from './plan.service';
import { isMatch, condenseMatch, condenseNonElective } from '../utils/utils';
import { Match, NonElective } from '../models/contribution';
import { PriorPlanService } from './prior-plan.service';
import { AuthService } from './auth.service';
import { CompanyService } from "./company.service";

@Injectable({
  providedIn: 'root',
})
export class ContributionsService {
  readonly minEmployeeCountForAnyType = 10;
  isNonElectiveEnabled = false;

  constructor(
    private http: HttpClient,
    private ppService: PriorPlanService,
    private planService: PlanService,
    private dateService: DateService,
    private companyService: CompanyService,
    private authService: AuthService,
  ) {}

  getAvailableContributions(): Observable<AvailableContributions> {
    return forkJoin(
      this.planService.getPlanBasics(),
      this.ppService.getPriorPlanInfo(),
      this.companyService.getCompanyInfo(),
    ).pipe(
      map(([{ effectiveDate }, priorPlan, { company: { employeeCount } }]) => {
        const date = new Date(effectiveDate);
        const ngbEffectiveDate = new NgbDate(
          date.getFullYear(),
          date.getMonth() + 1,
          date.getDate()
        );

        const config = this.dateService.getEffectiveDateConfig(true);
        // const config = this.dateService.getEffectiveDateConfig(true, priorPlan);
        const shouldKeepPriorPlanDesign =
          priorPlan && priorPlan.questions.isActiveSH && date.getFullYear() <= new Date().getFullYear();
        let filteredTypes = !shouldKeepPriorPlanDesign
          ? types
          : {
              sh: types.sh.filter(
                ({ id }) => id === priorPlan.questions.activeShType
              ),
            };
        let requireDateUpdateForSh = config.markDisabled(ngbEffectiveDate) || ngbEffectiveDate.before(config.minDate)

        // pep logic: only safe harbor if less/equal to 10 employeesCount
        let pepNoPriorPlanNotEnoughEmployees = false;
        if (this.authService.isPep && !priorPlan && employeeCount <= this.minEmployeeCountForAnyType) {
          filteredTypes = { sh: types.sh.filter(({ id }) => id === 'shMatch') };
          pepNoPriorPlanNotEnoughEmployees = true;
          requireDateUpdateForSh = false;
        }

        return {
          types: filteredTypes,
          shConfig: config,
          requireDateUpdateForSh: requireDateUpdateForSh,
          isDiscDisabled: config.case === 'existing_sh',
          shouldKeepPriorPlanDesign,
          pepNoPriorPlanNotEnoughEmployees: pepNoPriorPlanNotEnoughEmployees,
        };
      })
    );
  }

  getContributions(): Observable<Contributions> {
    return this.http.get<Contributions>('/plan/contributions');
  }

  planSubmission(body: any){
    return this.http.post('/plan/non-elective', body);
  }

  deletePlanMatch(id) {
    return this.http.delete(`/plan/match/${id}`);
  }

  deletePlanNonElective(id) {
    return this.http.delete(`/plan/non-elective/${id}`);
  }

  planSubmissionMatch(body: any){
    return this.http.post('/plan/match', body);
  }

  upsertContribution(cont, originalType: string) {
    const isOriginMatch = isMatch(originalType);
    const isNewMatch = isMatch(cont.type);
    const body = isNewMatch ? condenseMatch(cont) : condenseNonElective(cont);

    const post = isNewMatch
      ? this.http.post('/plan/match', body)
      : this.http.post('/plan/non-elective', body);

    if (!originalType) {
      return post;
    }

    const deleteObs = isOriginMatch
      ? this.deleteMatch(cont.id)
      : this.deleteNonElective(cont.id);
    // delete existing then add match
    return deleteObs.pipe(switchMap(() => post));
  }

  deleteAllContributions(): Observable<any> {
    return this.getContributions().pipe(
      map(res => [...res.nonElectiveList, ...res.matchList]),
      concatMap(contributions => this.deleteContributionByArray(contributions)),
    );
  }

  deleteContribution(cont) {
    if (isMatch(cont.type)) {
      return this.deleteMatch(cont.id);
    } else {
      return this.deleteNonElective(cont.id);
    }
  }

  deleteContributionByArray(contributions: (Match | NonElective)[]): Observable<boolean> {
    if (!contributions.length) {
      return of(false);
    } else {
      return this.deleteContribution(contributions[0]).pipe(
        map(() => true)
      );
    }
  }

  deleteMatch(id) {
    return this.http.delete(`/plan/match/${id}`);
  }

  deleteNonElective(id) {
    return this.http.delete(`/plan/non-elective/${id}`);
  }

  getAutoEnroll() {
    return this.http.get<AutoEnroll>('/plan/auto-enroll');
  }

  updateAutoEnroll(body) {
    return this.http.put('/plan/auto-enroll', body);
  }

  updateQacaAutoEnrollRate(autoDeferralRate) {
    return this.updateAutoEnroll({
      enabled: true,
      autoEnrollNewEeOnly: false,
      autoDeferralRate,
    });
  }

  resetAutoEnroll(): Observable<any> {
    return this.updateAutoEnroll({
      enabled: true,
      autoEnrollNewEeOnly: false,
      autoDeferralRate: 3,
    });
  }

  getContributionStat(): Observable<ContributionStat> {
    return this.getContributions().pipe(
      map(({ matchList, nonElectiveList }) => {
        const count = matchList.length + nonElectiveList.length;

        const hasQaca = []
          .concat(matchList)
          .concat(nonElectiveList)
          .some(({ type }) => /qaca/.test(type));

        return {
          count,
          hasQaca,
          contributions: { matchList, nonElectiveList },
        };
      })
    );
  }
}

export interface AutoEnroll {
  erId?: number;
  enabled: boolean;
  autoDeferralRate: string;
  autoEnrollNewEeOnly: boolean;
}

export interface AvailableContributions {
  types: ContributionTypes;
  shConfig: {
    case: string;
    minDate: NgbDate;
    markDisabled: (d: NgbDateStruct) => boolean;
  };
  requireDateUpdateForSh: boolean;
  isDiscDisabled: boolean;
  shouldKeepPriorPlanDesign: boolean;
  pepNoPriorPlanNotEnoughEmployees: boolean;
}

export interface ContributionStat {
  count: number;
  hasQaca: boolean;
  contributions: {
    matchList: Match[];
    nonElectiveList: NonElective[];
  };
}

export interface Contributions {
  matchList: Match[];
  nonElectiveList: NonElective[];
}
export interface ContributionTypes {
  [key: string]: ConType[];
}
