import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { finalize } from 'rxjs/internal/operators/finalize';
// import { CardGroupConfig } from 'src/app/card-group/card-group-config';
import {
  NORMAL_DESC,
  NORMAL_HEADER,
  PLACE_HOLDER_DATA
} from 'src/app/shared/constants/accordion-steps.data';
import { REVIEW } from 'src/app/shared/constants/steps.data';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { CommonFormType, Count, ERROR_MESSAGE } from 'src/app/shared/enums/common-form.enum';
import {
  NAME_VALIDATORS,
  TITLE_VALIDATORS
} from 'src/app/shared/validators/field-validators';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { PlanService } from 'src/app/shared/services/plan.service';
import { ProgressService } from 'src/app/shared/services/progress.service';
import { IAuthData, IAuthUser } from 'src/app/shared/models/auth-user.model';
import { FieldValidators } from 'src/app/shared/validators/validators';
import { FieldLength } from 'src/app/shared/validators/field-length';
import { CompanyService } from 'src/app/shared/services/company.service';

@Component({
  selector: 'ps-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss'],
})
export class AuthorizationComponent implements OnInit, OnDestroy {
  @ViewChild('commonForm', { static: true }) commonForm!: ModalComponent;
  @ViewChild('removePopup', { static: true }) removePopup!: ModalComponent;
  formType: number = 0;
  adminInformationForm!: ModalComponent;
  authPlanAdmin = [];
  authSigner = [];
  removedDataIndex!: number;
  isLoading!: boolean;
  removeModalHeader!: string;
  formTypes = CommonFormType;
  removeModalDesc!: string;
  signerOptions = [];
  errorMsg!: string;
  authorizationForm: FormGroup;
  isDeleting!: boolean;
  authUser: FormArray = new FormArray<any>([], [Validators.required]);
  signer!: FormGroup;
  authorizationData!: IAuthData;
  isNonProfit!: boolean;
  checkingIsNonProfit!: boolean;
  nonProfitSignerData = [];
  placeHolderData = PLACE_HOLDER_DATA;
  isSigner!: boolean;

  constructor(
    private planService: PlanService,
    private router: Router,
    private eeService: EmployeeService,
    private progressService: ProgressService,
    private fb: FormBuilder,
    public companyService: CompanyService
    // cgConfig: CardGroupConfig
  ) {
    // cgConfig.showLabel = true;
    // cgConfig.compact = false;
    progressService.onSave = this.onSave.bind(this);
    this.authorizationForm = this.fb.group({
      firstName: ['', NAME_VALIDATORS],
      lastName: ['', NAME_VALIDATORS],
      email: ['', [FieldValidators.email, Validators.required, Validators.maxLength(FieldLength.EMAIL)]],
      fullName: [''],
      title: ['', TITLE_VALIDATORS],
      relativeContactId: [0]
    });
  }

  ngOnInit(): void {
    this.checkCompanyType();
    this.initSigners();
  }

  ngOnDestroy(): void {
    this.progressService.onSave = null;
    this.signerOptions = [];
  }

  /**
 * Updates the 'authorizationForm' with the value corresponding to the selected 'event' 
 * and resets the 'email' field.
 * @param event - The selected event (number).
 */
  patchFormGroup(event: string): void {
    this.authorizationForm.patchValue(
      this.signerOptions.find((option) => option.fullName === event).values
    );
    this.authorizationForm.get('email').reset();
    this.progressService.hasChanges = true;
  }

/**
 * Deletes a contact if it has a 'contactId' and subscribes to the deletion process.
 * @param item - The contact item to be deleted.
 */
  private deleteContact(item): void {
    if (item.contactId) {
      this.eeService.deleteContact(item.contactId).pipe().subscribe();
    }
  }
  
  /**
   * Deletes the signer by calling 'deleteContact' and sets 'signer' to null.
   */
  deleteSigner(): void {
    this.deleteContact(this.signer.value);
    this.signer = null;
  }

  /**
 * Opens a delete confirmation popup if the provided 'index' is valid, setting the popup's data and displaying it.
 * @param index - The index of the item to be deleted.
 */
  openDeletePopUp(index?: number): void {
    if (index !== null && index >= 0 && index < this.authPlanAdmin.length) {
      this.removedDataIndex = index;
      this.removeModalHeader = NORMAL_HEADER;
      this.removeModalDesc = NORMAL_DESC;
      this.removePopup?.open();
    } else {
      console.error('Invalid index.');
    }
  }

/**
 * Checks for changes in the signer options and resets form controls if the relativeContactId is not present in the options.
 */
  private checkSignerChange() {
    if (this.signerOptions && this.authorizationForm.value) {
      const { email, firstName, fullName, lastName } = this.authorizationForm.controls;
      const { relativeContactId } = this.authorizationForm.value;
      const isRelativeContactIdNotPresent = this.signerOptions.every(option =>
        option?.values?.relativeContactId !== relativeContactId
      );
      if (isRelativeContactIdNotPresent) {
        email.reset();
        firstName.reset();
        fullName.reset();
        lastName.reset();
      }
    }
  }

  /**
 * Resets the signer by updating 'authUser' and 'signer' in 'authorizationData', then sending the update to 'planService'.
 * @returns {Promise<void>} - A promise that resolves when the operation is complete.
 */
  async resetSigner(): Promise<void> {
    try {
      this.authorizationData.authUser = this.authPlanAdmin;
      this.authorizationData.signer = this.authorizationForm.value;
      await this.planService.updateUsers(this.authorizationData.authUser, this.authorizationData.signer).toPromise();
    } catch (error) {
      console.error('An error occurred while updating signer:', error);
    }
  }

 /**
 * Closes the remove confirmation popup and resets the 'removedDataIndex' to null.
 */
  closeRemovePopup() :void{
    this.removePopup.close();
    this.removedDataIndex = null;
  }

  /**
 * Deletes data based on the 'removedDataIndex' if it's valid, and closes the remove confirmation popup.
 */
  deleteData() {
    if (
      this.removedDataIndex >= 0 &&
      this.removedDataIndex < this.authPlanAdmin.length
    ) {
      this.isDeleting = true;
      const contactToDelete = this.authPlanAdmin[this.removedDataIndex];
      if (contactToDelete) {
        this.deleteContact(contactToDelete);
        this.authPlanAdmin.splice(this.removedDataIndex, 1);
        this.isDeleting = false;
        this.removePopup.close();
      } else {
        console.error('Invalid contact to delete.');
        this.isDeleting = false;
      }
    } else {
      console.error('Invalid removedDataIndex.');
    }
  }

  /**
 * Submits the form if 'continueEnabled' is true, then navigates to the 'REVIEW' step.
 */
  onSubmit(): void {
    if (!this.continueEnabled) {
      return;
    }
    this.progressService.onSaving$.next(true);
    this.onSave().subscribe(() => {
      this.progressService.setCompanyTypeStatus(0);
      this.progressService.setCompanyTypeChanged('0')
      this.progressService.updateProgress(REVIEW.step);
      this.progressService.onSaving$.next(false);
      this.router.navigate([REVIEW.url]);
    }),()=>{
      this.progressService.onSaving$.next(false);
    };
  }
  
  /**
 * Handles the saving of common form data by updating or pushing to 'authPlanAdmin' and closing the form modal.
 * @param event - An object containing 'index' and 'formData'.
 */
  commonFormSave(event: { index: string | number; formData: any }): void {
    if (event.index != undefined) {
      this.isSigner ? this.nonProfitSignerData[event.index] = event.formData : this.authPlanAdmin[event.index] = event.formData;
    } else {
      this.isSigner ? this.nonProfitSignerData.push(event.formData) : this.authPlanAdmin.push(event.formData);
    }
    this.progressService.hasChanges = true;
    this.closeFormModal();
  }

  /**
 * Saves the data by calling 'planService.updateUsers' with 'authUser' and 'signer' from the form.
 * @returns {Observable<any>} - An observable representing the save operation.
 */
  private onSave(): Observable<any> {
    const payload = {
      authUser: this.authPlanAdmin,
      signer: this.isNonProfit ? this.nonProfitSignerData.filter(element => !element?.isPlaceHolder) : [this.authorizationForm.value]
    };
    this.isLoading = true;
    this.errorMsg = null;
    return this.planService.updateUsers(payload.authUser, payload.signer).pipe(
      finalize(() => {
        this.progressService.hasChanges = true;
        this.isLoading = false;
      })
    );
  }

  /**
 * Patches the 'authorizationForm' with data provided as arguments.
 * @param contactId - The contact ID.
 * @param firstName - The first name.
 * @param lastName - The last name.
 * @param email - The email address.
 * @param title - The title.
 * @param relativeContactId - The relative contact ID.
 */
  private patchSigner({ contactId, firstName, lastName, email, title, relativeContactId }): void {
    this.authorizationForm = this.fb.group({
      contactId,
      firstName: [firstName, NAME_VALIDATORS],
      lastName: [lastName, NAME_VALIDATORS],
      email: [email, [FieldValidators.email, Validators.required, Validators.maxLength(FieldLength.EMAIL)]],
      fullName: [`${firstName} ${lastName}`],
      title: [title, TITLE_VALIDATORS],
      relativeContactId: [relativeContactId || 0]
    });
    this.authorizationForm.setValue(this.authorizationForm.value);
    this.authorizationForm.valueChanges.subscribe(
      () => (this.progressService.hasChanges = true)
    );
  }

/**
 * Initializes signer and authentication users by fetching data from 'planService' and handling changes.
 */
  private initSignerAndAuthUsers(): void {
    this.planService
      .getAuthUsers()
      .pipe(
        finalize(() =>
          this.authUser.valueChanges.subscribe(
            () => (this.progressService.hasChanges = true)
          )
        )
      )
      .subscribe(
        (data) => {
          if (data) {
            this.authorizationData = data;
            this.patchAuthUsers(data.authUser);
            if (data.signer && !this.isNonProfit) {
              this.patchSigner(data.signer[0]);
            }
            if (this.isNonProfit)
              this.checkSignerPlaceHolderValue(data.signer);
          }
          else
          this.checkSignerPlaceHolderValue();
        },
        (error) => (this.errorMsg = error.error.message)
      );
  }

/**
 * Opens a form for editing with optional index and editData parameters.
 * @param index - The index for editing.
 * @param editData - The data to be edited.
 */
  openForm(isSigner: boolean, index?: number, editData?: {}): void {
    this.isSigner = this.eeService.isSignerForm = isSigner;
    if (editData) {
      const data = { editData, type: this.formTypes.AUTH_PLAN_ADMIN, index };
      this.eeService.editFormData.next(data);
    }
    this.eeService.editDataIndex = index;
    this.eeService.isSignerForm = isSigner
    this.commonForm.open();
  }

/**
 * Closes the form modal and notifies 'eeService' that the form modal is closed.
 */
  closeFormModal(): void {
    this.commonForm.close();
    this.eeService.formModalClosed.next(true);
  }

/**
 * Patches the 'authPlanAdmin' array with data from 'authUsers'.
 * @param authUsers - An array of authentication user data.
 */
  private patchAuthUsers(authUsers: IAuthUser[]): void {
    authUsers.forEach(({ contactId, firstName, lastName, title, email }) => {
      const fg = this.fb.group({
        contactId,
        firstName: [firstName, NAME_VALIDATORS],
        lastName: [lastName, NAME_VALIDATORS],
        title: [title, TITLE_VALIDATORS],
        email: [email, [FieldValidators.email, Validators.required, Validators.maxLength(FieldLength.EMAIL)]],
      });
      this.authPlanAdmin.push(fg.value);
    });
  }

 /**
 * Initializes signers by fetching data from 'eeService.getHCEs' and mapping it to 'signerOptions'.
 * Calls 'checkSignerChange' after initialization.
 */
  private async initSigners() {
    try {
      const { owners = [], officers = [] } = await this.eeService.getHCEs().toPromise();
      const hces = [...owners, ...officers];
  
      this.signerOptions = hces.map((ee) => {
        ee.relativeContactId = ee.contactId;
        delete ee.contactId;
        return {
          fullName: `${ee.firstName} ${ee.lastName}`,
          values: ee,
          label: `${ee.firstName} ${ee.lastName}${ee.title ? '・' + ee.title : ''}`,
          key: `${ee.firstName} ${ee.lastName}`,
          value:`${ee.firstName} ${ee.lastName}${ee.title ? '・' + ee.title : ''}`
        };
      });
    } catch (error) {
      console.error('Error fetching HCEs:', error);
    }
    this.checkSignerChange();
  }

/**
 * Returns a boolean indicating whether the 'continue' button should be enabled based on form and data validity.
 */
  get continueEnabled(): boolean {
    return (
      this.authPlanAdmin.length > 0 &&
      ((this.authorizationForm &&
        this.authorizationForm.valid) || (this.nonProfitSignerData.filter(element => !element?.isPlaceHolder).length == Count.TWO))
    );
  }

/**
 * Returns an error tooltip message based on form and data validity.
 */
  get continueErrorTooltip(): string {
    let error = 'Please provide the required information';
    if (!this.authorizationForm) {
      error = 'Please select a signer.';
    } else if (!this.authorizationForm.valid) {
      error = 'Please complete all fields for the signer.';
    } else if (this.authPlanAdmin.length === 0) {
      error = 'You need at least one authorized admin for your plan.';
    } else if (!this.authPlanAdmin) {
      error = 'Please complete all fields for the authorized admin.';
    }
    return error;
  }

  get continueErrorForTooltip(): string {
    return  this.isNonProfit ? ERROR_MESSAGE.IS_NON_PROFIT_ERROR : ERROR_MESSAGE.NOT_NON_PROFIT_ERROR;
  }

  checkCompanyType(): void {
    this.checkingIsNonProfit = false
    this.companyService.isNonProfitType().subscribe(res => {
      this.initSignerAndAuthUsers();
      this.isNonProfit = res;
      this.checkingIsNonProfit = true;
      if(this.progressService.categoryStatusType.getValue() === Count.FOUR )
      this.progressService.openCompanyChangePopUp(res);
    })
  }

  checkSignerPlaceHolderValue(data?: IAuthUser[] | null): void {
    if (data?.length === Count.ZERO || !data) {
      this.nonProfitSignerData = this.placeHolderData;
    }
    else {
      for (let i = Count.ZERO; i <= Count.ONE; i++) {
        if (data[i]) {
          const signer = {
            contactId: data[i]?.contactId,
            firstName: data[i]?.firstName,
            lastName: data[i]?.lastName,
            title: data[i]?.title,
            email: data[i]?.email,
          };
          this.nonProfitSignerData.push(signer);
        }
        else {
          this.nonProfitSignerData.push(this.placeHolderData[i])
        }
      }
    }
  }
}
