import { Component, ElementRef, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { trigger, transition, style, animate, group, animateChild, query } from '@angular/animations';

@Component({
  selector: 'ps-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in-out', style({ opacity: 0.5 }))
      ]),
      transition(':leave', [
        style({ opacity: 0.5 }),
        animate('300ms ease-in-out', style({ opacity: 0 }))
      ]),
    ]),
    trigger('fadeInUp', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(40%)' }),
        group([
          animate('200ms ease-in-out', style({ opacity: 1 })),
          animate('300ms ease-in-out', style({ transform: 'translateY(0)' }))
        ])
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('300ms ease-in-out', style({ opacity: 0, transform: 'translateY(40%)' }))
      ]),
    ]),
    trigger('animateChild', [
      transition(':enter, :leave', [
        query('@*', animateChild())
      ])
    ])
  ]
})
export class ModalComponent {
  show = false;
  @Input() closeButton = true;
  @Input() maxWidth = '600px';
  @Input() disableClickOff: boolean;
  @Input() compact = false;
  @Output() onClose = new EventEmitter();
  @ViewChild('wrapper') wrapper: ElementRef;

  constructor(
    private elRef: ElementRef,
  ) { }

  onWrapperClick(e?) {
    if (e === undefined || (e.target === this.wrapper.nativeElement && !this.disableClickOff)) {
      this.close();
    }
  }

  close() {
    this.show = false;
    this.onClose.emit();
  }

  open() {
    this.show = true;
  }

}
