import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlanService } from 'src/app/shared/services/plan.service';
import { ICheckpoint } from 'src/app/pages/confirmation/confirmation-interface';
import { REVIEW } from 'src/app/shared/constants/steps.data';
import { Message } from 'src/app/shared/enums/message.enum';
import { environment } from 'src/environments/environment';
import { Redirect } from 'src/app/shared/utils/redirect.util';

@Component({
  selector: 'ps-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
  docRefId: string;
  status: string;
  canChange: boolean;
  loading: boolean = true;
  submittedDate: Date;
  message = Message;
  checkpoints: ICheckpoint[] = [
  {
    id: 'submitted',
    label: 'Application Submitted',
  },
  {
    id: 'review',
    label: 'Application Review',
  },
  {
    id: 'signature',
    label: 'Worksite Signature',
  },
  {
    id: 'setup',
    label: 'Plan Setup',
  },
  {
    id: 'complete',
    label: '',
  },
    ];
  visibleCheckpoints = this.checkpoints.slice(0, -1);

  constructor(private planService: PlanService, private router: Router) {}

  /**
 * Initializes the component and fetches plan status data.
 */
  ngOnInit() {
    this.loading = true;
    this.planService
      .getPlanStatus()
      .subscribe(({ docRefId, planStatus, timestamps }) => {
        this.loading = false;
        this.docRefId = docRefId;
        // this.helpCardText = this.getConfirmationMessage('confirmation');
        this.submittedDate = timestamps.submitted;
        this.checkpoints[0].date = timestamps.submitted;
      });
  }
  // private getConfirmationMessage(status: string): ITextFactory {
  //   return textFactory[status];
  // }

  /**
 * Redirects the user to the home page of the application.
 */
  goHome() {
    sessionStorage.clear();
    Redirect.replace(environment.slavic401kUrl);
  }

  /**
 * Withdraws the submission and navigates the user to the review page.
 */
  withdrawAndEdit() {
    this.planService
      .withdrawSubmission()
      .subscribe(() => Redirect.replace(REVIEW.url));
  }
}
