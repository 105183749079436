<div class="text-instructive">
  <h4>
    <span class="icn-img">
      <img src="assets/img/star.svg"/>
    </span>Choose Custom Plan Design
  </h4>
  <p>
    <span>Are you sure none of the available plan design options will meet your needs? If so, please click Submit below and a Plan Advisor Representative will contact you for a plan design consultation within 2 business days .</span>
  </p>
</div>
<div class="modal-footer">
  <div class="modal-footer__cta">
    <cta-button [isLoading]="isLoading" (ctaClick)="confirmPlanSubmission(true)">Submit</cta-button>
  </div>
</div>
