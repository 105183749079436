import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MaskConfigs } from "src/app/shared/validators/masks";
import { IrsLimitsService } from "src/app/shared/services/irs-limits.service";
import { ICalculation, ICalculationResponse, IEmpSalaries, IRSLimits } from "src/app/shared/models/irs-limits.model";
import { ELEMENT_ID, PLAN_SELECTION_TYPE, PLAN_TYPE_VALUE, SLIDER } from "src/app/shared/enums/plan-selection.enum";
import { IPlanDetails } from "src/app/shared/models/plan-details.model";
import { COMMON_REUSABLE_NUMBER } from "src/app/shared/enums/common-number-validation.enum";
import { IEmployeeSalary } from "src/app/shared/models/calculation.models";

@Component({
  selector: "ps-match-calculator",
  templateUrl: "./match-calculator.component.html",
  styleUrls: ["./match-calculator.component.scss"]
})
export class MatchCalculatorComponent implements OnInit {
  @Input() planType: IPlanDetails;
  @Input() matchDeferral: number;
  @Input() matchPercentage: number;
  @Input() maxContribution: number;
  employees = new FormArray([]);
  maskConfigs = MaskConfigs;
  irsLimits: IRSLimits;
  sliderCount: number = SLIDER.SLIDER_COUNT;
  planTypeValue = PLAN_TYPE_VALUE;
  planSelectionType = PLAN_SELECTION_TYPE;
  isCalculated: boolean;
  calculatedData!: ICalculationResponse;
  tableHeader: number[] = [];
  @Output() calculationCalled: EventEmitter<boolean> = new EventEmitter<boolean>();
  isCalculatedDataLoading: boolean;
  isRightBorderNone: boolean;
  commonReusableNumber: typeof COMMON_REUSABLE_NUMBER = COMMON_REUSABLE_NUMBER;
  amountData: number[] = [];
  greaterAmount: boolean;
  lesserAmount: boolean;

  constructor(private irsLimitsService: IrsLimitsService) {
    //
  }

  ngOnInit(): void {
    this.getEmployeeCountBySalary();
  }

  getEmployeeCountBySalary(): void {
    this.irsLimitsService.getEmployeeCountBySalary().subscribe((res: IEmpSalaries[]) => {
      res.forEach((element: IEmpSalaries) => {
        this.addSalary(element);
      });
    });
  }

  addSalary(data?: IEmpSalaries): void {
    this.employees.push(new FormGroup({
      amount: new FormControl(data ? data.amount.toString() : null, [Validators.required]),
      empCount: new FormControl(data ? data.empCount.toString() : null, [Validators.required])
    }));
  }

  delete(index: number): void {
    this.employees.removeAt(index);
  }

  clearCalculator(): void {
    while (this.employees.length > COMMON_REUSABLE_NUMBER.ZERO) {
      this.employees.removeAt(COMMON_REUSABLE_NUMBER.ZERO);
    }
    this.sliderCount = COMMON_REUSABLE_NUMBER.NINETY;
  }

  onUpdateSlider(sliderPercentage: number): void {
    this.sliderCount = sliderPercentage;
  }

  getCalculation(): void {
    if (!this.employees.valid || this.employees.length == COMMON_REUSABLE_NUMBER.ZERO) {
      return;
    }
    this.employees.value.map(x => {
      x.amount = +x.amount.replace(/,/g, "");
      x.empCount = +x.empCount.replace(/,/g, "");
    });
    this.isCalculatedDataLoading = true;
    let payload: ICalculation = {
      empSalaries: this.employees.value,
      maxEmployerContribRate: this.planType.value === this.planSelectionType.MATCH ? this.maxContribution : +this.planType.percent,
      participationRate: this.planType.value === this.planTypeValue.SF_NONELECTIVE ? COMMON_REUSABLE_NUMBER.HUNDRED : this.sliderCount
    };
    this.amountData = this.employees.value.map((data: IEmployeeSalary) => data.amount);
    this.greaterAmount = this.amountData.every(data => data > COMMON_REUSABLE_NUMBER.HUNDRED_K);                                                                                                                                     
    this.lesserAmount = this.amountData.every(data => data <= COMMON_REUSABLE_NUMBER.HUNDRED_K);
    this.irsLimitsService.getTaxCredit(payload).subscribe((res: ICalculationResponse) => {
      this.calculatedData = res;
      this.tableHeader = [];
      this.calculatedData.years.map(year => {
        this.tableHeader.push(year.year);
        this.onResize();
      });
      this.calculationCalled.emit();
      this.isCalculatedDataLoading = false;
      this.isCalculated = true;
    });
  }

  onScroll(event: Event): void {
    const element = event.target as HTMLElement;
    if (element.scrollLeft + element.offsetWidth >= (element.scrollWidth) - COMMON_REUSABLE_NUMBER.THREE) {
      this.isRightBorderNone = false;
    }
    else {
      this.isRightBorderNone = true;
    }
  }

  onResize(): void {
    setTimeout(() => {
      const tableElement = document.getElementById(ELEMENT_ID.TABLE_ELEMENT);
      const divElement = document.getElementById(ELEMENT_ID.DIV_ELEMENT);
      if (divElement?.getBoundingClientRect()?.width < tableElement?.getBoundingClientRect()?.width) {
        this.isRightBorderNone = true;
      }
      else {
        this.isRightBorderNone = false;
      }
    }, COMMON_REUSABLE_NUMBER.HUNDRED);
  }
}
