import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IEmployeeSteps } from '../../models/employees.model';

@Component({
  selector: 'ps-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit, OnChanges {
  constructor() {}
  @Input() accordionData: IEmployeeSteps;
  @Output() backClicked = new EventEmitter();
  @Output() nextClicked = new EventEmitter();
  @Input() disableNextButton: boolean;
  @Input() isLoadingHCE: boolean;

  ngOnInit() {}

  goBack(step: number): void {
    this.backClicked.emit(step - 1);
  }

  goNext(step: number): void {
    this.nextClicked.emit(step + 1);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.disableNextButton =
      changes?.disableNextButton?.currentValue || this.disableNextButton;
  }
}
