import { Component, Input, Output, EventEmitter } from '@angular/core';
import { isMatch } from '../../utils/utils';

@Component({
  selector: 'ps-contribution-card',
  template: ` <h5 class="contribution__title">
      {{ cont.label }}
      <div class="actions">
        <a
          class="edit"
          routerLink="/employer-contributions/edit"
          *ngIf="showActions"
          >Edit</a
        >
        <a class="remove" (click)="onRemove.emit(cont)" *ngIf="showActions"
          >Remove</a
        >
      </div>
    </h5>
    <p *ngIf="!isMatch">
      The company will contribute <b>{{ cont.percent }}%</b> of the
      participant's compensation.
    </p>
    <div class="row">
      <div
        class="contribution__data col-12 col-md-6 mb-3"
        *ngIf="isMatch"
      >
        <label>MATCH FORMULA</label>
        <span>{{ cont.formula.label }}</span>
      </div>
      <div class="contribution__data col-12 col-md-6 mb-3">
        <label>VESTING SCHEDULE</label>
        <!-- <span>{{ cont.vesting.label }}</span> -->
      </div>
      <div
        class="contribution__data col-12 col-md-6 mb-3"
        *ngIf="isMatch && cont.matchLimit > 0"
      >
        <label>ANNUAL MATCH LIMIT</label>
        <span>{{ cont.matchLimit | currency: '$':'symbol':'1.0-0' }}</span>
      </div>
    </div>`,
  styleUrls: ['./contribution-card.component.scss'],
})
export class ContributionCardComponent {
  @Input() set contribution(cont) {
    this.cont = cont;
    this.isMatch = isMatch(cont.type);
  }
  @Input() showActions;
  @Output() onRemove = new EventEmitter();

  cont;
  isMatch: boolean;
}
