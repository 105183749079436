<div [@animation]="'in'" *ngIf="show">
  <div class="backdrop" (click)="clickOff.emit()"></div>
  <div class="summary">
    <div class="company" *ngIf="progress > 1">
      <h5 class="company__name title">{{company?.company.name}}</h5>
      <p class="company__ein">EIN {{ein | ein}}</p>
      <div class="info-list" *ngIf="companyInfoList.length">
        <span *ngFor="let info of companyInfoList">{{info}}</span>
      </div>
      <div class="contact" *ngIf="company?.contact">
        <div class="form-group">
          <label>Contact</label>
          <span>{{company.contact.firstName}} {{company.contact.lastName}}</span>
        </div>
        <div class="form-group">
          <label>Email</label>
          <span>{{company.contact.email}}</span>
        </div>
      </div>
    </div>
    <div class="employees" *ngIf="progress > 2">
      <hr>
      <h6>{{hces.length}} {{hces.length > 1 ? 'HCEs' : 'HCE'}}</h6>
      <ul>
        <li *ngFor="let hce of hces | slice:0:hceSliceEnd">{{hce}}</li>
        <li class="btn" (click)="toggleAllHces()" *ngIf="hces.length > 4">
          <span *ngIf="!showAllHces">
            <i class="fas fa-ellipsis-h"></i>
          </span>
          <span *ngIf="showAllHces">
            <i class="fas fa-chevron-up"></i>
          </span>
        </li>
      </ul>
    </div>
    <div class="plan-design" *ngIf="progress > 4">
      <hr>
      <h6>Plan Design</h6>
      <div>
        <div class="form-group">
          <label>Effective Date</label>
          <span class="text-primary">{{effDate}}</span>
        </div>
        <div class="form-group">
          <label>Entry Schedule</label>
          <span>{{entrySchedule | entrySchedule}}</span>
        </div>
      </div>
      <div class="info-list" *ngIf="planReqList.length">
        <span *ngFor="let info of planReqList">{{info}}</span>
      </div>
      <div *ngIf="progress > 5">
        <div class="contribution" *ngFor="let match of matches">
          <h6 class="title">{{match.label}}</h6>
          <ul class="formula">
            <li *ngFor="let t of match.formula.tiers">
              <p class="match-pct">{{t.matchPercent}}% of</p>
              <p class="large-pct">{{t.to - t.from}}%</p>
            </li>
          </ul>
          <!-- <p class="contribution__vesting">w/ {{match.vesting.label | titlecase}}</p> -->
        </div>
        <div class="contribution" *ngFor="let nonEl of nonElectives">
          <h6 class="title">{{nonEl.label}}</h6>
          <div class="text-center">
            <p class="large-pct">{{nonEl.percent}}%</p>
          </div>
          <!-- <p class="contribution__vesting">w/ {{nonEl.vesting.label | titlecase}}</p> -->
        </div>
      </div>
    </div>
  </div>
</div>
