import { Question } from '../services/question.service';

export const SERVICE_REQ_DESC =
  'The required length of employment for eligible employees';
export const SERVICE_REQ_DESC_HOURS =
  'The required length of employment for eligible employees. This selection assumes all employees work at least 1000 hours per year';

export const BASIC_QUESTIONS: Question[] = [
  // {
  //   id: 'effectiveDate',
  //   type: 'date',
  //   label: 'Plan Effective Date',
  //   description: 'The date when employees can start enrolling into the plan',
  //   enabled: true,
  // },
  // {
  //   id: 'entrySchedule',
  //   type: 'select',
  //   options: [],
  //   label: 'Entry Schedule',
  //   description:
  //     'How often newly eligible employees are allowed to enter the plan (options may be restricted by the plan parameters)',
  //   enabled: true,
  // },
  // {
  //   id: 'serviceRequirement',
  //   type: 'select',
  //   options: [
  //     // { value: '0', label: 'No service requirement' },
  //     // { value: '1', label: '1 month' },
  //     { value: '3', label: '3 months' },
  //     { value: '6', label: '6 months' },
  //     { value: '12', label: '12 months' },
  //     { value: '12/1000', label: '12 months & 1,000 hours' },
  //   ],
  //   label: 'Service Requirement',
  //   description: SERVICE_REQ_DESC,
  //   enabled: true,
  // },
  // {
  //   id: 'ageRequirement',
  //   type: 'select',
  //   options: [
  //     { value: '0', label: 'No age requirement' },
  //     { value: '18', label: '18 years old' },
  //     { value: '21', label: '21 years old' },
  //   ],
  //   label: 'Age Requirement',
  //   description: 'The minimum age for employees to become eligible',
  //   enabled: true,
  // },
  // {
  //   id: 'requirementWavier',
  //   type: 'boolean',
  //   label: 'Requirement Waiver',
  //   description:
  //     'Age, service, and entry requirements will be waived for current employees',
  //   enabled: true,
  // },
  // {
  //   id: 'allEnoughHours', // mapped to hasMissingHours
  //   type: 'boolean',
  //   label: 'Hours Worked',
  //   description: 'Do all employees work at least 1000 hours per year?',
  //   enabled: false,
  // },

  {
    id: 'effectiveDate',
    type: 'date',
    label: 'Plan Effective Date',
    description: 'The date the plan becomes active and contributions can begin. Options are limited to the 1st and 15th of the month with a minimum 10 business day lead time.',
    enabled: true,
  },
  {
    id: 'entrySchedule',
    type: 'readonly',
    label: 'Entry Schedule',
    description: 'How often newly eligible employees are allowed to enter the plan.',
    value: 'Monthly',
    enabled: true,
  },
  {
    id: 'entrySchedule',
    type: 'readonly',
    label: 'Service Requirement',
    description: 'The required length of employment for eligible employees.',
    value: '3 Months',
    enabled: true,
  },
  {
    id: 'entrySchedule',
    type: 'readonly',
    label: 'Age Requirement',
    description: 'The minimum age for employees to become eligible.',
    value: '18 Years Old',
    enabled: true,
  },
  {
    id: 'entrySchedule',
    type: 'readonly',
    label: 'Enrollment Method',
    description: '<ul><li><span class="plan-span">To ensure compliance with The Secure Act 2.0, all plans established after 1/1/2023 must have an automatic enrollment feature in place by 1/1/2025.</span></li><li><span class="plan-span">Employees can quickly & easily opt out of being automatically enrolled at any time prior to their enrollment date, as well as up to 90 days after <a href="https://www.irs.gov/retirement-plans/plan-participant-employee/retirement-topics-automatic-enrollment" target="_blank">Learn more</a></span></li></ul>',
    value: 'Automatic enrollment',
    enabled: true,
  },
  {
    id: 'entrySchedule',
    type: 'readonly',
    label: 'Automatic Enrollment Details',
    description: 'Participants will be automatically enrolled into the plan at a starting deferral rate of 3%, and will then be automatically escalated by 1% annually until reaching a rate of 10%.',
    value: 'Start at 3% and increase 1% annually until 10%',
    enabled: true,
  },
];
