<div class="mob-show">
  <div class="user-info">
    <h5>Have saved progress?</h5>
    <a routerLink="/retrieve">Retrieve it here</a>
  </div>
</div>
<div class="head-names">
  <p class="main-instruction">
    Welcome to Slavic401k Plan Setup.
  </p>
  <a class="vde-btn" *ngIf="isVideoVisited" (click)="isVideoVisited=false"><span class="play-bt"><img
        src="assets/img/Vector.png" alt="Vector"/></span>Watch video again</a>
</div>
<ng-container *ngIf="isVideoVisited; else planSetupVideoContainer">
  <div class="block">
    <h5 class="text-center mb-4 plan-name">Plan Setup</h5>
    <div class="col-12 col-lg-6 mx-auto padding-0">
      <p class="text-center text-instructive instr-cls">Before we start, please provide the following information so we
        can identify
        your company.</p>
    </div>
    <form class="col-12 col-lg-6 mx-auto padding-0 mt-16" (ngSubmit)="onSubmit()" [formGroup]="form">
      <error-message *ngIf="errorMsg">{{errorMsg}}</error-message>
      <div class="form-group" *ngIf="showPasscode">
        <label>
          <span>Company Passcode</span>
          <i class="uil uil-info-circle" placement="top" ngbTooltip="If you don’t know your company passcode, contact your payroll company for assistance."></i>
          <i class="form-error" *ngIf="form.get('passcode').touched">
             <ps-error-span [control]="form.get('passcode')"></ps-error-span>
          </i>
        </label>
        <div class="input-wrapper">
          <input type="password" placeholder="Required" class="form-control" formControlName="passcode" passwordToggle>
        </div>
      </div>
      <div class="form-group">
        <label>
          <span>EIN / Tax ID</span>
          <i class="form-error" *ngIf="form.get('ein').touched">
            <ps-error-span [control]="form.get('ein')"></ps-error-span>
          </i>
        </label>
        <input type="tel" placeholder="123456789" class="form-control" formControlName="ein" [textMask]="maskConfig">
      </div>
      <div class="text-center pt-40">
        <cta-button [isLoading]="isLoading" [disabled]="form.invalid" class="full-width identity-btn">Get Started</cta-button>
      </div>
    </form>
  </div>
</ng-container>
<ng-template #planSetupVideoContainer>
  <cta-button (click)="goPlanSetup()" [isLoading]="callingByReportId" class="start-online-btn">Start Online 401(k) Plan Setup 
    <img src="assets/img/arrow-right.svg" alt="arrow">
  </cta-button>
  <div class="video-wrapper">
    <iframe allowtransparency="true" [title]="'Wistia video player'" allowFullscreen frameborder="0" scrolling="no"
      class="wistia_embed" name="wistia_embed" src="https://fast.wistia.net/embed/iframe/551r6b0z3r"></iframe>
  </div>
</ng-template>
<ps-modal (onClose)="callingByReportId = false">
  <div class="text-instructive">
    <h4><span class="icn-img"><img src="assets/img/note.png" alt="note"/></span>Saved new plan setup in progress found</h4>
    <p>We found an unfinished plan setup for the EIN you entered. Would you like to pick up where you left off?</p>
  </div>
  <div class="modal-footer mob-split-btn">
    <div class="modal-footer-cta">
      <cta-button  class="border-btn" (click)="startNewProgress()" *ngIf="status == statuses.INPROGRESS" [isLoading]="isStartOverLoading">Start
        over</cta-button >
    </div>
    <div class="modal-footer-cta">
      <cta-button (click)="retrieve()" [isLoading]="isRetrieveLoading">Retrieve Progress</cta-button>
    </div>
  </div>
</ps-modal>