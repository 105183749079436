<section>
  <h5 class="title text-center price-title">Pricing</h5>
  <h5 class="title sub-title">401(k) Recordkeeping & Administration Fees</h5>
  <p class="intro">
    Slavic401k plans have low-cost, asset-based fees that are comparable to or
    lower than many other top-tier providers. There is an administration fee and
    asset-based fee. The annual asset fee will be based on the plan’s total
    assets.
  </p>
  <div class="slider-parent">
    <div class="slider">
      <ps-asset-slider
        [max]="sliderMax"
        [hoverText]="'est. total assets'"
        [isAmount]="true"
        [value]="estAssets"
        (updateText)="setLabelText($event)"
        (update)="setEstAssets($event)"
      ></ps-asset-slider>
    </div>
    <div class="slider-label">
      <p>$0</p>
      <p>$5m</p>
    </div>
  </div>

  <div class="mid-sect">
    <h5 class="title sub-title">401(k) Fee Breakdown</h5>
    <p class="intro">
      As shown below, 401(k) fees are billed directly to participant accounts,
      typically with little to no additional cost to the employer to offer a
      401(k) plan.
    </p>
    <div class="fee-table">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th>Participant Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="td-bld">Annual Asset Fee</td>
            <td>{{ pricing?.feePercent | number : "1.2" }}%</td>
          </tr>
          <tr>
            <td class="td-bld">Admin Fee</td>
            <td>${{ pricing?.annualAdminFee }} / year</td>
          </tr>
          <tr>
            <td class="td-bld">Loans</td>
            <td>
              ${{ pricing?.loanFee }} init + {{ pricing?.maintenanceFee }} /
              year
            </td>
          </tr>
          <tr>
            <td class="td-bld">Distribution</td>
            <td>${{ pricing?.distributionFees }} / each</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="warning-msg">
      <p>
        *If the total participant admin fee is less than $350, the employer will
        be charged the difference.
      </p>
    </div>
  </div>
</section>
<section class="funds">
  <h5 class="title sub-title">Investment Funds</h5>
  <p class="intro mb-0">
    Your plan offers access to top mutual funds from providers such as Vanguard,
    Fidelity and more. You may view a full list of the investment funds
    available for your plan
    <a
      href="https://ww2.slavic401k.com/fund-performance/?peoId={{ peoId }}"
      target="_blank"
      >here</a
    >.
  </p>
</section>
