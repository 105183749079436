<div class="block">
  <button class="btn block__edit" [routerLink]="['/plan-basics']" *ngIf="canEdit">Edit</button>
  <h6 class="section-title">Plan Specifications</h6>
  <div class="full-width">
    <div class="form-group col-12 col-md-12  pt-0">
      <label>Effective Date</label>
      <span>{{basics?.effectiveDate | date}}</span>
    </div>
    <div class="form-group col-12 col-md-12">
      <label>Entry Schedule</label>
      <span>{{basics?.entrySchedule}}</span>
    </div>
    <div class="form-group col-12 col-md-12">
      <label>Service Requirement</label>
      <span>3 months</span>
    </div>
    <div class="form-group col-12 col-md-12">
      <label>Enrollment Method</label>
      <span>Automatic enrollment</span>
    </div>
    <div class="form-group col-12 col-md-12">
      <label>Automatic Enrollment Details</label>
      <span>Start at 3% and increase 1% annually until 10%</span>
    </div>
    <div class="form-group col-12 col-md-12">
      <label>Plan Design Type</label>
      <span>{{planDesignLabel ? planDesignLabel:'N/A'}}</span>
    </div>
    <div class="form-group col-12 col-md-12">
      <label>Maximum Employer Contribution Rate</label>
      <span>{{contributionRate ? contributionRate +'%':'N/A'}}</span>
    </div>
    <div class="form-group col-12 col-md-12">
      <label>Vesting Schedule</label>
      <span>{{vestingSchedule ? vestingSchedule:'N/A'}}</span>
    </div>
  </div>
</div>