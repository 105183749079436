import { Component, OnInit } from '@angular/core';
import { PlanService } from 'src/app/shared/services/plan.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { IPricing } from 'src/app/pages/features/models/plan-features.model';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'ps-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent implements OnInit {
  assetFee: number = 0;
  sliderMax: number = 5000000;
  peoName: string;
  peoId: string;
  estAssets: number = 600000;
  pricing: IPricing;
  estTotalAssets:string = '0';
  hoverText: string;
  
  constructor(
    private decimalPipe: DecimalPipe,
    private compService: CompanyService,
    private planService: PlanService
  ) { }

  /**
 * Initializes the component when it is created.
 * - Sets the PEO name and PEO ID based on the company service.
 * - Subscribes to the estimated assets from the plan service and triggers asset pricing retrieval.
 */
  ngOnInit(): void {
    this.peoName = this.compService.peoName;
    this.peoId = this.compService.peoId;

    this.planService.estAssets.subscribe(res => {
      this.estAssets = res;
      this.getPlanAssetPricing(this.peoId, this.estAssets);
    })
  }

  /**
 * Sets the estimated assets value and triggers actions based on the new value.
 *
 * @param {string | number} val - The new estimated assets value to set.
 * @returns {void}
 */
  setEstAssets(val): void {
    this.estAssets = +val;
    this.setTotalAssets(this.estAssets);
    this.getPlanAssetPricing(this.peoId, this.estAssets);
  }

  /**
 * Formats and sets the total assets value based on the provided number.
 *
 * @param {number} val - The numeric value to set as total assets.
 * @returns {string} The formatted total assets value.
 */
  setTotalAssets(val:number):string{
    const formattedNumber = this.decimalPipe.transform(val,'1.0-0');
    return this.estTotalAssets = formattedNumber;
  }

  /**
 * Sets the hover text for a label element.
 *
 * @param {string} paramText - The text to be displayed on hover.
 */
  setLabelText(paramText:string){
    this.hoverText = paramText;
  }
  
  /**
 * Retrieves plan asset pricing data based on the provided PEO ID and estimated assets.
 *
 * @param {string} peoId - The PEO (Professional Employer Organization) ID.
 * @param {number} estAsset - The estimated assets associated with the plan.
 */
  getPlanAssetPricing(peoId, estAsset): void {
    const param = { peoId, estAsset }
    this.planService.getPlanAssetPricing(param).subscribe((res: IPricing) => {
      this.pricing = res;
    })
  }

}