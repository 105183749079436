<div class="accordion">
  <ng-container>
    <div class="accordion-item" [class.active]="accordionData.isActive">
      <h4 class="accordion-heading">{{ accordionData?.mainHead }}</h4>
      <div class="accordion-content">
        <legend class="subtitle">{{ accordionData?.subHead }}</legend>
        <ng-content></ng-content>
        <div class="next-prev-buttons"  [class.end-button]="!accordionData.isBackButton">
          <button class="back-btn" *ngIf="accordionData.isBackButton" (click)="goBack(accordionData.step)">
            Back</button>
          <cta-button *ngIf="accordionData.isNextButton" [disabled]="!disableNextButton" (click)="goNext(accordionData.step)" [class.btn-disable]="!disableNextButton"
            [isLoading]="isLoadingHCE">Save & Next</cta-button>
        </div>
      </div>
    </div>
  </ng-container>
</div>