  <ng-container *ngIf = "!useNgModel">
    <ng-select [formControl] = "control" [searchable] = "false" [clearable] = "false" [placeholder] = "placeholder" required (change) = "change($event)">
      <ng-option *ngFor = "let item of data" [value] = "item.key"><span class="tick-container" [class.tick] = "control.value === item.value"></span>
        {{item.value}}</ng-option>
    </ng-select>
  </ng-container>
  
  <!-- Display when using ngModel -->
  <ng-container *ngIf = "useNgModel">
    <ng-select [ngModel] = "modelValue" [searchable] = "false" [clearable] = "false" [placeholder] = "placeholder" required (ngModelChange) = "ngModelChange($event)">
      <ng-option *ngFor = "let item of data" [value] = "item.id">
        <span class = "tick-container" [class.tick]="modelValue === item.id"></span>
        {{ item.label }}
      </ng-option>
    </ng-select>
  </ng-container>
