import { Step } from '../models/step';

export const COMPANY_INFO: Step = {
  step: 1,
  url: '/company-info',
  path: 'company-info',
  title: 'Company Information',
};

export const EMPLOYEES: Step = {
  step: 2,
  url: '/employees',
  path: 'employees',
  title: 'Employees',
};

export const QUALIFICATION: Step = {
  step: 3,
  url: '/qualification',
  path: 'qualification',
  title: 'Qualification Questions',
};


export const PLAN_BASICS: Step = {
  step: 4,
  url: '/plan-basics',
  path: 'plan-basics',
  title: 'Plan Selection',
};


export const AUTH: Step = {
  step: 5,
  url: '/authorization',
  path: 'authorization',
  title: 'Authorization',
};

export const REVIEW: Step = {
  step: 6,
  url: '/review',
  path: 'review',
  title: 'Review',
};

export const CONFIRM: Step = {
  step: 7,
  url: '/confirmation',
  path: 'confirmation',
  title: 'Confirmation',
};


export const STEPS: Step[] = [
  COMPANY_INFO,
  EMPLOYEES,
  QUALIFICATION,
  PLAN_BASICS,
  AUTH,
  REVIEW,
  CONFIRM,
];

export const STEP = "step";