import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { NON_PROFIT } from '../constants/corptypes';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  nonProfit: BehaviorSubject<boolean>;
  isNonProfit: boolean = false;
  set peoName(val) {
    sessionStorage.setItem('peoName', val);
  }
  get peoName() {
    return sessionStorage.getItem('peoName');
  }
  set peoId(val) {
    sessionStorage.setItem('peoId', val);
  }
  get peoId() {
    return sessionStorage.getItem('peoId');
  }
  set ein(val) {
    sessionStorage.setItem('ein', val);
  }
  get ein() {
    return sessionStorage.getItem('ein')|| '';
  }
  set companyName(val) {
    sessionStorage.setItem('companyName', val);
  }
  get companyName() {
    return sessionStorage.getItem('companyName') || '';
  }
  set contact(val) {
    sessionStorage.setItem('contact', JSON.stringify(val));
  }
  get contact() {
    return JSON.parse(sessionStorage.getItem('contact'));
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
    this.nonProfit = new BehaviorSubject<boolean>(true);
  }

  getCompanyInfo(): Observable<any> {
    return this.http.get('/plan/company').pipe(
      tap(res => this.saveToSessionStorage(res)),
    );
  }

  isNonProfitType(): Observable<boolean> {
    return this.http.get<any>('/plan/company').pipe(
      tap(res => this.saveToSessionStorage(res)),
      map(res => res && res.company && res.company.type === NON_PROFIT)
    );
  }

  updateCompanyInfo(companyInfo): Observable<any> {
    if (companyInfo.company.erId) {
      return this.http.put('/plan/company', companyInfo).pipe(
          tap(() => this.saveToSessionStorage(companyInfo))
      );
    } else {
      companyInfo.repId = sessionStorage.getItem('repId') || companyInfo.repId;

      return this.http.post('/plan/company', companyInfo).pipe(
        tap(({ accessToken }) => {
          this.saveToSessionStorage(companyInfo);
          this.authService.accessToken = accessToken;
        })
      );
    }
  }

  private saveToSessionStorage(res: any): void {
    if (!res) {
      return;
    }

    const { company, contact } = res;
    this.companyName = company.name;
    this.ein = company.einTaxId;
    this.contact = contact;
    this.nonProfit.next(company.type === NON_PROFIT);
  }
}
