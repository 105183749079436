import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { URLS, URL_VALIDATION } from 'src/app/shared/enums/urls.enum';
import { HTTP_STATUS } from 'src/app/shared/enums/status.enum';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {
    //
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith(URL_VALIDATION.HTTP)) {
      return next.handle(request);
    }

    request = request.clone({
      url: environment.apiBase + request.url
    });

      request = request.clone({
        setHeaders: {
          Authorization: this.authService.accessToken
        }
      });

    return next.handle(request).pipe(
      catchError(err => {
        const { errorCode } = err.error || { errorCode: '' };
        if (errorCode === HTTP_STATUS.ERROR_CODE_1000) {
          this.router.navigate([URLS.RETRIEVE], {
            queryParams: {error: HTTP_STATUS.TOKEN_EXPIRED}
          });
        }
        return throwError(err);
      })
    );
  }
}

export const interceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: Interceptor,
  multi: true
};
