import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { concatMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/internal/operators/finalize';
import { NgbDatepickerConfig, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { conformToMask } from 'text-mask-core';
import { CompanyService } from 'src/app/shared/services/company.service';
import { MaskConfigs } from 'src/app/shared/validators/masks';
import { FieldValidators } from 'src/app/shared/validators/validators';
import { NON_PROFIT } from 'src/app/shared/constants/corptypes';
import { ProgressService } from 'src/app/shared/services/progress.service';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { EMPLOYEES } from 'src/app/shared/constants/steps.data';
import { FieldLength } from 'src/app/shared/validators/field-length';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { CA_STATES, CORP_TYPES } from 'src/app/shared/constants/dropdown-values.const';
import { Count } from 'src/app/shared/enums/common-form.enum';

@Component({
  selector: 'ps-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss'],
  providers: [NgbDatepickerConfig]
})
export class CompanyInfoComponent implements OnInit, OnDestroy {
  readonly maskConfigs = MaskConfigs;
  readonly corpTypes  = CORP_TYPES;
  readonly states = CA_STATES;
  @ViewChild('modal', { static: true })
  modal!: ModalComponent;
  peoName!: string| null;
  from5500!: boolean;
  form!: FormGroup;
  previousSelectedCompanyType!: string;
  isLoading = false;
  errorMsg!: string;
  get repEmail(): string {
    return this.authService?.repEmail;
  }

  get requestPayload(): object {
    const request = this.form.value;
    request.company?.employeeCount?.replace(',', '');
    return request;
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private companyService: CompanyService,
    private progressService: ProgressService,
    private authService: AuthService,
    dpConfig: NgbDatepickerConfig,
    calendar: NgbCalendar
  ) {
    progressService.onSave = this.onSave.bind(this);
    dpConfig.maxDate = calendar.getToday();
    this.initForm();
    this.companyTypeChanged();
  }

  ngOnInit(): void {
    this.peoName = this.companyService.peoName;
    this.notChangedCompanyType();
    this.saveCompanyTypeChanges();
    this.companyService
      .getCompanyInfo()
      .pipe(
        finalize(() => {
          this.form.valueChanges.subscribe(
            () => (this.progressService.hasChanges = true)
          );
        })
      )
      .subscribe((data) => {
        this.from5500 = data?.from5500;
        this.previousSelectedCompanyType = data?.company?.type;
        this.form.patchValue(this.cleanData(data));
      });
  }

  ngOnDestroy(): void {
    this.progressService.onSave = null;
  }

  onSubmit(): void {
    if (!this.form.get('company.erId').value) {
      this.modal.open();
    } else {
      this.submitData();
    }
  }

  submitData(): void {
    this.progressService.onSaving$.next(true);
    this.onSave().subscribe(() => {
      this.progressService.updateProgress(EMPLOYEES.step);
      this.progressService.onSaving$.next(false);
      this.progressService.empChanged = false;
      this.settingProgressForCompanyEdit();
      this.router.navigateByUrl(EMPLOYEES.url);
    },
      (error) => {
        this.errorMsg = error.error.message;
        this.progressService.onSaving$.next(false);
      }
    );
  }

  onSave() {
    this.isLoading = true;
    this.errorMsg = '';
    return this.companyService
      .updateCompanyInfo(this.requestPayload)
      .pipe(finalize(() => (this.isLoading = false)));
  }

  initForm(): void {
    const company = this.fb.group({
      name: [
        '',
        [Validators.required, Validators.maxLength(FieldLength.COMPANY_NAME)]
      ],
      type: [null, Validators.required],
      employeeCount: [
        null,
        [Validators.required, Validators.min(1), Validators.pattern(/\d/)]
      ],
      erId: [],
      peoId: [this.companyService.peoId],
      einTaxId: [this.companyService.ein.replace(/\D/, '')]
    });
    const address = this.fb.group({
      street1: [
        '',
        [Validators.required, Validators.maxLength(FieldLength.STREET)]
      ],
      street2: ['', [Validators.maxLength(FieldLength.STREET)]],
      city: ['', [Validators.required, Validators.maxLength(FieldLength.CITY)]],
      state: [null, Validators.required],
      addType: ['primary'],
      zipcode: ['', [Validators.required, FieldValidators.zipcode]]
    });
    const contact = this.fb.group({
      firstName: [
        '',
        [Validators.required, Validators.maxLength(FieldLength.NAME)]
      ],
      lastName: [
        '',
        [Validators.required, Validators.maxLength(FieldLength.NAME)]
      ],
      email: [
        '',
        [
          FieldValidators.email,
          Validators.required,
          Validators.maxLength(FieldLength.EMAIL),
        ]
      ],
      phone: ['', [Validators.required, FieldValidators.phone]]
    });
    this.form = this.fb.group({
      company,
      address,
      contact
    });
  }

  cleanData(obj) {
    const data = Object.assign({}, obj);
    for (const key in data) {
      const child = data[key];
      for (const subkey in child) {
        if (child[subkey] === null) delete child[subkey];
      }
      if (data[key] === null) delete data[key];
    }
    if (data.contact && data.contact.phone) {
      data.contact.phone = conformToMask(
        data.contact.phone,
        MaskConfigs.phone.mask,
        {}
      ).conformedValue;
    }
    return data;
  }

  checkCompanyTypeChanged(): void {
    if (this.progressService.progress.getValue() >= Count.TWO) {
      if (this.previousSelectedCompanyType === NON_PROFIT && this.form.value.company.type !== NON_PROFIT && !this.progressService.empChanged) {
        this.progressService.companyTypeChanged.next({status:true,isContinue:true,isNonProfit:false});
        return;
      }
      else if (this.previousSelectedCompanyType !== NON_PROFIT && this.form.value.company.type === NON_PROFIT && !this.progressService.empChanged) {
        this.progressService.companyTypeChanged.next({status:true,isContinue:true,isNonProfit:true});
        return;
      }
    }
    this.onSubmit();
  }

  notChangedCompanyType(): void {
    this.progressService.noChanges.subscribe(data => {
      if (data && !this.progressService.empChanged) {
        this.form.controls['company'].get('type').setValue(this.previousSelectedCompanyType);
      }
    })
  }

  saveCompanyTypeChanges(): void {
    this.progressService.savedCompanyChanged.subscribe(res => {
      if (res)
        this.onSubmit();
    })
  }

  companyTypeChanged(): void {
    this.form.get('company').valueChanges.subscribe(data => {
      this.progressService.changedCompanyType = ((this.previousSelectedCompanyType === NON_PROFIT && data?.type !== NON_PROFIT ) ||
        (this.previousSelectedCompanyType !== NON_PROFIT && data?.type === NON_PROFIT)) ? true : false;
        this.companyService.isNonProfit= data?.type === NON_PROFIT;
      })
  }
  
  settingProgressForCompanyEdit(): void {
    this.progressService.savedCompanyChanged.complete();
    this.progressService.savedCompanyChanged = new Subject();
    this.progressService.changedCompanyType? this.progressService.setCompanyTypeChanged(Count.ONE.toString()):null;
    this.progressService.changedCompanyType = false;
  }
}
