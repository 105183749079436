import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { ProgressService } from '../services/progress.service';

@Injectable({
  providedIn: 'root'
})
export class ProgressGuard implements CanActivate {
  constructor(private progressService: ProgressService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return this.progressService.progress.value >= route.data.step;
  }
}
