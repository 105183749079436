<div class="question-row row">
  <div class="question col-12 col-sm-6 col-md-9 mb-2 mb-sm-0">
    <p class="label">
      {{label}}
      <i class='uil uil-info-circle' *ngIf="tooltip" ngbTooltip="{{tooltip}}" placement="bottom"></i>
    </p>
    <p class="desc" *ngIf="description; else descriptionTemplate" [innerHTML]="description"></p>
  </div>
  <div class="col-12 col-sm-6 col-md-3 mt-3 mt-sm-0">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #descriptionTemplate>
  <p class="desc">
    <ng-content select=".description-template"></ng-content>
  </p>
</ng-template>