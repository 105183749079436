<ng-container *ngIf="vestingContent">
  <div class="vesting-panel">
    <h5>Vesting Schedule</h5>
    <p>The vesting schedule for the plan determines when contributions the employer makes
      to participant 401(k) accounts become the participant's assets to keep. It is important to
      note that employees' own contributions are always 100% vested immediately, while only
      employer contributions are subject to the vesting schedule.
    </p>
  </div>
  <div class="vesting-description">
    <h3>{{vestingContent.heading}}</h3>
    <p>{{vestingContent.description}}</p>
  </div>
  <div class="vesting-table" *ngIf="vestingContent.data">
    <div class="table-border">
      <table>
        <tr class="table-heading">
          <th>Years of Service</th>
          <th>Vested</th>
        </tr>
        <tr *ngFor="let vesting of vestingContent.data">
          <td class="left-column">{{vesting.year}}</td>
          <td class="right-column">{{vesting.vested}}%</td>
        </tr>
      </table>
    </div>
  </div>
</ng-container>