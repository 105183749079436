<p class="main-instruction">Please answer the following questions to determine if your plan is eligible for fully-online
  plan setup.</p>
<div class="block block-padding" *ngIf="!isDataLoading; else load">
  <form class="question-form">
    <error-message *ngIf="errorMsg">{{ errorMsg }}</error-message>
    <div class="questions">
      <ps-question-row *ngFor="let question of filteredQuestions" [label]="question.label"
        [description]="question.description" [tooltip]="question.tooltip">
        <ps-input-bool [value]="question.value" (onUpdate)="setAnswer(question.id, $event)"></ps-input-bool>
      </ps-question-row>
    </div>
    <div class="text-right pt-48">
      <cta-button [isLoading]="isLoading" (ctaClick)="checkQuestionAnswer()"
        [disabled]="hasEmptyValue()">Save & Next</cta-button>
    </div>
  </form>
</div>

<ps-modal class="warning-popup" #infoPopup>
  <span class="top-section">
    <img src="assets/img/help-icon.svg" alt="remove icon">
    <h2>Help is on the way!</h2>
  </span>
  <p class="mb-4">Looks like your plan is not eligible for fully online setup at this time. If you hit confirm, we will send your
    info to a Plan Design Consultant, and someone will be in touch with you within two business days.</p>
    <p>If you need to make changes, please close this modal.</p>
  <div class="button-outer">
    <cta-button [isLoading]="isLoading" (click)="updateInfo(true)">Confirm</cta-button>
  </div>
</ps-modal>

<ng-template #load>
  <ps-loading-dots></ps-loading-dots>
</ng-template>