import { Component, Input } from '@angular/core';
import { ICompanyInformation } from 'src/app/shared/models/company-info-model';
@Component({
  selector: 'ps-review-company',
  templateUrl: './review-company.component.html',
  styleUrls: ['./review-company.component.scss']
})
export class ReviewCompanyComponent {

  @Input() companyInfo: ICompanyInformation;
  @Input() canEdit: boolean;

}
