<p class="main-instruction">Now it’s time to work on the plan specification.</p>
<div class="block ">
  <div class="inside-container">
    <div class="title-block">
      <h6 class="section-title">Plan Specifications</h6>
      <div class="back-btn-cls" *ngIf="(currentStep !== currentStage.CURRENT_STEP_ONE)" (click)="prevStep()">
        <img src="assets/img/back-arrow.svg" alt="Back" />
      </div>
    </div>
    <div *ngIf="!loadingData"> 
    <div class="plan-design-container plan-mob-design" *ngIf="currentStep === currentStage.CURRENT_STEP_ONE">
      <h6 class="sub-title">Step 1: {{stepLabels.STEP_ONE}}</h6>
      <div [formGroup]="form">
        <ng-container *ngFor="let question of questions">
          <ps-question-row [label]="question.label" [description]="question.description" *ngIf="question.enabled">
            <div *ngIf="question.type === questionTypes.DATE" class="input-wrapper input-form">
              <i class="fas fa-calendar prefix"></i>
              <input readonly type="text" class="form-control" placeholder="MM/DD/YYYY" ngbDatepicker
                #dp="ngbDatepicker" (click)="dp.open()" [formControlName]="question.id">
            </div>

            <div *ngIf="question.type === questionTypes.READONLY" class="text-left pr-field">
              <span>{{question.value}}</span>
            </div>

            <select class="form-control" *ngIf="question.type === questionTypes.SELECT" [formControlName]="question.id"
              required>
              <option value="" disabled>Select</option>
              <option *ngFor="let option of question.options" [value]="option.value">{{option.label}}</option>
            </select>

            <ps-input-bool *ngIf="question.type === questionTypes.BOOLEAN" [value]="form.get(question.id).value"
              (onUpdate)="form.get(question.id).setValue($event)"></ps-input-bool>
          </ps-question-row>
        </ng-container>
      </div>
      <div class="info-narrative">Entry Schedule, Service Requirement, and Enrollment Method are not adjustable. All
        current employees will be automatically enrolled in the plan immediately, while new employees will be
        automatically enrolled after completing 3 months of service.</div>
    </div>

    <div class="select-plan-container"
      *ngIf="currentStep === currentStage.CURRENT_STEP_TWO || currentStep === currentStage.CURRENT_STEP_THREE">
      <h6 class="sub-title">Step {{ currentStep }}: {{stepLabel}}</h6>
      <p>{{stepDescription}}</p>
      <ps-plan-cards 
      [activeSafeHarbor]="activeSafeHarbor"
        [presentStep]="currentStep"
        [planOptions]="(currentStep == 2 ?parentOption: childPlanOption)"
        [selectedPlanOption]="(currentStep == 2 ?parentSelectedOption: childSelectedOption)"
        (selectPlanOption)="onSelectPlanOption($event)"
        [isExpandChild]="(currentStep == currentStage.CURRENT_STEP_TWO ?
      (parentSelectedOption?.value !== planSelectionType.SAFE_HARBOR || isParentExpand || (isEdit &&  editedParentType != planSelectionType.SAFE_HARBOR)): 
      (childSelectedOption?.value !== planTypeValue.SF_QACA  && childSelectedOption != undefined) || isChildExpand ||(isEdit &&  editedChildType != planSelectionType.QACA_MATCH && editedChildType != planSelectionType.MATCH && editedChildType != planSelectionType.STARTER))"
        (isExpandedParent)="expand($event)"></ps-plan-cards>
      <ps-company-contribution
        *ngIf="isCompanyContribution && currentStep === currentStage.CURRENT_STEP_THREE && planSelectionType.MATCH"
        (getOptionValue)="getValue($event)" [options]="vestingOptions" [contributions]="contributions"
        (calculationValueChange)="matchCalculationChanged($event)"></ps-company-contribution>
    </div>

    <div *ngIf="currentStep === currentStage.CURRENT_STEP_FOUR">
      <h6 class="sub-title">Step 4: {{stepLabels.STEP_FOUR}}</h6>
      <ps-match-calculator [planType]="(currentStep == 2 ?parentSelectedOption: childSelectedOption)"
        [matchDeferral]="deferral" [matchPercentage]="matchPercentage"
        [maxContribution]="maximumContribution" (calculationCalled)="scrollToSelf()"></ps-match-calculator>
    </div>

    <div class="text-right mt-64">
      <cta-button [isLoading]="isLoading" (ctaClick)="onSubmit()"
        [disabled]="(currentStep === currentStage.CURRENT_STEP_ONE && form.invalid) || (currentStep !== currentStage.CURRENT_STEP_ONE && !selectedPlanOption) || (currentStep === currentStage.CURRENT_STEP_THREE && (planSelectionType.MATCH === selectedPlanOption.type) && optionValue === null) || isDisabled  || ((currentStep === currentStage.CURRENT_STEP_TWO) && (planSelectionType.SAFE_HARBOR === selectedPlanOption.type) && disableSafeHarbor)">
        {{ currentStep === 4 ? reusableLabel.NEXT : reusableLabel.SAVE_AND_NEXT}}</cta-button>
    </div>
  </div>
</div>
<div *ngIf="loadingData" class="loading-margin">
  <ps-loading-dots></ps-loading-dots>
</div>
</div>

<ps-modal [disableClickOff]="true" class="custom-confirm-popup" #confirmPopup (onClose)="updateSelectedPlan()">
  <div class="text-instructive">
    <h4><span class="icn-img"><img src="assets/img/star.svg" alt="Reconfirm Image" /></span>Choose Custom Plan Design
    </h4>
    <p>
      <span>Are you sure none of the available plan design options will meet your needs? If so, please click Submit
        below and a Plan Advisor Representative will contact you for a plan design consultation within 2 business days
        .</span>
    </p>
  </div>
  <div class="modal-footer full-btn">
    <div class="modal-footer-cta">
      <cta-button [isLoading]="isSubmit" (ctaClick)="confirmCustomPlanSubmission(true)">Submit</cta-button>
    </div>
  </div>
</ps-modal>

<ps-modal [disableClickOff]="true" #reConfirmPopup [closeButton]="false"  class="reConfirmPopup">
  <div class="text-instructive" *ngIf="!unSelectPlan">
    <span class="confirm-popup-header">
      <img src="assets/img/avatar-icon.svg" alt="Reconfirm" />
      <h4>Reconfirm Highly Compensated
        Employees</h4>
    </span>
    <p>
      <span>Please note that the IRS highly compensated employee income threshold changes as frequently as every year.
        Please go back to the Employees step and reconfirm your highly compensated employee list based upon the limit
        associated with your selected plan effective date.</span>
    </p>
  </div>
  <div class="text-instructive" *ngIf="unSelectPlan">
    <span class="confirm-popup-header">
      <img src="assets/img/pencil-icon.svg" alt="Reconfirm" />
      <h4>{{!isSaveShow?'Reconfirm Highly Compensated
        Employees And Reselect Your Plan':'Reselect Your Plan'}}</h4>
    </span>
    <p>
      <span>
        Are you sure you want to change your plan effective date? Safe Harbor cannot be elected after October 1st of
        each year. If you choose an effective date within the 4th quarter of the year, you are not able to select a Safe
        Harbor plan design.
        <span class="second-content"  *ngIf="!isSaveShow">Also, because of the effective date change, you will need to go back and reconfirm
          your list of highly compensated employees based upon the income limit associated with the year in which you
          are adopting the plan (i.e. <span>{{effectiveDate}}</span> vs <span>{{selectedYear}}</span>). The IRS highly compensated employee limit changes as frequently as
          every year.</span>
      </span>
    </p>
  </div>
  <div class="modal-footer full-btn">
    <div class="modal-footer-cta">
      <cta-button class="border-btn  change-btn" *ngIf="unSelectPlan  && isShowDontSave" (ctaClick)="noDateChange()">Don’t change the date</cta-button>
      <cta-button [isLoading]="isSubmit" (ctaClick)="goToHCE()"> {{isSaveShow?'Save & Continue':'Go to
        HCE'}}</cta-button>
    </div>
  </div>
</ps-modal>

<ps-modal [disableClickOff]="true" #helpPopup class="help-modal" [closeButton]="false">
  <ps-help-card [referenceNum]="docRefId" [submittedDate]="submittedDate" [title]="message.CUSTOM_END_TITLE"
    [text]="message.CUSTOM_END_TEXT" (returnButtonClick)="returnToSlavic()"></ps-help-card>
</ps-modal>