import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DEFAULT_VALUES, OPERATION_OPERATOR, PLAN_SELECTION_TYPE, VESTING_TYPE } from 'src/app/shared/enums/plan-selection.enum';
import { VestingSchedule } from 'src/app/shared/services/vesting.service';
import { planOptions } from 'src/app/pages/plan-basics/plan-cards/plan-card.data';
import { Contributions } from 'src/app/shared/services/contributions.service';
import { ProgressService } from 'src/app/shared/services/progress.service';
import { ICalculation } from 'src/app/shared/models/calculation.models'
import { COMMON_REUSABLE_NUMBER } from 'src/app/shared/enums/common-number-validation.enum';
import { PlanService } from 'src/app/shared/services/plan.service';

@Component({
  selector: 'ps-company-contribution',
  templateUrl: './company-contribution.component.html',
  styleUrls: ['./company-contribution.component.scss']
})
export class CompanyContributionComponent implements OnInit, OnChanges {
  defaultValues = DEFAULT_VALUES;
  public PlanOptions = planOptions;
  @Input() contributions: Contributions;
  deferral: number = this.defaultValues.DEFERRAL_PERCENTAGE;
  @Input() options: VestingSchedule[];
  selectedVestingId: number = null;
  operationOperator = OPERATION_OPERATOR;
  planSelectionType = PLAN_SELECTION_TYPE;
  matchPercentage: number = this.defaultValues.MATCH_PERCENTAGE;
  maximumContribution: number = this.defaultValues.MAX_CONTRIBUTION;
  @Output() getOptionValue: EventEmitter<number> = new EventEmitter<number>();
  @Output() calculationValueChange: EventEmitter<ICalculation> = new EventEmitter<ICalculation>();
  @Output() matchPercentageValue: EventEmitter<number> = new EventEmitter<number>();
  @Output() deferralValue: EventEmitter<number> = new EventEmitter<number>();
  vestingType = VESTING_TYPE;
  constructor(
    private progressService: ProgressService,
    public planService: PlanService
  ) {
    //
  }

  changeMatchPercentage(delta: number): void {
    const newValue = +this.matchPercentage + delta;
    this.matchPercentage = Math.min(Math.max(newValue, COMMON_REUSABLE_NUMBER.ZERO), COMMON_REUSABLE_NUMBER.HUNDRED);
    this.updateMaxContribution();
  }

  changeDeferral(delta: number) {
    const newValue = this.deferral + delta;
    this.deferral = Math.min(Math.max(newValue, COMMON_REUSABLE_NUMBER.ZERO), COMMON_REUSABLE_NUMBER.HUNDRED);
    this.updateMaxContribution();
  }

  restrictInputToRange(event: any, minValue: number, maxValue: number) {
    let inputValue = Number.parseInt(event.target.value);
    if (inputValue < COMMON_REUSABLE_NUMBER.ZERO) {
      inputValue = COMMON_REUSABLE_NUMBER.ZERO;
    }
    inputValue = Math.min(Math.max(inputValue, minValue), maxValue);
    event.target.value = inputValue.toString();
    this.updateMaxContribution();
  }

  updateMaxContribution(): void {
    const maxContribution = (this.deferral / COMMON_REUSABLE_NUMBER.HUNDRED) * (this.matchPercentage / COMMON_REUSABLE_NUMBER.HUNDRED) * COMMON_REUSABLE_NUMBER.HUNDRED;
    const maximumContributionFormatted = maxContribution.toFixed(2);
    this.maximumContribution = parseFloat(maximumContributionFormatted);
    this.deferral = Math.min(this.deferral, COMMON_REUSABLE_NUMBER.HUNDRED);
    this.calculationValueChange.emit({ deferral: this.deferral, maximumContribution: this.maximumContribution, matchPercentage: this.matchPercentage });
    if (this.contributions.matchList.length) {
      if (this.contributions.matchList[0].formula.tiers.length) {
        this.progressService.hasChanges = (this.contributions.matchList[0].formula.tiers[0].matchPercent !== this.matchPercentage) || (this.contributions.matchList[0].formula.tiers[0].to !== this.deferral);
      }
      else {
        this.progressService.hasChanges = (this.maximumContribution !== this.defaultValues.MAX_CONTRIBUTION);
      }
    }
  }

  ngOnInit(): void {
    if (this.contributions) {
      this.savedPlanOption();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.contributions = changes.contributions?.currentValue || this.contributions;
    this.savedPlanOption();
  }

  optionValueChange(event): void {
    this.selectedVestingId = event;
    this.getOptionValue.emit(this.selectedVestingId);
    if (this.contributions.matchList.length) {
      if (this.contributions.matchList[0].vesting) {
        this.progressService.hasChanges = (this.contributions.matchList[0].vesting.id !== this.selectedVestingId);
      }
    }
  }

  savedPlanOption(): void {
    if (this.contributions.matchList.length) {
      const matchList = this.contributions.matchList;
      if (matchList.length > COMMON_REUSABLE_NUMBER.ZERO) {
        if (matchList[0].type == this.planSelectionType.MATCH) {
          this.matchPercentage = this.contributions.matchList[0].formula.tiers[0].matchPercent;
          this.deferral = this.contributions.matchList[0].formula.tiers[0].to;
          this.selectedVestingId = this.contributions.matchList[0].vesting.id;
          this.maximumContribution = this.contributions.matchList[0].formula.maxEmployerContribRate;
          this.getOptionValue.emit(this.selectedVestingId);
        }
        else {
          this.setDefaultValueSet();
        }
      }
    }
    else
      this.setDefaultValueSet();
  }

  setDefaultValueSet() {
    this.selectedVestingId = this.options.find(element => element.label === VESTING_TYPE.IMMEDIATE).id;
    this.getOptionValue.emit(this.selectedVestingId);
  }
}
