import { Component, Input } from '@angular/core';
import { IVestingData } from 'src/app/shared/models/plan-details.model';

@Component({
  selector: 'app-vesting-schedule',
  templateUrl: './vesting-schedule.component.html',
  styleUrls: ['./vesting-schedule.component.scss']
})
export class VestingScheduleComponent {
  data: IVestingData;
  get vestingContent(): IVestingData {
    return this.data;
  }
  @Input() set vestingContent(value: IVestingData) {
    this.data = value;
  }

  constructor() {
    //
  }

}
