import { Directive, ElementRef, ViewContainerRef, Component, ComponentFactoryResolver, Input } from '@angular/core';

@Directive({
  selector: '[passwordToggle]'
})
export class PasswordToggleDirective {

  constructor(elem: ElementRef, vcf: ViewContainerRef, cfr: ComponentFactoryResolver) {
    vcf.clear();

    const toggleFactory = cfr.resolveComponentFactory(PasswordToggleComponent);
    const toggle = vcf.createComponent(toggleFactory);
    toggle.instance.inputElem = elem;
  }

}

@Component({
  template: `
    <button type="button" tabindex="-1" class="right" (click)="toggle()">
      <i class="fa fa-eye" [ngClass]="{'text-secondary': showPw, 'text-invalid': !showPw}"></i>
    </button>
  `
})
export class PasswordToggleComponent {
  showPw: boolean = false;
  @Input() inputElem: ElementRef;

  toggle() {
    this.showPw = !this.showPw;
    this.inputElem.nativeElement.type = this.showPw ? 'text' : 'password';
  }
}
