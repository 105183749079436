<div class="slider-wrapper">
  <input type="range" min="0" [max]="max" [step]="step" [value]="value" (input)="update.emit($event.target.value)">
  <div class="slider">
    <div class="track">
        <div class="track-progress" [style.width.%]="value / max * 100">
        <span class="slider-thumb"></span>
        <div class="label">
          <h6>{{ hoverText }}</h6>
          {{ estAssets }}
        </div>
      </div>
    </div>
  </div>
</div>