import {
  Component,
  Output,
  Input,
  EventEmitter,
  OnChanges,
  OnInit,
} from '@angular/core';
import { AMOUNT_THRESHOLDS, AMOUNT_SUFFIX } from 'src/app/shared/components/asset-slider/asset-slider-enum';

@Component({
  selector: 'ps-asset-slider',
  templateUrl: './asset-slider.component.html',
  styleUrls: ['./asset-slider.component.scss'],
})
export class AssetSliderComponent implements OnChanges, OnInit {

  @Input() value;
  @Input() max = 100;
  @Input() step = 1;
  @Input() isAmount;
  @Input() hoverText: string;
  @Output() update = new EventEmitter();
  @Output() updateText = new EventEmitter();
  estAssets: string;

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.updateEstAssets();
  }

  /**
 * Update the estimated assets based on the input value and format it.
 * Emits the updated text to be displayed.
 */
  updateEstAssets() {
    if (this.isAmount) {
      this.estAssets = this.formatAmount(this.value);
      if (+this.value === this.max) {
        this.estAssets = 'Over ' + this.estAssets;
      }
    } else {
      this.estAssets = `${this.value}%`;
    }
    this.updateText.emit(this.hoverText);
  }

  /**
 * Formats a numeric value or string as a currency amount in USD with optional suffixes for thousand, million, and billion.
 *
 * @param {string | number} value - The numeric value or string to format.
 * @returns {string} The formatted currency amount as a string.
 * @throws {Error} Throws an error if the input value is not a valid number.
 */
  private formatAmount(value: string | number): string {
    const numericValue = +value;
    if (isNaN(numericValue)) {
      throw new Error('Invalid input. Value must be a number.');
    }
    const minimumFractionDigits = (numericValue >= AMOUNT_THRESHOLDS.BILLION || numericValue >= AMOUNT_THRESHOLDS.MILLION) ? 1 : (numericValue >= AMOUNT_THRESHOLDS.THOUSAND ? 0 : 0);
    const options = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: minimumFractionDigits,
    };
    if (numericValue >= AMOUNT_THRESHOLDS.BILLION) {
      return (numericValue / AMOUNT_THRESHOLDS.BILLION).toLocaleString('en-US', options) + AMOUNT_SUFFIX.BILLION;
    } else if (numericValue >= AMOUNT_THRESHOLDS.MILLION) {
      return (numericValue / AMOUNT_THRESHOLDS.MILLION).toLocaleString('en-US', options) + AMOUNT_SUFFIX.MILLION;
    } else if (numericValue >= AMOUNT_THRESHOLDS.THOUSAND) {
      return (numericValue / AMOUNT_THRESHOLDS.THOUSAND).toLocaleString('en-US', options) + AMOUNT_SUFFIX.THOUSAND;
    } else {
      return numericValue.toLocaleString('en-US', options);
    }
  }

}