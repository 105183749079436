import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Count } from 'src/app/shared/enums/common-form.enum';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { ProgressService } from 'src/app/shared/services/progress.service';
@Component({
  selector: 'app-company-type-change-modal',
  templateUrl: './company-type-change-modal.component.html',
  styleUrls: ['./company-type-change-modal.component.scss']
})
export class CompanyTypeChangeModalComponent implements OnInit {
  isNonProfit: boolean = true;
  isContinue: boolean = false;
  currentStatus: number = 1;
  @Input() progress: number = 0;
  count = Count;
  @ViewChild('companyTypeModal', { static: true })
  companyTypeModal!: ModalComponent;
  constructor(
    private employeeService: EmployeeService,
    public progressService: ProgressService,
  ) {
    //
  }
  ngOnInit() {
    this.companyTypeChanged();
    this.currentStatus = this.progressService.categoryStatusType.getValue();
  }
  
  continue(): void {
      this.deleteEmployees();
  }

  deleteEmployees(): void {
    this.employeeService.deleteAllContact().subscribe(() => {
      this.savedChanges();
    })
  }

  companyTypeChanged(): void {
    this.progressService.companyTypeChanged.subscribe(res => {
      this.isContinue = res.isContinue;
      this.isNonProfit= res.isNonProfit;
      if (res.status) {
        this.companyTypeModal.open();
      }
    })
  }

  noChanges(): void {
    this.progressService.noChanges.next(true);
  }

  close(): void {
    if (!this.isContinue) {
      (sessionStorage.setItem('continuePopUpShow',Count.ONE.toString()));
      this.progressService.continuePopUpShow.next(Count.ONE)
      }
      this.companyTypeModal.close();
      this.noChanges();
  }

  savedChanges(): void {
    this.progressService.empChanged = true;
    this.progressService.savedCompanyChanged.next(true);
    this.companyTypeModal.close();
  }
}