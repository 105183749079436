
<div class="company-contr">
  <label>Maximum company contribution</label>
  <div class="match-calc">
    <p>
      <span class="words">Match</span>
      <span class="perc-cls">
        <button class="btn" (click)="changeMatchPercentage(this.operationOperator.SUBTRACT)">-</button>
        <div class="input-box"><input type="number" [(ngModel)]="matchPercentage"
            (input)="restrictInputToRange($event, 0, 100)"><span class="symbol-per">%</span></div>
        <div class="extr"><button class="btn" (click)="changeMatchPercentage(this.operationOperator.ADD)">+</button>
        </div>
      </span>
      <span class="words">of deferral up to </span>
      <span class="perc-cls">
        <button class="btn" (click)="changeDeferral(this.operationOperator.SUBTRACT)">-</button>
        <div class="input-box"><input type="number" [(ngModel)]="deferral"
            (input)="restrictInputToRange($event, 0, 100)"><span class="symbol-per">%</span></div>
        <div class="extr"><button class="btn" (click)="changeDeferral(this.operationOperator.ADD)">+</button></div>
      </span>
      <span class="words">of compensation</span>
    </p>
  </div>
  <p class="exp-cls">Example: The company will contribute a maximum of <strong>{{ maximumContribution | number:'1.0-2'
      }}%</strong> of a participant’s compensation when they defer <strong>{{ deferral | number:'1.0-2'}}%</strong> or
    more.</p>
  <div class="vest"  >
    <div class="form-group">
      <label class="main-label">
        <span>Vesting</span>
      </label>

      <p class="vesting-description">The vesting schedule for the plan determines when contributions the employer makes
        to participant 401(k)
        accounts become the participant's assets to keep. It is important to note that employees' own contributions are
        always 100% vested immediately, while only employer contributions are subject to the vesting schedule.</p>

      <div class="vesting-cards" >
        <ng-container *ngFor="let option of options">
          <div class="vesting-item" (click)="optionValueChange(option.id)"
            [class.selected-data]="selectedVestingId===option.id">
            <div class="label-text">
              <input type="radio" class="vesting-radio" name="selectedVestingId" [(ngModel)]="selectedVestingId"
                [value]="option.id"> <span class="vesting-label"
                [class.selected-data-color]="selectedVestingId === option.id">{{option.label}}</span>
            </div>
            <div class="vesting-table">
              <p class="vesting-desc" *ngIf="option.label === vestingType.IMMEDIATE">A participant is 100% vested immediately.</p>
              <table *ngIf="option.label!==vestingType.IMMEDIATE" >
                <tr class="table-header">
                  <th width="50%">Years of Service</th>
                  <th width="50%">Vested</th>
                </tr>
                <tr *ngFor="let vested of option.schedule" class="vested-data">
                  <td>{{vested.year}}</td>
                  <td class="vested-percent">{{vested.vestedPercent}}%</td>
                </tr>
              </table>
            </div>

          </div>
        </ng-container>
      </div>
    </div>
  </div>
  
</div>

