import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  public questions: Question[] = [
    {
      order: 1,
      id: 'hasOtherBusiness',
      label: 'Other Ownership',
      description:
        'Do any owners, spouses, or children own part of any other business with employees?',
      tooltip: 'If the owners of your company own any other companies, the multiple companies may need to be treated as a single company for 401(k) compliance and testing purposes.',
      enabled: true,
      value: null
    },
    {
      order: 2,
      id: 'isSubsidiary',
      label: 'Subsidiary',
      description: 'Is the company a subsidiary of another company?',
      tooltip: 'If your company is a subsidiary of another company, it is likely that both entities will be required to share a common 401(k) plan for compliance and testing purposes.',
      enabled: true,
      value: null
    },
    {
      order: 3,
      id: 'isControlledGroup',
      label: 'Controlled Group or Affiliated Service Group',
      description: 'Is the company part of a Controlled Group or Affiliated Service Group?',
      tooltip: 'If your company is part of a controlled group, defined as two or more companies with a set percentage of common ownership, the multiple companies may need to be treated as a single company for 401(k) compliance and testing purposes.',
      enabled: true,
      value: null
    },
    {
      order: 4,
      id: 'allPaidByPEO',
      label: 'Employee Compensation',
      description: 'Do you have any employees who are paid other than via standard W-2 wages, i.e. K1, 1099, etc.?',
      enabled: true,
      value: null
    },
    {
      order: 5,
      id: 'hasPriorPlan',
      label: 'Prior or Existing Plan',
      description: 'Do you have a prior or existing 401k Plan?',
      enabled: true,
      value: null
    }
  ];

  public contributions = new BehaviorSubject([]);

  constructor(private http: HttpClient) { }

  getQuestion(id) {
    return this.questions.find((q) => q.id === id);
  }

  getAnswers() {
    return this.http.get<QualificationInfo>('/plan/qualification-info');
  }

  setAnswer(qid, value) {
    this.questions.find((q) => q.id === qid).value = value;
  }

  getPrevQuestion(id) {
    const curr = this.getQuestion(id);
    if (curr.order <= 1) {
      return null;
    }
    return this.questions.find((q) => q.order === curr.order - 1);
  }

  getNextQuestion(id) {
    const curr = this.getQuestion(id);
    if (curr.order >= this.questions.length) {
      return null;
    }
    return this.questions.find((q) => q.order === curr.order + 1);
  }

  updateQualificationInfo(questions) {
    const answers = questions.reduce((answers, curr) => {
      answers[curr.id] = curr.value;
      return answers;
    }, {});
    return this.http.put<any>('/plan/qualification-info', answers);
  }

  initAnswers() {
    return this.http.get<any>('/plan/qualification-info').pipe(
      map(data => {
        data = data || {};
        this.questions.forEach((q) => {
          if (data[q.id] !== undefined) {
            q.value = data[q.id];
          }
          return q;
        });
        return this.questions;
      })
    );
  }

  initQuestionAndAnswer(): Observable<Question[]> {
    return this.http.get<Question[]>('/plan/qualification-info').pipe(
      map(data => {
        if (data) {
          this.questions.forEach((question) => {
            question.value = data[question.id];
            return question;
          });
        }
        else
          this.questions.map(question => question.value = false);

        return this.questions;
      })
    );
  }
}

export interface Question {
  id: string;
  type?: 'boolean' | 'select' | 'readonly' | 'date';
  order?: number;
  label: string;
  description?: string;
  tooltip?: string;
  value?: any;
  options?: Array<any>;
  enabled?: boolean;
}

export interface QualificationInfo {
  hasOtherBusiness: boolean;
  isSubsidiary: boolean;
  isControlledGroup: boolean;
  allPaidByPEO: boolean;
  hasPriorPlan: boolean;
}
