<div ngbDropdown *ngIf="options">
  <button class="btn" [class.readonly]="readonly" ngbDropdownToggle>
    <p *ngIf="option?.tag">{{option.tag}}</p>
    <span class="label">{{option?.label || placeholder}}</span>
    <i class="fa fa-caret-down"></i>
  </button>
  <div ngbDropdownMenu>
    <button class="dropdown-item" *ngFor="let option of options" (click)="select(option)">{{option.label}}</button>
  </div>
</div>
