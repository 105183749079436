import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ps-help-card',
  templateUrl: './help-card.component.html',
  styleUrls: ['./help-card.component.scss'],
})
export class HelpCardComponent implements OnInit {
  @Input() referenceNum: string;
  @Input() title: string;
  @Input() text: string;
  @Input() submittedDate: Date;
  @Output() returnButtonClick: EventEmitter<void> = new EventEmitter<void>();
  constructor() {}

  ngOnInit(): void {}

  handleButtonClick() {
    this.returnButtonClick.emit();
  }
}
