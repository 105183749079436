import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { PlanService } from '../services/plan.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditingGuard implements CanActivate {
  constructor(private planService: PlanService) {}

  canActivate(): Observable<boolean> {
    return this.planService.getStatus().pipe(
      map(status => status === 'inProgress')
    );
  }
}
