
export class FieldLength {
  static readonly PASSCODE = 14;
  static readonly COMPANY_NAME = 60;
  static readonly STREET = 30;
  static readonly CITY = 30;
  static readonly NAME = 20;
  static readonly EMAIL = 100;
  static readonly TITLE = 30;
  static readonly NOTE = 500;
}
