<div class="helper-widget">
  <div class="help-top">
    <div class="help-img">
      <img src="assets/img/Confirm.svg" alt="Help" />
    </div>
    <h4 class="help-title">{{ title }}</h4>
    <p class="help-descr" [innerHTML]="text">{{ text }}</p>
    <div class="help-sub">
      <span class="help-date">Submitted on {{ submittedDate | date }}</span>
    </div>
  </div>
  <div class="help-ref">
    <label class="help-label">Reference #</label>
    <span class="help-re-no">{{ referenceNum }}</span>
  </div>
  <div class="text-center">
    <cta-button (click)="handleButtonClick()">Return to Slavic 401k</cta-button>
  </div>
</div>
