import { Component } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CompanyService } from 'src/app/shared/services/company.service';
import { FieldValidators } from 'src/app/shared/validators/validators';
import { PlanService } from 'src/app/shared/services/plan.service';
import { finalize } from 'rxjs/internal/operators/finalize';
import { delay } from 'rxjs/internal/operators/delay';
import { trigger, transition, style, animate } from '@angular/animations';
import { ANIM_TIMINGS } from 'src/app/shared/constants/animation.data';

const WIDGET_CLOSED_STYLE = style({
  opacity: 0,
  transform: 'scale(0)'
});
const WIDGET_OPENED_STYLE = style({
  opacity: 1,
  transform: 'scale(1)'
});

@Component({
  selector: 'ps-get-help-widget',
  templateUrl: './get-help-widget.component.html',
  styleUrls: ['./get-help-widget.component.scss'],
  animations: [
    trigger('widget', [
      transition(':enter', [
        WIDGET_CLOSED_STYLE,
        animate(ANIM_TIMINGS, WIDGET_OPENED_STYLE)
      ]),
      transition(':leave', [
        WIDGET_OPENED_STYLE,
        animate(ANIM_TIMINGS, WIDGET_CLOSED_STYLE)
      ]),
      transition('* => true', [
        style({ height: '*' }),
        animate(ANIM_TIMINGS, style({ height: '*'}))
      ])
    ]),
    trigger('widgetContent', [
      transition(':enter', [
        style({ height: 0, opacity: 0, paddingBottom: 0 }),
        animate(ANIM_TIMINGS, style({ height: '*', opacity: 1, paddingBottom: '*' }))
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 0 }),
        animate(ANIM_TIMINGS, style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class GetHelpWidgetComponent {
  form: FormGroup;
  showForm = false;
  loading = false;
  received = false;

  constructor(fb: FormBuilder, private compService: CompanyService, private planService: PlanService) {
    this.form = fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, FieldValidators.email]],
      phone: '',
      message: ['', Validators.required]
    });
  }

  toggleForm() {
    this.received = false;
    this.showForm = !this.showForm;
    const contact = this.compService.contact;
    if (contact) {
      this.form.patchValue({
        ...contact,
        name: [contact.firstName, contact.lastName].join(' ')
      });
    }
  }

  send() {
    this.loading = true;
    this.planService
      .sendQuestion(this.form.value)
      .pipe(
        delay(1000),
        finalize(() => {
          this.loading = false;
          this.received = true;
          this.form.get('message').setValue('');
        })
      )
      .subscribe();
  }
}
