import { Component, Input } from '@angular/core';

@Component({
  selector: 'ps-loading-dots',
  template: `
    <span class="loading-dot-1"></span>
    <span class="loading-dot-2"></span>
    <span class="loading-dot-3"></span>
  `,
  styleUrls: ['./loading-dots.component.scss'],
  host: {
    '[style.color]': 'color'
  }
})
export class LoadingDotsComponent {
  @Input() color = '#008eff';
}
