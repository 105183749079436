<div class="block company">
  <button class="btn block__edit" [routerLink]="['/company-info']" *ngIf="canEdit">Edit</button>
  <h6 class="section-title">COMPANY</h6>
  <div class="form-group pt-0">
    <label>Company Name</label>
    <span>{{companyInfo?.company?.name}}</span>
  </div>
  <div class="form-group">
    <label>Business Address</label>
    <span>{{companyInfo?.address?.street1.trim()}}, <ng-container
        *ngIf="companyInfo?.address?.street2">{{companyInfo?.address?.street2.trim()}},</ng-container>
      {{companyInfo?.address?.city.trim()}}, {{companyInfo?.address?.state.trim()}},
      {{companyInfo?.address?.zipcode.trim()}}</span>
  </div>
  <div class="form-group">
    <label>Primary Contact</label>
    <span>{{companyInfo?.contact?.firstName}} {{companyInfo?.contact?.lastName}}</span>
  </div>
  <div class="form-group">
    <label>Email</label>
    <span class="break-word">{{companyInfo?.contact?.email}}</span>
  </div>
</div>