import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { trigger, transition, animate, style, query, group } from '@angular/animations';
import { CompanyService } from 'src/app/shared/services/company.service';
import { QuestionService } from 'src/app/shared/services/question.service';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { ContributionsService } from 'src/app/shared/services/contributions.service';
import { PlanService } from 'src/app/shared/services/plan.service';
import { ProgressService } from 'src/app/shared/services/progress.service';


@Component({
  selector: 'ps-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  animations: [
    trigger('animation', [
      transition('void => *', [
        group([
          query('.backdrop', [
            style({ opacity: 0 }),
            animate('200ms ease-in-out', style({ opacity: 1 }))
          ]),
          query('.summary', [
            style({ transform: 'translateX(100%)' }),
            animate('200ms ease-in-out', style({ transform: 'translateX(0)' }))
          ])
        ])
      ]),
      transition('* => void', [
        group([
          query('.backdrop', animate('200ms ease-in-out', style({ opacity: 0 }))),
          query('.summary', animate('200ms ease-in-out', style({ transform: 'translateX(100%)' })))
        ])
      ])
    ])
  ]
})
export class SummaryComponent implements OnChanges {
  @Input() show = false;
  @Output() clickOff = new EventEmitter();

  progress;
  ein;
  company;
  questions;
  companyInfoList = [];
  hces = [];
  showAllHces = false;
  entrySchedule;
  effDate;
  planReqList = [];
  matches;
  nonElectives;

  constructor(
    private compService: CompanyService,
    private qService: QuestionService,
    private eeService: EmployeeService,
    private contService: ContributionsService,
    private planService: PlanService,
    progressService: ProgressService
  ) {
    progressService.progress.subscribe(p => this.progress = p);
  }

  ngOnChanges() {
    if (!this.show) return;
    
    this.ein = this.compService.ein;
    this.compService.getCompanyInfo().subscribe(c => this.company = c);
    
    this.qService.getAnswers().subscribe(q => {
      const compInfo = [];
      if (q?.isSubsidiary) compInfo.push('is subsidiary');
      if (q?.isControlledGroup) compInfo.push('part of controlled group');
      if (q?.hasPriorPlan) compInfo.push('has prior plan');
      this.companyInfoList = compInfo;
    });

    this.eeService.getNameList().subscribe(hces => this.hces = hces);

    this.planService.getPlanBasics().subscribe(planBasics => {
      this.entrySchedule = planBasics.entrySchedule;
      this.effDate = this.getFormattedDate(new Date(planBasics.effectiveDate));
      this.planReqList = this.buildReqList(planBasics);
    });

    this.contService
      .getContributions()
      .subscribe(c => {
        this.matches = c.matchList;
        this.nonElectives = c.nonElectiveList;
      });
  }

  toggleAllHces() {
    this.showAllHces = !this.showAllHces;
  }

  getFormattedDate(date: Date) {
    const dateArray = date.toDateString().split(' ');
    return `${dateArray[1]} ${dateArray[2]}, ${dateArray[3]}`;
  }
  
  buildReqList(planBasics) {
    const list = [];
    if(planBasics.serviceRequirement != '0')
      list.push('req. ' + planBasics.serviceRequirement + ' months of service');
    if(planBasics.ageRequirement != '0')
      list.push('req. ' + planBasics.ageRequirement + ' years old');
    if (planBasics.requirementWavier)
      list.push('waive requirements for current empl.');

    return list;
  }

  get hceSliceEnd() {
    return this.showAllHces ? undefined : 4;
  }

}
