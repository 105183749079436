import createNumberMask from "../utils/create-number-mask";

export const MaskConfigs = {
  phone: {
    mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    placeholderChar: '\u2000',
    guide: false
  },
  ein: {
    mask: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    placeholderChar: '\u2000',
    guide: false
  },
  zipcode: {
    mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
    placeholderChar: '\u2000',
    guide: false
  },
  percent: {
    mask: [/\d/, /\d/, /\d/],
    placeholderChar: '\u2000',
    guide: false
  },
  number: {
    mask: createNumberMask({ prefix: '' }),
    placeholderChar: '\u2000',
    guide: false
  },
  dollar: {
    mask: createNumberMask({ prefix: '$' }),
    placeholderChar: '\u2000',
    guide: false
  }
};
