export enum CommonFormType {
  OWNER_INFORMATION_FORM = 1,
  LINEAL_RELATIVE_FORM = 2,
  HCE_FORM = 3,
  OFFICERS_FORM = 4,
  AUTH_PLAN_ADMIN = 5,
  AUTH_PLAN_SIGNER = 6
}

export enum CommonData {
  YES = 'yes',
  NO = 'no',
}

export enum Count {
  ZERO,
  ONE,
  TWO ,
  THREE ,
  FOUR ,
  FIVE
}

export enum ERROR_MESSAGE {
  IS_NON_PROFIT_ERROR ='Two Authorized Signers are required and at least one Plan Admin is required.',
  NOT_NON_PROFIT_ERROR ='One Authorized Signer is required and at least one Plan Admin is required.'
}

