import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'entrySchedule'
})
export class EntrySchedulePipe implements PipeTransform {

  transform(value: string): string {
    const entrySchedules = {
      quarterly: 'Quarterly',
      immediate: 'Immediate',
      monthly: 'Monthly',
      dual: 'Jan 1 & Jul 1',
    };
    return entrySchedules[value] || value;
  }

}
