<ng-container *ngIf = "!isDataLoading; else load">
  <ng-container [ngSwitch] = "endStatus">
    <ng-container *ngSwitchCase = "statusType.END_WITH_QUALIFICATION">
      <div class="warning-popup">
        <span class="top-section">
          <img src="assets/img/help-icon.svg" alt="remove icon">
          <h2>Help is on the way!</h2>
        </span>
        <p>Looks like your plan is not eligible for fully online setup at this time. We have already sent your
          information
          to
          a Plan Design Consultant for follow-up, and someone will be in touch with you within two business days.</p>
        <div class="button-outer">
          <cta-button  (ctaClick)="returnToSlavic()">Return to Slavic401k</cta-button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase = "statusType.END_WITH_CUSTOM_PLAN">
      <div class="submit-popup">
        <ps-help-card [referenceNum]="docRefId" [submittedDate]="submittedDate" [title]="message.CUSTOM_END_TITLE"
          [text]="message.CUSTOM_END_TEXT" (returnButtonClick)="returnToSlavic()"></ps-help-card>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase = "[statusType.IN_PROGRESS, statusType.SUBMITTED].includes(endStatus) ? endStatus : ''">
      <p class="main-instruction" *ngIf = "endStatus == statusType.IN_PROGRESS">Please review carefully and update any
        incorrect information.</p>

      <div class="wrapper">
        <span class="referral-title" *ngIf = "endStatus == statusType.SUBMITTED">
          <p>Reference # {{docRefId}}</p>
          <p class="date-text">Submitted on {{submittedDate | date}}</p>
        </span>
        <div class="col-12 mb-24 pd-zero">
          <ps-review-company [canEdit]="canEdit" [companyInfo]="companyInfo"></ps-review-company>
        </div>
        <div class="col-12 mb-24 pd-zero">
          <ps-review-employees [canEdit]="canEdit" [employeeData]="employeeData"  [companyType]="companyInfo.company.type"></ps-review-employees>
        </div>
        <div class="col-12 mb-24 pd-zero">
          <ps-review-plan [canEdit]="canEdit" [basics]="plan" [planDetails]="planDetails"></ps-review-plan>
        </div>
        <div class="col-12 pd-zero">
          <ps-review-users [canEdit]="canEdit" [users]="authUsers"></ps-review-users>
        </div>
        <div class="col-12 text-right mt-48 mb-40 pd-zero" *ngIf = "canEdit">
          <cta-button [isLoading]="isLoading" (ctaClick)="onSubmit()">Submit Application</cta-button>
        </div>
        <div class="col-12 text-right mt-48 mb-40 pd-zero" *ngIf = "!canEdit && endStatus == statusType.SUBMITTED">
          <cta-button [isLoading]="isLoading" (ctaClick)="returnToSlavic()">Return to Slavic401k</cta-button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #load>
  <ps-loading-dots></ps-loading-dots>
</ng-template>