<div class="block">
  <button class="btn block__edit" [routerLink]="['/employees']" *ngIf="canEdit">
    <span>Edit</span>
  </button>
  <h6 class="section-title">employees</h6>
  <div class="form-group" *ngIf="companyType!== nonProfit"  [class.pt-0]="companyType!== nonProfit">
    <label>Owners</label>
    <span>
      <ng-container *ngIf="owners.length>0; else noData">
        <ng-container *ngFor="let owner of owners">
          <p>{{owner.name}} {{owner.firstName}} {{owner.lastName}}</p>
        </ng-container>
      </ng-container>
    </span>
  </div>
  <div class="form-group" *ngIf="companyType!== nonProfit">
    <label>Lineal Relative Employees</label>
    <span>
      <ng-container *ngIf="ownerRels.length>0; else noData">
        <ng-container *ngFor="let rel of ownerRels">
          <p>{{rel.firstName}} {{rel.lastName}}</p>
        </ng-container>
      </ng-container>
    </span>
  </div>
  <div class="form-group"  [class.pt-0]="companyType === nonProfit">
    <label>Highly Compensated Employees</label>
    <span>
      <ng-container *ngIf="eeOver120k.length>0; else noData">
        <ng-container *ngFor="let emp of eeOver120k">
          <p> {{emp.firstName}} {{emp.lastName}}</p>
        </ng-container>
      </ng-container>
    </span>
  </div>
  <div class="form-group">
    <label>Officers</label>
    <span>
      <ng-container *ngIf="officers.length>0; else noData">
        <ng-container *ngFor="let officer of officers">
          <p>{{officer.firstName}} {{officer.lastName}}</p>
        </ng-container>
      </ng-container>
    </span>
  </div>
</div>

<ng-template #noData>
  <p>-</p>
</ng-template>