import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ein'
})
export class EinPipe implements PipeTransform {

  transform(value: string = ''): any {
    const str = value?.replace(/\D/, '');
    return str?.slice(0,2) + '-' + str?.slice(2);
  }

}
