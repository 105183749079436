<div class="mob-show">
  <div class="user-info">
    <h5>Starting a new plan setup?</h5>
    <a routerLink="/">Click here</a>
  </div>
</div>
<p class="main-instruction">Please provide the following information to continue your plan setup.</p>
<div class="block">
  <h5 class="text-center plan-name">Plan Setup</h5>
  <form class="col-12 col-lg-6 mx-auto padding-0" (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="mb-24">
      <error-message *ngIf="errorMsg">{{errorMsg}}</error-message>
    </div>
    <div class="form-group">
      <label for="">
        <span>Reference Code</span>
        <i class="uil uil-info-circle" placement="top" ngbTooltip="Please enter the reference code that we sent to your email."></i>
        <i class="form-error" *ngIf="form.get('docRefId').touched">
          <ps-error-span [control]="form.get('docRefId')"></ps-error-span>

        </i>
      </label>
      <input type="text" placeholder="UniqueCode123" class="form-control" formControlName="docRefId">
    </div>
    <div class="form-group">
      <label for="">EIN/tax id
        <i class="form-error" *ngIf="form.get('ein').touched">
          <ps-error-span [control]="form.get('ein')"></ps-error-span>
        </i>
      </label>
      <input type="text" placeholder="123456789" class="form-control" formControlName="ein" [textMask]="maskConfig">
    </div>
    <div class="text-center pt-5 pt-32">
      <cta-button [isLoading]="isLoading" [disabled]="form.invalid" class="full-width">Retrieve</cta-button>
    </div>
  </form>
</div>