export const OWNER_TYPE = [
  { key: 'Individual', value: 'Individual' },
  { key: 'Entity', value: 'Entity' },
];

export const ON_PAYROLL = [
  { key: true, value: 'Yes' },
  { key: false, value: 'No' },
];

export const CORP_TYPES = [
  { key: 'Corporation', value: 'Corporation' },
  { key: 'LLC', value: 'LLC' },
  { key: 'Sole Proprietor', value: 'Sole Proprietor' },
  { key: 'S Corporation', value: 'S Corporation' },
  { key: 'Partnership', value: 'Partnership' },
  { key: 'Non-profit', value: 'Non-profit' },
  { key: 'Prof. Assoc', value: 'Prof. Assoc' },
];

export const CA_STATES = [
  { key: 'AL', value: 'AL' },
  { key: 'AK', value: 'AK' },
  { key: 'AZ', value: 'AZ' },
  { key: 'AR', value: 'AR' },
  { key: 'CA', value: 'CA' },
  { key: 'CO', value: 'CO' },
  { key: 'CT', value: 'CT' },
  { key: 'DE', value: 'DE' },
  { key: 'DC', value: 'DC' },
  { key: 'FL', value: 'FL' },
  { key: 'GA', value: 'GA' },
  { key: 'HI', value: 'HI' },
  { key: 'ID', value: 'ID' },
  { key: 'IL', value: 'IL' },
  { key: 'IN', value: 'IN' },
  { key: 'IA', value: 'IA' },
  { key: 'KS', value: 'KS' },
  { key: 'KY', value: 'KY' },
  { key: 'LA', value: 'LA' },
  { key: 'ME', value: 'ME' },
  { key: 'MD', value: 'MD' },
  { key: 'MA', value: 'MA' },
  { key: 'MI', value: 'MI' },
  { key: 'MN', value: 'MN' },
  { key: 'MS', value: 'MS' },
  { key: 'MO', value: 'MO' },
  { key: 'MT', value: 'MT' },
  { key: 'NE', value: 'NE' },
  { key: 'NV', value: 'NV' },
  { key: 'NH', value: 'NH' },
  { key: 'NJ', value: 'NJ' },
  { key: 'NM', value: 'NM' },
  { key: 'NY', value: 'NY' },
  { key: 'NC', value: 'NC' },
  { key: 'ND', value: 'ND' },
  { key: 'OH', value: 'OH' },
  { key: 'OK', value: 'OK' },
  { key: 'OR', value: 'OR' },
  { key: 'PA', value: 'PA' },
  { key: 'RI', value: 'RI' },
  { key: 'SC', value: 'SC' },
  { key: 'SD', value: 'SD' },
  { key: 'TN', value: 'TN' },
  { key: 'TX', value: 'TX' },
  { key: 'UT', value: 'UT' },
  { key: 'VT', value: 'VT' },
  { key: 'VA', value: 'VA' },
  { key: 'WA', value: 'WA' },
  { key: 'WV', value: 'WV' },
  { key: 'WI', value: 'WI' },
  { key: 'WY', value: 'WY' },
];

export const RELATION_SHIP = [
  { key: 'Spouse', value: 'Spouse' },
  { key: 'Child', value: 'Children' },
];
