export enum PLAN_SELECTION_TYPE {
  SAFE_HARBOR = 'safeHarbor',
  MATCH = 'match',
  STARTER = 'starter',
  CUSTOM = 'custom',
  QACA_NON_ELECTIVE = 'shNonElective',
  SH_MATCH = 'shMatch',
  QACA_MATCH = 'qacaMatch'
};

export enum PLAN_TYPE_VALUE {
  SF_QACA = 'sfQuaca',
  SF_MATCH = 'sfMatch',
  SF_NONELECTIVE = 'sfNonElective'
};

export enum CURRENT_STAGE {
  CURRENT_STEP_ONE = 1,
  CURRENT_STEP_TWO = 2,
  CURRENT_STEP_THREE = 3,
  CURRENT_STEP_FOUR = 4
};

export enum OPERATION_OPERATOR {
  ADD = 1,
  SUBTRACT = -1
};

export enum DEFAULT_VALUES {
  MATCH_PERCENTAGE = 50,
  DEFERRAL_PERCENTAGE = 6,
  MAX_CONTRIBUTION = 3,
  EMPLOYEE_COUNT = 10,
  DEFAULT_SALARY = '50000'
};

export enum STEP_LABELS {
  STEP_ONE = 'Basic plan specification',
  STEP_TWO = 'Select a Plan Design',
  STEP_THREE = 'Select a type under Safe Harbor Plan',
  STEP_THREE_MATCH = 'Provide details',
  STEP_FOUR = 'Calculate tax credit and match'
};

export enum OPTION_VALUE {
  VALUE_INDEX_1 =  3,
  VALUE_INDEX_2 =  5,
  VALUE_INDEX_3 =  10
};

export enum TAX_CREDIT {
  ESTIMATED_TAX_CREDIT = 1000
}

export enum SLIDER {
  SLIDER_COUNT = 90
};

export enum DATE_TYPES {
  DEFAULT = 'default',
  EXISTING_SH = 'existing_sh',
  SH_WITH_PRIOR_PLAN = 'sh_with_prior_plan',
  NEW_SH = 'new_sh'
};

export enum TOOLTIP_MESSAGE {
  SAFE_HARBOR_DISABLE_MESSAGE = "A Safe Harbor plan design cannot be adopted later than October 1st of each year. Please either change your plan effective date to be January 1st of next year or later. Otherwise, if you wish to keep your plan's effective date within the 4th Quarter, you will need to select a different plan design option that does not include a Safe Harbor feature."
};

export enum DEFAULT_VALUE {
  EFFECTIVE_DATE = 0
};

export enum VESTING_TYPE {
  IMMEDIATE = 'Immediate'
};

export enum VESTING_LABEL {
  NOT_APPLICABLE = "N/A",
  CUSTOMIZE = "Customize"
};

export enum CLASS_NAME {
  ADD_COLOR = "addColor",
  TAG_SAFE = 'tag-safe',
  TAG_MATCH = 'tag-match'
};

export enum STEP_DESCRIPTION {
  STEP_TWO_DESCRIPTION = "The plan design marked as recommended will streamline your plan's compliance testing and maximize the tax credits available under Secure 2.0 (accepting this recommendation is optional, and other plan design options are available via the \"See other Plan Designs\" link below).",
  STEP_THREE_DESCRIPTION = "You can calculate your estimated tax credits and match costs in the next step."
};

export enum CARD_LABEL {
  SAFE_HARBOR = "Safe Harbor"
};

export enum ELEMENT_ID {
  TABLE_ELEMENT = "table-element",
  DIV_ELEMENT = "div-element"
};

export enum BUTTON_VALUE {
  SEE_OTHER_PLAN_DESIGNS = "See other Plan Designs",
  SEE_OTHER_TYPES = "See other types"
}