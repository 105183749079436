import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { ContributionType } from '../models/contribution';

@Injectable({
  providedIn: 'root',
})
export class PriorPlanService {
  constructor(private http: HttpClient) {}

  getPriorPlanInfo(): Observable<PriorPlan> {
    return this.http.get<PriorPlan>('/plan/prior-plan');
  }

  updatePriorPlanInfo(body) {
    return this.http.put('/plan/prior-plan', body);
  }
}

export interface PriorPlan {
  effectiveDate: string;
  assets: string;
  adminContact: {
    contactId: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  questions: PriorPlanQuestions;
}

export interface PriorPlanQuestions {
  needMerger: boolean;
  isActiveSH: boolean;
  hasLoans: boolean;
  hasHardship: boolean;
  existingAutoEnroll: boolean;
  activeShType: ContributionType;
}
