import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs/internal/Subject';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError } from 'rxjs/internal/observable/throwError';
import { tap } from 'rxjs/internal/operators/tap';
import { Question } from 'src/app/shared/services/question.service';
import { ProgressService } from 'src/app/shared/services/progress.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { IEstimatedAssets, IPlanFeatures, IPricing } from 'src/app/pages/features/models/plan-features.model';
import { BASIC_QUESTIONS } from 'src/app/shared/constants/basic-questions.data'
import { environment } from 'src/environments/environment';
import { STEPS } from 'src/app/shared/constants/steps.data';
import { Count } from 'src/app/shared/enums/common-form.enum';
import { Message } from 'src/app/shared/enums/message.enum';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  status;
  basicQuestions: Question[] = BASIC_QUESTIONS;
  estAssets: Subject<number> = new Subject();
  entrySchedule: Subject<string> = new Subject();

  constructor(
    private http: HttpClient,
    private progressService: ProgressService,
    private compService: CompanyService
  ) { }

  /**
 * Retrieves and sets the entry schedule options from the server.
 */
  getEntryScheduleOptions() {
    this.http.get<any>('/plan/entry-schedule').subscribe(options => {
      // const entrySchedule = this.basicQuestions.find(({ id }) => id === 'entrySchedule');
      // entrySchedule.options = options;
      const entrySchedule = this.basicQuestions.find(({ value }) => value === Message.MONTHLY);
      entrySchedule!.value = options[Count.ZERO].label;
        this.entrySchedule.next(entrySchedule!.value);
    });
  }
  
  /**
   * Retrieves and processes plan features from the server.
   *
   * @returns An observable of plan features.
   */
  getPlanFeatures() {
    return this.http.get<IPlanFeatures>('/plan/features/' + this.compService.peoId)
      .pipe(map(features => {
        const assetFees = features.assetFees.reduce((prev, curr) => {
          if (!prev.some(({ feePct }) => feePct === curr.feePct)) prev.push(curr);
          return prev;
        }, []);
        return { ...features, assetFees };
      }));
  }
  /**
   * Retrieves plan basics from the server.
   *
   * @returns An observable of plan basics.
   */
  getPlanBasics() {
    return this.http.get<PlanBasics>('/plan/plan-outline/');
  }

  /**
 * Updates the effective date for the plan.
 *
 * @param date - The new effective date.
 * @returns An observable of the update request.
 */
  updateEffectiveDate(date): Observable<any> {
    return this.http.put('/plan/plan-outline/', { effectiveDate: date });
  }

  /**
 * Updates plan basics with the provided data.
 *
 * @param basics - The updated plan basics data.
 * @returns An observable of the update request.
 */
  updatePlanBasics(basics): Observable<any> {
    // if (basics.serviceRequirement !== '12/1000') {
    //   basics.allEnoughHours = true;
    // }
    return this.http.put('/plan/plan-outline/', {
      ...basics,
      // hasMissingHours: !basics.allEnoughHours
    });
  }

  /**
 * Retrieves additional plan options from the server.
 *
 * @returns An observable of additional plan options.
 */
  getMoreOptions(): Observable<any> {
    return this.http.get('/plan/addl-options');
  }

  /**
 * Updates additional plan options with the provided data.
 *
 * @param options - The updated additional plan options data.
 * @returns An observable of the update request.
 */
  updateMoreOptions(options): Observable<any> {
    return this.http.put('/plan/addl-options', options);
  }

  /**
 * Retrieves portal access information for plan setup.
 */
  getUsers() {
    return this.http.get('/api/plan-setup/portal-access');
  }

  /**
 * Updates authentication users and signer information.
 *
 * @param authUser - The updated authentication users data.
 * @param signer - The updated signer information.
 */
  updateUsers(authUser, signer) {
    return this.http.put('/plan/auth-user', { authUser, signer });
  }

  /**
 * Retrieves the plan status, either from the cached value or by making a request to get it.
 *
 * @returns An observable of the plan status.
 */
  getStatus(): Observable<any> {
    return this.status
    ? of(this.status)
    : this.getPlanStatus().pipe(
        catchError(err => {
          return throwError(err);
        }),
        map(res => {
          let status;

          if (!res && this.progressService.progress.value < 2) {
            status = 'inProgress';
          }

          if (res && res.status) {
            status = res.status;
          }

          this.status = status;
          return status;
        })
      );
  }

  /**
 * Retrieves the plan status from the server.
 *
 * @returns An observable of the plan status.
 */
  getPlanStatus(): Observable<any> {
    return this.http.get<any>('/plan/status');
  }

  /**
 * Withdraws the plan submission, setting the status to 'inProgress'.
 */
  withdrawSubmission() {
    return this.http.put('/plan/status', { status: 'inProgress' }).pipe(
      tap(() => {
        this.status = 'inProgress';
      this.progressService.setProgress(STEPS.length - 1);
    })
  );
  }

  /**
 * Submits the plan application, setting the status to 'submitted'.
 */
  submitApplication() {
    return this.http
      .put('/plan/status', { status: 'submitted' })
      .pipe(tap(() => (this.status = 'submitted')));
  }

  /**
 * Retrieves authentication users and signer information.
 *
 * @returns An observable of authentication users and signer data.
 */
  getAuthUsers(): Observable<any> {
    return this.http.get('/plan/auth-user');
  }

  /**
 * Updates the DocuSign status for a given ER (Employer Record) ID.
 *
 * @param erId - The ER ID for which to update the DocuSign status.
 */
  updateDocuSignStatus(erId) {
    return this.http.post(`/plan/docusign-status/${erId}`, {});
  }

  /**
 * Sends a help message with the provided form data.
 *
 * @param getHelpForm - The form data for the help message.
 */
  sendQuestion(getHelpForm) {
    const { recordTypeId } = environment.helpForm;
    const data: HelpMessage = {
      ...getHelpForm,
      recordTypeId,
      ein: this.compService.ein,
    };
    return this.http.post('/plan/send-help-message', data);
  }

  /**
 * Retrieves plan asset pricing data based on the provided data.
 *
 * @param data - The data for retrieving asset pricing information.
 * @returns An observable of plan asset pricing data.
 */
  getPlanAssetPricing(data): Observable<IPricing> {
    return this.http.post<IPricing>('/plan/pricing', data);
  }

  /**
 * Retrieves estimated assets for the plan.
 *
 * @returns An observable of estimated assets data.
 */
  getEstimatedAssets(): Observable<IEstimatedAssets> {
    return this.http.get<IEstimatedAssets>('/plan/estimated-assets');
  }
}

interface PlanBasics {
  ageRequirement: string;
  effectiveDate: string;
  entrySchedule: string;
  requirementWavier: boolean;
  serviceRequirement: string;
  hasMissingHours: boolean;
}

interface HelpMessage {
  recordTypeId: string;
  ein: string;
  name: string;
  email: string;
  phone: string;
  message: string;
}
