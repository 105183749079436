import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/internal/operators/tap';
import { map } from 'rxjs/internal/operators/map';
import { PlanService } from 'src/app/shared/services/plan.service';
import { ApiResponse } from 'src/app/shared/enums/api-response.enum';
import { IAuthData } from 'src/app/shared/models/auth-user.model';

@Injectable({
  providedIn: 'root'
})
export class AdminCheckingGuard implements CanActivate {

  constructor(private planService: PlanService,  private router: Router) { }

  canActivate(): Observable<boolean> {
    return this.planService.getAuthUsers().pipe(
      map((res: IAuthData) => !!res?.authUser?.length && !!res?.signer),
      tap((isAuthorized: boolean) => {
        if (!isAuthorized) {
          this.router.navigate([ApiResponse.AUTHORIZATION]);
        }
      })
    );
  }
  
}