import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { LIMITS } from 'src/app/shared/constants/limits.data';
import { ApiResponse } from 'src/app/shared/enums/api-response.enum';
import { CompanyService } from 'src/app/shared/services/company.service';
import { ProgressService } from 'src/app/shared/services/progress.service';
import { Count } from 'src/app/shared/enums/common-form.enum';

@Injectable({
  providedIn: 'root'
})
export class EmployeeCheckingGuard implements CanActivate {
  totalPercentage!: number;

  constructor(private eeService: EmployeeService, private companyService: CompanyService, private router: Router, private progressService: ProgressService
  ) {
    //
   }
  canActivate(): Observable<boolean> {
    let checkValidation = forkJoin([this.eeService.getEmployees(), this.companyService.isNonProfitType()])
    return checkValidation.pipe(
      map((res: any) => {
        this.totalPercentage = 0;
        res[0].owners.concat(res[0].ownerEntity).forEach(result => {
          this.totalPercentage = this.totalPercentage + result.percentage;
        })
        if ((this.totalPercentage !== LIMITS.OWNER_INFORMATION_LIMIT && !res[Count.ONE]) || (this.progressService.categoryStatusType.getValue() !== Count.ZERO && this.progressService.categoryStatusType.getValue() < Count.THREE)) {
          this.router.navigate([ApiResponse.EMPLOYEE]);
        }
        return (this.totalPercentage === LIMITS.OWNER_INFORMATION_LIMIT || (res[Count.ONE]));
      })
    );
  }
}