import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appMaxValue]'
})
export class MaxValueDirective {

  @Input()
  maxValue: number;
  constructor(private ref: ElementRef, private control: NgControl) {
    //
   }
  @HostListener('input', ['$event'])
  onInput(): void {
    let val = parseInt(this.ref.nativeElement.value?.replace(/,/g, ""));
    if (!isNaN(val) && val > this.maxValue) {
      this.control.control.setValue(this.maxValue.toString());
    }
  }
}
