import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

const GA_MEASUREMENT_ID = 'UA-1340604-15';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  sendPageView(page_path: string) {
    if (environment.production) {
      gtag('config', GA_MEASUREMENT_ID, {
        page_path,
      });
    }
  }

  sendEvent(event: string, value?: number) {
    if (environment.production) {
      gtag('event', event, { value });
    }
  }
}
