import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ps-input-bool',
  templateUrl: './input-bool.component.html',
  styleUrls: ['./input-bool.component.scss'],
})
export class InputBoolComponent implements OnInit {
  @Input() value;
  @Input() control: FormControl;
  @Input() yesTooltip: string;
  @Input() noTooltip: string;
  @Output() onUpdate = new EventEmitter();

  ngOnInit() {
    if (this.value !== undefined && this.control !== undefined) {
      console.error(
        'Only one property binding of "value" or "control" is allowed.'
      );
    }
  }

  setValue(value) {
    if (this.control) {
      this.control.setValue(value);
    }
    this.onUpdate.emit(value);
  }
}
