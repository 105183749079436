import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { finalize } from 'rxjs/internal/operators/finalize';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { QUALIFICATION } from 'src/app//shared/constants/steps.data';
import {
  ACCORDION_STEPS,
  DESC_1,
  DESC_2,
  DESC_3,
  ENTITY,
  HEADER_1,
  HEADER_2,
  HEADER_3,
  INDIVIDUAL,
  NORMAL_DESC,
  NORMAL_HEADER,
  TYPE,
  YES,
} from 'src/app/shared/constants/accordion-steps.data';
import { LIMITS } from 'src/app/shared/constants/limits.data';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { ProgressService } from 'src/app/shared/services/progress.service';
import { PlanService } from 'src/app/shared/services/plan.service';
import { IrsLimitsService } from 'src/app/shared/services/irs-limits.service';
import { CommonData, CommonFormType, Count  } from 'src/app/shared/enums/common-form.enum';
import { STATUS } from 'src/app/shared/enums/status.enum';
import { IRSLimits } from 'src/app/shared/models/irs-limits.model';
import { IAuthData, ISigner } from 'src/app/shared/models/auth-user.model';
import { IEmployeeSteps } from 'src/app/shared/models/employees.model';
import { CompanyService } from 'src/app/shared/services/company.service';

@Component({
  selector: 'ps-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss'],
})
export class EmployeesComponent implements OnInit, OnDestroy {
  @ViewChild('commonForm', { static: true }) commonForm: ModalComponent;
  @ViewChild('removePopup', { static: true }) removePopup: ModalComponent;
  @ViewChild('officerModal', { static: true }) officerModal: ModalComponent;
  formTypes = CommonFormType;
  formType: number = 0;
  commonEnumData = CommonData;
  ownerInformationDetails = [];
  linealRelative = [];
  hcesData = [];
  officersData = [];
  isLinealRelativeEmployees = new FormControl(this.commonEnumData.NO);
  isHCEs = new FormControl(this.commonEnumData.NO);
  isOfficers = new FormControl(this.commonEnumData.NO);
  accordionData: Array<IEmployeeSteps> = ACCORDION_STEPS;
  authPlanAdmin = [];
  isAuthPlanAdmin = new FormControl('no');
  removeData;
  isLoading: boolean = false;
  errorMsg!: string;
  totalPercentage: number = 0;
  onDestroy: Subject<void> = new Subject<void>();
  relativeOf = [];
  currentStep: number = 0;
  removeModalHeader!: string;
  removeModalDesc!: string;
  removedDataList: Array<string> = [];
  isDeleting!: boolean;
  irsLimits!: IRSLimits;
  status = STATUS;
  currentSigner!: ISigner;
  authorizationData!: IAuthData;
  removedSignerContactId!: number;
  isNonProfit!: boolean;
  checkingIsNonProfit!: boolean;

  constructor(
    private planService: PlanService,
    private router: Router,
    private eeService: EmployeeService,
    private progressService: ProgressService,
    private irsLimitsService: IrsLimitsService,
    private route: ActivatedRoute,
    public companyService: CompanyService
  ) {
    progressService.onSave = this.onSave.bind(this);
  }

  /**
 * Initializes the component when it is created.
 * This function retrieves necessary data, sets the initial step, and handles query parameters.
 */
  ngOnInit(): void {
    this.getHCEsData();
    this.getSigner();
    this.getLimits();
    this.checkCompanyType();
    this.goNext(this.currentStep);
    const paramName = 'step';
    const paramValue = this.route.snapshot.queryParamMap.get(paramName);
    if (paramValue == '2') {
      this.goNext(2);
    }
  }

  /**
 * Lifecycle hook called when the component is destroyed.
 * It removes the 'onSave' callback from the 'progressService'.
 */
  ngOnDestroy(): void {
    this.progressService.onSave = null;
  }

  /**
 * Saves changes to Highly Compensated Employees (HCE) data by sending an update request to the server.
 * This function filters individual and entity owners, removes the 'type' property,
 * and then sends the update request to the 'eeService'. It sets the 'isLoading' flag
 * during the request and finalizes it when the request is complete.
 *
 * @returns An Observable that resolves when the update is successful.
 */
  onSave(): Observable<any> {
    const owner = this.ownerInformationDetails.filter(
      (x) => x.type == INDIVIDUAL
    );
    const ownerEntity = this.ownerInformationDetails.filter(
      (x) => x.type == ENTITY
    );
    owner.forEach((x) => delete x.type);
    ownerEntity.forEach((x) => delete x.type);
    this.isLoading = true;
    return this.eeService
      .updateHCE(
        owner,
        ownerEntity,
        this.linealRelative,
        this.hcesData,
        this.officersData,
        null
      )
      .pipe(finalize(() => (this.isLoading = false)));
  }

  /**
 * Navigates to the previous step in the process and updates the current step index.
 * It also manages the state of the accordion sections, setting the specified step as active.
 *
 * @param {number} step - The step to navigate back to.
 */
  goBack(step: number): void {
    this.currentStep = step;
    this.accordionData.map((x) => {
      x.isActive = false;
    });
    this.accordionData[step].isActive = true;
  }

  /**
 * Navigates to the next step in the process and updates the current step index.
 * It also sets the state of the accordion sections to match the current step.
 *
 * @param {number} step - The step to navigate to.
 */
  goNext(step: number): void {
    this.currentStep = step;
    if (step === LIMITS.LAST_STEP && this.officersData.length === Count.ZERO && this.ownerInformationDetails.filter((x) => x.type == INDIVIDUAL).length === Count.ZERO && !this.isNonProfit) {
      this.officerModal.open();
    }
    else {
      this.setAccordion();
    }
  }

  /**
 * Sets the state of accordion sections based on the current step index.
 * It deactivates all sections and activates the section corresponding to the current step.
 * If the current step exceeds the last step, it updates progress and navigates to the next page.
 */
  setAccordion(): void {
    this.accordionData.map((x) => {
      x.isActive = false;
    });

    if (this.currentStep >= LIMITS.LAST_STEP) {
      this.progressService.updateProgress(QUALIFICATION.step);
      this.updateCompanyTypeChanged();
      this.router.navigateByUrl(QUALIFICATION.url);
    } else this.accordionData[this.currentStep].isActive = true;
  }

  /**
 * Opens a common form modal based on the specified form type and optional edit data.
 *
 * @param {number} type - The type of the form.
 * @param {number | null} index - The index of the form (optional).
 * @param {Object} editData - The data to prepopulate the form with for editing (optional).
 */
  openForm(type: number, index?: number | null, editData?: {}): void {
    this.formType = type;
    if (editData) {
      const data = { editData, type, index };
      this.eeService.editFormData.next(data);
    }
    this.eeService.editDataIndex = index;
    this.commonForm.open();
  }

  /**
 * Updates the signer information for authorization.
 *
 * @throws {Error} If an error occurs while updating the signer.
 */
  async updateSigner(): Promise<void> {
    try {
      await this.planService.updateUsers(this.authorizationData.authUser, this.authorizationData.signer).toPromise();
    } catch (error) {
      console.error('An error occurred while updating signer:', error);
    }
  }

  /**
 * Closes the common form modal and emits an event to signal modal closure.
 * Resets the form to its default value based on the form type.
 */
  closeFormModal(): void {
    this.commonForm.close();
    this.eeService.formModalClosed.next(true);
    this.setDefaultValue(this.formType);
  }

  /**
 * Handles the saving of form data from various form types (Owner Information, Lineal Relative, HCE, Officers).
 * Updates or appends the form data to the respective data arrays based on the form type.
 * If the form data includes a signer, it updates the signer's information and triggers an update.
 * Finally, submits the form data.
 *
 * @param event - The event containing the form data, form type, and related data.
 */
  commonFormSave(event): void {
    switch (event.type) {
      case this.formTypes.OWNER_INFORMATION_FORM:
        if (!!event?.index || event?.index === 0) {
          const ownerInfo = this.ownerInformationDetails[event.index];
          if (ownerInfo) {
            this.ownerInformationDetails[event.index] = event.formData;
            const isSigner = this.checkSigner(ownerInfo.contactId);
            if (isSigner) {
              const signerData = this.ownerInformationDetails[event.index];
              const signer = this.authorizationData?.signer[0];
              signer.firstName = signerData.firstName;
              signer.lastName = signerData.lastName;
              signer.title = signerData.title;
              this.updateSigner();
            }
          }
        } else {
          this.ownerInformationDetails.push(event.formData);
        }
        if (
          event.relValue !==
          event.formData.firstName + ' ' + event.formData.lastName
        )
          this.updateRelOptions(
            event.relValue,
            event.formData.firstName + ' ' + event.formData.lastName
          );
        break;
      case this.formTypes.LINEAL_RELATIVE_FORM:
        if (event?.index === 0)
          this.linealRelative[event.index] = event.formData;
        else this.linealRelative.push(event.formData);
        break;
      case this.formTypes.HCE_FORM:
        if (event?.index === 0)
          this.hcesData[event.index] = event.formData;
        else this.hcesData.push(event.formData);
        break;
      case this.formTypes.OFFICERS_FORM:
        if (!!event?.index || event?.index === 0) {
          const officersInfo = this.officersData[event.index];
          if (officersInfo) {
            this.officersData[event.index] = event.formData;
            if (this.checkSigner(officersInfo.contactId)) {
              const signer = this.authorizationData?.signer[0];
              const officersData = this.officersData[event.index];
              signer.firstName = officersData.firstName;
              signer.lastName = officersData.lastName;
              this.updateSigner();
            }
          }
        } else {
          this.officersData.push(event.formData);
        }
        break;
    }
    this.submitForm();
  }

  /**
 * Calculates the total percentage of owner information data and checks if it reaches the specified limit.
 *
 * @returns {boolean} - True if the total percentage equals the owner information limit; otherwise, false.
 */
  get ownerInformationPercentage(): boolean {
    this.totalPercentage =
      this.ownerInformationDetails?.reduce((accumulator: number, object) => {
        return accumulator + +object.percentage;
      }, 0) || 0;
    return this.totalPercentage == LIMITS.OWNER_INFORMATION_LIMIT;
  }

  /**
 * Checks if lineal relative employees are present based on the selected option.
 *
 * @returns {boolean} - True if lineal relative employees are present, or if the selected option is 'YES'; otherwise, false.
 */
  get checkisLinealRelativeEmployees(): boolean {
    return this.isLinealRelativeEmployees.value == YES
      ? this.linealRelative.length > 0
      : true;
  }

  /**
 * Checks if HCEs (Highly Compensated Employees) data is valid based on the selected option.
 *
 * @returns {boolean} - True if HCEs data is valid, or if the selected option is 'YES'; otherwise, false.
 */
  get checkHcesValid(): boolean {
    return this.isHCEs.value == YES ? this.hcesData.length > 0 : true;
  }

  /**
 * Checks if officers' data is valid based on the selected option.
 *
 * @returns {boolean} - True if officers' data is valid, or if the selected option is 'YES'; otherwise, false.
 */
  get officerValid(): boolean {
    return this.isOfficers.value == YES ? this.officersData.length > 0 : true;
  }

  /**
 * Sets employee data and initializes related form fields.
 *
 * @param {object} data - The employee data to be set.
 */
  setEmployeeData(data): void {
    data.owners.map((element) => (element[TYPE] = INDIVIDUAL));
    data.ownerEntity.map((element) => (element[TYPE] = ENTITY));
    this.ownerInformationDetails = [...data.owners, ...data.ownerEntity];
    this.ownerInformationDetails = this.ownerInformationDetails.sort(function (firstElement, secondElement) { return firstElement?.contactId - secondElement?.contactId });
    this.updateRelOptions();
    this.linealRelative = data.ownerRels.sort(function (firstElement, secondElement) { return firstElement?.contactId - secondElement?.contactId });
    this.linealRelative.length > 0
      ? this.isLinealRelativeEmployees.setValue(this.commonEnumData.YES)
      : this.isLinealRelativeEmployees.setValue(this.commonEnumData.NO);
    this.hcesData = data.eeOver120k.sort(function (firstElement, secondElement) { return firstElement?.contactId - secondElement?.contactId });;
    this.hcesData.length > 0
      ? this.isHCEs.setValue(this.commonEnumData.YES)
      : this.isHCEs.setValue(this.commonEnumData.NO);
    this.officersData = data.officers.sort(function (firstElement, secondElement) { return firstElement?.contactId - secondElement?.contactId });;
    this.officersData.length > 0
      ? this.isOfficers.setValue(this.commonEnumData.YES)
      : this.isOfficers.setValue(this.commonEnumData.NO);
  }

  /**
 * Set default values for form fields based on the form type.
 *
 * @param {CommonFormType} type - The type of the common form.
 */
  setDefaultValue(type: CommonFormType): void {
    switch (type) {
      case this.formTypes.LINEAL_RELATIVE_FORM:
        this.linealRelative.length == 0
          ? this.isLinealRelativeEmployees.setValue(this.commonEnumData.NO)
          : '';
      case this.formTypes.HCE_FORM:
        this.hcesData.length == 0 ? this.isHCEs.setValue(this.commonEnumData.NO) : '';
      case this.formTypes.OFFICERS_FORM:
        this.officersData.length == 0 ? this.isOfficers.setValue(this.commonEnumData.NO) : '';
    }
  }

  /**
 * Submits the form data, triggers the onSave operation, and handles success or error responses.
 */
  submitForm(): void {
    this.isLoading = true;
    this.onSave().subscribe(
      () => {
        this.getHCEsData();
        this.isLoading = false;
        this.closeFormModal();
      },
      (error) => (this.errorMsg = error.error.message)
    );
  }

 /**
 * Fetches employee data from the service and sets it in the component.
 */
  getHCEsData(): void {
    this.eeService
      .getEmployees()
      .pipe()
      .subscribe((data) => {
        this.setEmployeeData(data);
      });
  }

 /**
 * Closes the remove popup and clears the removed data list.
 */
  closeRemovePopup(): void {
    this.removePopup.close();
    this.removedDataList = [];
  }

  /**
 * Updates the relative options for lineal relatives based on the percentage of individual owners.
 * @param {string} relative - The relative to be updated.
 * @param {string} latest - The latest value for the relative.
 */
  private updateRelOptions(relative?: string, latest?: string): void {
    const options = this.ownerInformationDetails
      .filter((owner) => owner.percentage > 5 && owner.type == INDIVIDUAL)
      ?.map((owner) => owner?.firstName + ' ' + owner?.lastName);
      this.relativeOf=[];
      options.forEach(x=>{
      this.relativeOf.push({key:x,value:x})
    });

    if (relative) {
      this.linealRelative?.forEach((x) => {
        if (x.relativeOf === relative) {
          x.relativeOf = latest;
        }
      });
    }
  }

  /**
 * Retrieves signer and authorization data from the plan service.
 * Updates the `authorizationData` and `currentSigner` properties with the retrieved data.
 */
  private getSigner(): void {
    this.planService
    .getAuthUsers()
    .subscribe(
      (data) => {
          if (data) {
            this.authorizationData = data;
            this.currentSigner = this.authorizationData.signer ? this.authorizationData?.signer[0]:null;
          }
      },
      (error) => (this.errorMsg = error.error.message)
    );
  } 

  /**
 * Checks if a given contact ID matches the contact ID of the current signer.
 *
 * @param {number} contactId - The contact ID to check.
 * @returns {boolean} True if the contact ID matches the signer's contact ID, false otherwise.
 */
  private checkSigner(contactId: number): boolean {
    return this.currentSigner?.relativeContactId === contactId;
  }

  /**
 * Opens a delete confirmation popup based on the provided form type, index, and other optional parameters.
 *
 * @param {CommonFormType} type - The type of form being deleted.
 * @param {number} [index] - The index of the form data being deleted (optional).
 * @param {boolean} [isCallFromRadio] - Indicates if the deletion is called from a radio button selection (optional).
 */
  openDeletePopUp(
    type: CommonFormType,
    index?: number,
    isCallFromRadio?: boolean
  ): void {
    this.removedDataList = [];
    this.removeModalHeader = NORMAL_HEADER;
    this.removeModalDesc = NORMAL_DESC;
    let isSigner = false;
    let isOwnerOrOfficer = ((type === this.formTypes.OWNER_INFORMATION_FORM || type === this.formTypes.OFFICERS_FORM));
    switch (type) {
      case this.formTypes.OWNER_INFORMATION_FORM:
        const ownerInfo = this.ownerInformationDetails[index];
        const name = `${ownerInfo.firstName} ${ownerInfo.lastName}`;
        const linealRelatives = this.linealRelative.filter(obj => obj.relativeOf === name);
        isSigner = this.checkSigner(ownerInfo.contactId);
        if (isSigner && isOwnerOrOfficer && linealRelatives.length > 0) {
          this.removeModalHeader = HEADER_2;
          this.removeModalDesc = DESC_2;
          this.removedDataList = linealRelatives.map(x => x.contactId);
        } else if (isSigner && isOwnerOrOfficer) {
          this.removeModalHeader = HEADER_3;
          this.removeModalDesc = DESC_3;
        } else if (isSigner || linealRelatives.length > 0) {
          this.removeModalHeader = HEADER_1;
          this.removeModalDesc = DESC_1;
          if (linealRelatives.length > 0) {
            this.removedDataList = linealRelatives.map(x => x.contactId);
          }
        }
        this.removedDataList.push(ownerInfo.contactId);
        if (isSigner) {
          this.removedSignerContactId = this.currentSigner?.contactId;
        }
        break;
      case this.formTypes.LINEAL_RELATIVE_FORM:
        const linealRelativeData = isCallFromRadio ? this.linealRelative : [this.linealRelative[index]];
        this.removedDataList = linealRelativeData.map(x => x.contactId);
        break;
      case this.formTypes.HCE_FORM:
        const hcesData = isCallFromRadio ? this.hcesData : [this.hcesData[index]];
        this.removedDataList = hcesData.map(x => x.contactId);
        break;
      case this.formTypes.OFFICERS_FORM:
        const officerData = this.officersData[index];
        isSigner = this.checkSigner(officerData.contactId);
        if (isSigner && isOwnerOrOfficer) {
          this.removeModalHeader = HEADER_3;
          this.removeModalDesc = DESC_3;
        }
        if (isCallFromRadio) {
          this.removedDataList = this.officersData.map(x => x.contactId);
        } else {
          this.removedDataList.push(officerData.contactId);
        }
        if (isSigner) {
          this.removedSignerContactId = this.currentSigner.contactId;
        }
        break;
    }
    this.removePopup.open();
  }
  
  /**
 * Deletes a contact with the specified contact ID.
 *
 * @param {number} contactId - The ID of the contact to be deleted.
 */
  private deleteContact(contactId): void {
    if (contactId) {
      this.eeService.deleteContact(contactId).subscribe();
    }
  }

  /**
 * Deletes selected data, including contacts and related records.
 * This function first deletes the contacts specified in the `removedDataList`,
 * then deletes the signer contact if necessary, and finally refreshes the data.
 */
  deleteData(): void {
    this.isDeleting = true;
    this.eeService.deleteContactList(this.removedDataList).subscribe((res) => {
      this.getHCEsData();
      this.isDeleting = false;
      this.removePopup.close();
    });
    this.deleteContact(this.removedSignerContactId);
  }

  /**
 * Changes the status for a specific form element.
 * 
 * @param {Event} event - The event object.
 * @param {CommonFormType} type - The type of form element to change the status for.
 */
  changeStatus(event, type): void {
    event.preventDefault();
    event.stopPropagation();
    this.openDeletePopUp(type, null, true);
  }

  /**
 * Retrieves IRS limits based on the effective date and updates the component's IRS limits data.
 */
  getLimits(): void {
    this.irsLimitsService.getLimitsByEffectiveDate().subscribe((limits) => this.irsLimits = limits);
  }

   /**
 * Check at least one Individual owner or officer.
 */
  assignOfficer(): void {
    this.officerModal.close();
    this.isOfficers.setValue(this.commonEnumData.YES);
    this.openForm(this.formTypes.OFFICERS_FORM, null);
  }

   /**
* Check company type non-profit or not.
*/
checkCompanyType(): void{
  this.checkingIsNonProfit = false;
  this.companyService.isNonProfitType().subscribe(res => {
    this.isNonProfit = res;
    this.accordionData[Count.TWO].isActive = res;
    this.accordionData[Count.TWO].isBackButton = !res  
    this.checkingIsNonProfit = true;
    if(this.progressService.categoryStatusType.getValue() === Count.TWO )
    this.progressService.openCompanyChangePopUp(res);
  })
}

updateCompanyTypeChanged(): void {
  if (this.progressService.companyStatusCompleted()) {
    this.progressService.setCompanyTypeStatus(Count.THREE);
    this.progressService.setCompanyTypeChanged(Count.THREE.toString());
  }
}

}
