<div class="get-help-widget block" *ngIf="showForm" @widget>
  <button class="close-btn btn" (click)="showForm = false">
    <i class="uil uil-times"></i>
  </button>
  <form [formGroup]="form" *ngIf="!received" (ngSubmit)="send()" @widgetContent>
    <div class="contact-info">
      <h4 class="title">We are here to help</h4>
      <p class="note">
        One of our team members will reach out to you via email.
      </p>
      <div class="form-group">
        <label class="label-small">Name</label>
        <input
          type="text"
          placeholder="Required"
          class="form-control"
          formControlName="name"
          name="name"
        />
      </div>
      <div class="form-group">
        <label class="label-small">Phone</label>
        <input
          type="text"
          placeholder="Optional"
          class="form-control"
          formControlName="phone"
          name="phone"
        />
      </div>
      <div class="form-group mb-0">
        <label class="label-small">Email</label>
        <input
          type="text"
          placeholder="Required"
          class="form-control"
          formControlName="email"
          name="email"
        />
      </div>
    </div>
    <div class="message">
      <textarea
        class="message__input"
        name="message"
        placeholder="Send a message..."
        (input)="
          msg.style.height = ''; msg.style.height = msg.scrollHeight + 'px'
        "
        formControlName="message"
        #msg
      ></textarea>
      <button type="submit" class="message__send btn btn-primary-bg" *ngIf="form.valid">
        <span class="message__send__text" [style.opacity]="loading ? 0 : 1">
          Send
        </span>
        <ps-loading-dots *ngIf="loading"></ps-loading-dots>
      </button>
    </div>
  </form>
  <div class="contact-info" *ngIf="received" @widgetContent>
    <h4 class="title">Message sent successfully</h4>
    <p>We have received your message and will get back to you soon.</p>
  </div>
</div>
<button
  class="get-help-btn btn"
  (click)="toggleForm()"
  [class.active]="showForm"
>
  <i class="fas fa-envelope"></i>
  Get Help
</button>
