import { ContributionType } from '../models/contribution';

const benefitText = 'Plan Design will exempt your plan from some non-discrimination tests. For more information please review this <a href="https://slavic401k.atlassian.net/wiki/spaces/PSKB/pages/1321369743/The+Safe+Harbor+Plan+Design+Election" target="_blank">General Information Guide</a> or refer to this <a href="https://www.law.cornell.edu/cfr/text/26/1.401(k)-3" target="_blank">Cornell Law School</a> page for additional details.';

const shBenefit = `A Safe Harbor ${benefitText}`;

const qacaBenefit = `A QACA ${benefitText}`;

export const CONTRIBUTION_TYPES: { sh: ConType[]; disc: ConType[] } = {
  sh: [
    {
      id: 'shMatch',
      label: 'Safe Harbor Match',
      // TODO: place tag under tab (Safe Harbor) name
      // tag: 'Recommended'
      benefit: shBenefit,
    },
    {
      id: 'shNonElective',
      label: 'Safe Harbor Non-Elective',
      benefit: shBenefit,
    },
    {
      id: 'qacaMatch',
      label: 'QACA Match',
      benefit: qacaBenefit,
    },
    {
      id: 'qacaNonElective',
      label: 'QACA Non-Elective',
      benefit: qacaBenefit,
    },
  ],
  disc: [
    {
      id: 'match',
      label: 'Other Employer Match',
      description: '',
    },
  ],
};

export interface ConType {
  id: ContributionType;
  label: string;
  tag?: string;
  benefit?: string;
  description?: string;
}
