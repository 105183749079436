import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ENTITY,
  INDIVIDUAL,
} from 'src/app/shared/constants/accordion-steps.data';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { CommonFormType } from 'src/app/shared/enums/common-form.enum';
import { FieldLength } from 'src/app/shared/validators/field-length';
import {
  NAME_VALIDATORS,
  PERCENTAGE_VALIDATORS,
  TITLE_VALIDATORS,
} from 'src/app/shared/validators/field-validators';
import { MaskConfigs } from 'src/app/shared/validators/masks';
import { IOwnerInformation } from 'src/app/shared/models/employees.model';
import { FieldValidators } from 'src/app/shared/validators/validators';
import {ON_PAYROLL, OWNER_TYPE, RELATION_SHIP} from 'src/app/shared/constants/dropdown-values.const'
@Component({
  selector: 'app-common-form',
  templateUrl: './common-form.component.html',
  styleUrls: ['./common-form.component.scss'],
})
export class CommonFormComponent implements OnInit, OnChanges {
  constructor(public eeService: EmployeeService) {}
  ownerType = OWNER_TYPE;
  onPayroll = ON_PAYROLL;
  relationship = RELATION_SHIP;
  @Input() formType;
  @Input() header;
  private relData: [];
  tempRelValue: string;
  formTypes = CommonFormType;
  @Input() set relativeOf(value: []) {
    this.relData = value;
  }

  get relativeOf(): [] {
    return this.relData;
  }

  @Input() isLoading;
  currentForm: FormGroup;
  @Output() formValueSave = new EventEmitter();
  mask = MaskConfigs;
  editIndex!: number;
  individualTypeControls = [
    { controlName: 'firstName', validator: NAME_VALIDATORS },
    { controlName: 'lastName', validator: NAME_VALIDATORS },
    { controlName: 'title', validator: TITLE_VALIDATORS },
    { controlName: 'percentage', validator: PERCENTAGE_VALIDATORS },
    { controlName: 'onPayroll', validator: Validators.required },
  ];
  entityTypeControls = [
    { controlName: 'name', validator: NAME_VALIDATORS },
    { controlName: 'percentage', validator: PERCENTAGE_VALIDATORS },
  ];
  ownerInformationForm: FormGroup = new FormGroup({
    type: new FormControl(null, [Validators.required]),
    contactId: new FormControl(''),
  });

  linealRelativeFrom: FormGroup = new FormGroup({
    firstName: new FormControl('', NAME_VALIDATORS),
    lastName: new FormControl('', NAME_VALIDATORS),
    relationship: new FormControl(null, [Validators.required]),
    relativeOf: new FormControl('', [Validators.required]),
    contactId: new FormControl(),
  });

  hceForm: FormGroup = new FormGroup({
    firstName: new FormControl('', NAME_VALIDATORS),
    lastName: new FormControl('', NAME_VALIDATORS),
    contactId: new FormControl(),
  });

  officersForm: FormGroup = new FormGroup({
    firstName: new FormControl('', NAME_VALIDATORS),
    lastName: new FormControl('', NAME_VALIDATORS),
    title: new FormControl('', TITLE_VALIDATORS),
    contactId: new FormControl(),
    relativeContactId: new FormControl(),
  });

  authPlanAdmin: FormGroup = new FormGroup({
    firstName: new FormControl('', NAME_VALIDATORS),
    lastName: new FormControl('', NAME_VALIDATORS),
    title: new FormControl('', TITLE_VALIDATORS),
    email: new FormControl('', [
      FieldValidators.email,
      Validators.required,
      Validators.maxLength(FieldLength.EMAIL),
    ]),
    contactId: new FormControl(),
  });

  private setOwnerInformationForm(
    type: string,
    isEdit: boolean,
    formValue?: IOwnerInformation
  ): void {
    const addedFormControl =
      type === INDIVIDUAL
        ? this.individualTypeControls
        : this.entityTypeControls;
    const removeFormControl =
      type === ENTITY ? this.individualTypeControls : this.entityTypeControls;
    removeFormControl.forEach((control) => {
      this.ownerInformationForm.removeControl(control.controlName);
    });
    addedFormControl.forEach((control) => {
      this.ownerInformationForm.addControl(
        control.controlName,
        new FormControl(null, control.validator)
      );
    });
    if (isEdit) this.setFormData(formValue);
  }

  ngOnInit() {
    this.eeService.formModalClosed.subscribe((res) => {
      if (res) {
        this.formReset();
      }
    });
    this.eeService.editFormData.subscribe((res) => {
      if (res) {
        this.setEditValues(res);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.formType = changes?.formType?.currentValue || this.formType;
    this.currentForm = this.getCurrentForm();
    this.editIndex = changes?.index?.currentValue;
  }

  private setEditValues(data): void {
    this.formType = data?.type;
    this.currentForm = this.getCurrentForm();
    this.editIndex = data?.index;
    if (data) {
      switch (this.formType) {
        case this.formTypes.OWNER_INFORMATION_FORM:
          this.ownerInformationForm.get('type').setValue(data.editData.type);
          this.tempRelValue =
            data?.editData?.firstName + ' ' + data?.editData?.lastName;
          this.setOwnerInformationForm(
            data?.editData?.type,
            true,
            data.editData
          );
          break;
        case this.formTypes.LINEAL_RELATIVE_FORM:
          this.linealRelativeFrom.setValue(data.editData);
          break;
        case this.formTypes.HCE_FORM:
          this.hceForm.setValue(data.editData);
          break;
        case this.formTypes.OFFICERS_FORM:
          this.officersForm.setValue(data.editData);
          break;
        case this.formTypes.AUTH_PLAN_ADMIN:
          this.authPlanAdmin.setValue(data.editData);
          break;
      }
    }
  }

  private getCurrentForm(): FormGroup {
    switch (this.formType) {
      case this.formTypes.OWNER_INFORMATION_FORM:
        return this.ownerInformationForm;
      case this.formTypes.LINEAL_RELATIVE_FORM:
        return this.linealRelativeFrom;
      case this.formTypes.HCE_FORM:
        return this.hceForm;
      case this.formTypes.OFFICERS_FORM:
        return this.officersForm;
      case this.formTypes.AUTH_PLAN_ADMIN:
        return this.authPlanAdmin;
    }
  }

  private formReset(): void {
    switch (this.formType) {
      case this.formTypes.OWNER_INFORMATION_FORM:
        this.ownerInformationForm.reset();
        break;
      case this.formTypes.LINEAL_RELATIVE_FORM:
        this.linealRelativeFrom.reset();
        break;
      case this.formTypes.HCE_FORM:
        this.hceForm.reset();
        break;
      case this.formTypes.OFFICERS_FORM:
        this.officersForm.reset();
        break;
      case this.formTypes.AUTH_PLAN_ADMIN:
        this.authPlanAdmin.reset();
    }
  }

  private setFormData(data): void {
    this.ownerInformationForm.patchValue(data);
  }

  submit(value): void {
    const data = {
      formData: value,
      type: this.formType,
      index: this.eeService.editDataIndex,
      relValue: this.tempRelValue,
    };
    this.formValueSave.emit(data);
  }
}
