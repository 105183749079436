import { Component, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'message-warning',
  templateUrl: './message-warning.component.html',
  styleUrls: ['./message-warning.component.scss'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({ height: 0, paddingTop: 0, paddingBottom: 0, marginBottom: 0 }),
        animate('200ms ease-in-out', style({ height: '*', paddingTop: '*', paddingBottom: '*', marginBottom: '*' }))
      ]),
      transition(':leave', [
        style({ height: '*', paddingTop: '*', paddingBottom: '*', marginBottom: '*' }),
        animate('200ms ease-in-out', style({ height: 0, paddingTop: 0, paddingBottom: 0, marginBottom: 0 }))
      ])
    ])
  ]
})
export class MessageWarningComponent {
  @Input() message;
}
