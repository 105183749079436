<nav class="navbar px-0 px-sm-3">
  <div class="col-mob d-lg-none" *ngIf="(!!step)">
    <a class="btn toggle" (click)="toggleSidebar.emit()">
      <img src="assets/img/menu.svg" alt="" *ngIf="!showingSidebar">
      <i class="fas fa-times" *ngIf="showingSidebar"></i>
    </a>
  </div>
  <div class="col-mob-logo col-lg-2 logo-outer">
    <a href="https://ww2.slavic401k.com" class="flex">
      <img class="logo" src="./assets/img/logo-g.png" alt="slavic logo">
      <h5 class="">Plan Setup</h5>
    </a>
  </div>
  <!-- <div class="title">
    <h5>PLAN SETUP</h5>
    <span class="step" *ngIf="step">
      <span>Step </span>
      <span>{{ step }}</span>
      <span> of </span>
      <span>{{ defaultTotalStepsCount }}</span>
     </span>
  </div> -->
  <div class="nav__buttons col-5 col-lg-10 px-0 pricing-btn-mob">
    <ng-content></ng-content>
  </div>
</nav>

<ng-template #stepTemplate let-step="step" let-nonProfit="nonProfit">

</ng-template>
