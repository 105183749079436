import { Directive, HostListener, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[inputExclude]'
})
export class InputExcludeDirective {
  @Output() ngModelChange = new EventEmitter();
  @Input()
  set inputExclude(val: RegExp) {
    this.regex = new RegExp(val, 'g');
  }
  regex;

  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener('input')
  onChange() {
    const val = this.el.nativeElement.value;
    this.el.nativeElement.value = val.replace(this.regex, '');
    this.control.control.setValue(this.el.nativeElement.value);
    this.ngModelChange.emit(val.replace(this.regex, ''));
  }

}
