<div class="block" *ngIf="!loading; else load">
  <ng-container >
    <ps-help-card
      [referenceNum]="docRefId"
      [submittedDate]="submittedDate"
      [title]="message.CONFIRMATION_TITLE"
      [text]="message.CONFIRMATION_TEXT"
      (returnButtonClick)="goHome()"
    ></ps-help-card>
  </ng-container>
</div>

<ng-template #load>
  <ps-loading-dots></ps-loading-dots>
</ng-template>

