import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { trigger, transition, style, animate } from '@angular/animations';
import { filter } from 'rxjs/internal/operators/filter';
import { ProgressService } from 'src/app/shared/services/progress.service';
import { ANIM_TIMINGS } from 'src/app/shared/constants/animation.data';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { PlanService } from 'src/app/shared/services/plan.service';
import { IEstimatedAssets } from 'src/app/pages/features/models/plan-features.model';
import { URLS } from 'src/app/shared/enums/urls.enum';
const ALWAYS_SHOW_SIDEBAR_BREAKPOINT = '(min-width: 992px)';

@Component({
  selector: 'ps-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scaleX(1)', width: '0px' }),
        animate(ANIM_TIMINGS, style({ opacity: 1, transform: 'scaleX(1)', width: '*' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scaleX(1)', width: '*' }),
        animate(ANIM_TIMINGS, style({ opacity: 0, transform: 'scaleX(1)', width: '0px' }))
      ])
    ])
  ]
})
export class AppComponent implements OnInit {
  step: number |null;
  progress: number;
  showSummary: boolean;
  showSidebar: boolean;
  currentPath!: string;
  @ViewChild('content', { static: true }) content!: ElementRef;
  @ViewChild('pricingModal', { static: true }) pricingModal!: ModalComponent;
  isRetrieve!: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private progressService: ProgressService,
    private bpObserver: BreakpointObserver,
    private gaService: GoogleAnalyticsService,
    private planService: PlanService
  ) {
    this.step = null;
    this.progress = 0;
    this.showSidebar = false;
    this.showSummary = false;
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.isRetrieve = event.url.includes(URLS.REVIEW_DETAILS);
    });
  }

  /**
 * Initializes the component when it is first loaded.
 */
  ngOnInit(): void {
    // this.companyService.isNonProfitType().subscribe();
    this.progressService.progress.subscribe(p => this.progress = p);
    this.subscribeRouterEvent();
    this.bpObserver.observe(ALWAYS_SHOW_SIDEBAR_BREAKPOINT).subscribe(({ matches }) => {
      this.showSidebar = matches;
    });
  }

  /**
 * Toggles the display of the summary.
 */
  toggleSummary(): void {
    this.showSummary = !this.showSummary;
  }

  /**
 * Subscribes to router events to update page view, scroll to top, and handle sidebar visibility.
 */
  subscribeRouterEvent(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.gaService.sendPageView(e.urlAfterRedirects);
        this.content.nativeElement.scrollTo(0, 0);

        this.showSidebar = this.bpObserver.isMatched(ALWAYS_SHOW_SIDEBAR_BREAKPOINT);
        this.progressService.hasChanges = false;
        let route = this.route.firstChild;

        while (route.firstChild) {
          route = route.firstChild;
        }
        this.currentPath = route.routeConfig.path;
        this.step = route.snapshot.data.step;
        this.progressService.updateProgress(this.step);
      });
  }

  /**
 * Opens the pricing modal and fetches estimated assets data.
 */
  openPricingModal(): void {
    this.planService.getEstimatedAssets().subscribe((res: IEstimatedAssets) => {
      this.planService.estAssets.next(res.estimatedAssets);
      this.pricingModal.open();
    }, err => {
      this.pricingModal.close();
    })
  }
}
