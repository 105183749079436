import { IPlanDetails, IVestingData } from 'src/app/shared/models/plan-details.model'

export const planOptions: IPlanDetails[] = [
  {
    value: "safeHarbor",
    type:"safeHarbor",
    label: "Safe Harbor Match",
    cardLabel: "Safe Harbor",
    labelTag: "shMatch",
    tagLabel: "Safe Harbor Plan",
    icon: "assets/img/safe.svg",
    description: "Provide a predetermined Safe Harbor employer contribution (match or profit share) and exempt your plan from most 401(k) compliance testing.",
    calcLink: "",
    calcFormula:[
      {
        matchMessage: '',
        deferralMessage: ''
      }
    ],
    formula:{
      id: 2,
      label: 'Safe Harbor Basic Formula',
      tiers: [
        {
          from: 0,
          to: 6,
          matchPercent: 50
        }
      ]
    },
    formulaId: 2,
    id: 2,
    matchLimit: 0,
    companyContribution:"3%, 3.5%, or 4%",
    percent: 3,
    deferVal: 6,
    vesting:{
      id: 1,
      label: ["Immediate","2 Year Cliff"]
    },
    childPlan:[
      {
        value: "sfQuaca",
        type:"qacaMatch",
        label: "Safe Harbor Plan - QACA",
        cardLabel: "3.5% Match + Vesting",
        labelTag: "shMatch",
        tagLabel: "Safe Harbor Plan",
        icon: "assets/img/qaca.svg",
        description: "Match 100% of employee deferrals on the first 1% and 50% on the next 5%, for a maximum employer contribution of 3.5% This is the only Safe Harbor plan design that encourages employee retention through a vesting schedule for employer contributions.",
        calcLink: "",
        calcFormula:[
          {
            matchMessage: 'MATCH 100% OF DEFERRAL',
            deferralMessage: 'on the first 1% of compensation'
          },
          {
            matchMessage: 'MATCH 50% OF DEFERRAL',
            deferralMessage: 'on the next 5% of compensation'
          }
        ],
        formula:{
          id: 4,
          label: 'QACA Basic Formula',
          tiers: [
            {
              from: 0,
              to: 3.5,
              matchPercent: 50
            }
          ]
        },
        formulaId: 4,
        id: 4,
        matchLimit: 0,
        companyContribution:"3.5%",
        percent: 3.5,
        deferVal: 6,
        vesting:{
          id: 2,
          label: ["2 Year Cliff"]
        }
      },
      {
        value: "sfMatch",
        type:"shMatch",
        label: "Safe Harbor Plan - 4% Match",
        cardLabel: "4% Match",
        labelTag: "shMatch",
        tagLabel: "Safe Harbor Plan",
        icon: "assets/img/qaca.svg",
        description: "Match 100% of employee deferrals up to 4%, with a maximum employer contribution of 4%. Employer contributions are fully vested immediately.",
        calcLink: "",
        calcFormula:[
          {
            matchMessage: 'MATCH 100% OF DEFERRAL',
            deferralMessage: 'up to 4% of compensation'
          }
        ],
        formula:{
          id: 2,
          label: 'Safe Harbor Basic Formula',
          tiers: [
            {
              from: 0,
              to: 4,
              matchPercent: 50
            }
          ]
        },
        formulaId: 2,
        id: 0,
        matchLimit: 0,
        companyContribution:"4%",
        percent: 4,
        deferVal: 4,
        vesting:{
          id: 1,
          label: ["Immediate"]
        }
      },
      {
        value: "sfNonElective",
        type:"shNonElective",
        label: "Safe Harbor Plan - 3% Non-Elective",
        cardLabel: "3% Non-Elective",
        labelTag: "shMatch",
        tagLabel: "Safe Harbor Plan",
        icon: "assets/img/qaca.svg",
        description: "Provide employees with a 3% nonelective contribution, regardless of their own deferrals into the plan. Employer contributions are fully vested immediately.",
        calcLink: "",
        calcFormula:[
          {
            matchMessage: 'PROVIDE A 3% NON-ELECTIVE CONTRIBUTION',
            deferralMessage: 'to all employees'
          }
        ],
        formula:{
          id: 2,
          label: 'Safe Harbor Basic Formula',
          tiers: [
            {
              from: 0,
              to: 3,
              matchPercent: 50
            }
          ]
        },
        formulaId: 0,
        id: 6,
        matchLimit: 0,
        companyContribution:"3%",
        percent: 3,
        deferVal: 0,
        vesting:{
          id: 1,
          label: ["Immediate"]
        }
      }
    ]
  },
  {
    value: "match",
    type:"match",
    label: "Standard Match Plan",
    cardLabel: "Match",
    labelTag: "match",
    tagLabel: "Standard Match Plan",
    icon: "assets/img/match.svg",
    description: "Provide a standard match to incentivize employee participation and add a vesting schedule to incentivize retention. This option provides no exemption from 401(k) compliance testing.",
    calcLink: "",
    calcFormula:[
      {
        matchMessage: 'MATCH 50% OF DEFERRAL',
        deferralMessage: 'up to 6% of compensation'
      }
    ],
    formula:{
      id: 6,
      label: 'Customized Formula',
      tiers: [
        {
          from: 0,
          to: 6,
          matchPercent: 50
        }
      ]
    },
    formulaId: 1,
    id: 1,
    matchLimit: 0,
    companyContribution:"Any basic formula. Whole percent to whole percent",
    percent: 6,
    deferVal: 12,
    vesting:{
      id: 3,
      label: ["Immediate","3 Year Cliff","6 Year Graded"]
    },
    childPlan:[]
  },
  {
    value: "starter",
    type:"starter",
    label: "No Match Plan",
    cardLabel: "Starter",
    labelTag: "starter",
    tagLabel: "No Match Plan",
    icon: "assets/img/starter.svg",
    description: "Provide a 401(k) plan for your employees even if you're not ready to make employer contributions quite yet. This option provides no exemption from 401(k) compliance testing.",
    calcLink: "",
    calcFormula:[
      {
        matchMessage: '',
        deferralMessage: ''
      }
    ],
    formula:{
      id: 17,
      label: 'Starter',
      tiers: [
        {
          from: 0,
          to: 0,
          matchPercent: 0
        }
      ]
    },
    formulaId: 17,
    id: 7,
    matchLimit: 0,
    companyContribution:"N/A",
    percent: 0,
    deferVal: 0,
    vesting:{
      id: 0,
      label: ["N/A"]
    },
    childPlan:[]
  },
  {
    value: "custom",
    type:"custom",
    label: "Custom",
    cardLabel: "Custom",
    labelTag: "custom",
    tagLabel: "Customized Plan",
    icon: "assets/img/custom.svg",
    description: "Meet with a Plan Advisor Representative to create a customized 401(k) plan that meets your company's needs. This option provides fully-flexible plan design choices for matching, vesting, eligibility, etc. (please note that additional administration fees may apply).",
    calcLink: "",
    calcFormula:[
      {
        matchMessage: '',
        deferralMessage: ''
      }
    ],
    formula:{
      id: 18,
      label: 'Custom',
      tiers: [
        {
          from: 0,
          to: 0,
          matchPercent: 0
        }
      ]
    },
    formulaId: 18,
    id: 8,
    matchLimit: 0,
    companyContribution: "Customize",
    percent: 0,
    deferVal: 0,
    vesting:{
      id: 0,
      label: ["Customize"]
    },
    childPlan:[]
  }
];

export const vestingModalContent: IVestingData[] = [
  {
    heading: "Immediate",
    description: "A participant is 100% vested immediately.",
    data: null,
  },
  {
    heading: "2 year cliff",
    description: "The below chart shows the vesting percentages for 2 year cliff.",
    data: [{
      year: 0,
      vested: 0
    },{
      year: 1,
      vested: 0
    },
    {
      year: 2,
      vested: 100
    }
  ]
  },
  {
    heading: "3 year cliff",
    description: "The below chart shows the vesting percentages for 3 year cliff.",
    data: [{
      year: 0,
      vested: 0
    },{
      year: 1,
      vested: 0
    },
    {
      year: 2,
      vested: 0
    },
    {
      year: 3,
      vested: 100
    }]
  },
  {
    heading: "6 year graded",
    description: "The below chart shows the vesting percentages for 6 year graded.",
    data: [{
      year: 0,
      vested: 0
    },{
      year: 1,
      vested: 0
    },
    {
      year: 2,
      vested: 20
    },
    {
      year: 3,
      vested: 40
    },
    {
      year: 4,
      vested: 60
    },
    {
      year: 5,
      vested: 80
    },
    {
      year: 6,
      vested: 100
    }
  ]
  }
];