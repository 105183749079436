import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VestingService {
 
  constructor(private http: HttpClient) {
    //
  }

  getVestingOptions(type): Observable<VestingSchedule[]> {
    return this.http.get<VestingSchedule[]>(`/plan/vesting-schedule/${type}`);
  }
}

export class VestingSchedule {
  id: number;
  label: string;
  schedule: {
    year: number;
    vestedPercent: number;
  }[];
}
