import { Component } from '@angular/core';
@Component({
  selector: 'ps-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent{

  constructor(
  ) {
    //
  }
}
