import { Injectable } from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { PriorPlan } from 'src/app/shared/services/prior-plan.service';
import { DATE_TYPES } from 'src/app/shared/enums/plan-selection.enum';

const OCTOBER = 10;
const FIRST_MONTH = 1;
const FIRST_DAY = 1;
const FIFTEENTH_DAY = 15;
const LEAD_DAYS_COUNT = 14;

@Injectable({
  providedIn: 'root',
})
export class DateService {
  private disableDatePeriod: boolean;
  constructor(private calendar: NgbCalendar) {
    this.disableDatePeriod = false;
    return this;
  }

  getEffectiveDateConfig(doDisable?: boolean, forSH?: boolean, priorPlan?: PriorPlan) {
    this.disableDatePeriod = doDisable;
    const today = this.calendar.getToday();
    const currYear = new Date().getFullYear();
    let minDate = today;
    const maxYear = currYear + 1;

    if (today.day !== FIRST_DAY) {
      minDate = this.calendar.getNext(today, 'm', 1);
      minDate.day = FIRST_DAY;
    }

    const notEnoughLeadTime = ({ day, month, year }) => {
      const currDate = new Date(`${month}/${day}/${year}`);
      return moment().add(LEAD_DAYS_COUNT, 'days').isAfter(currDate);
    };

    const defaultConfig = {
      case: DATE_TYPES.DEFAULT,
      minDate: minDate,
      maxDate: { year: maxYear, month: 12, day: 31 }, 
      markDisabled: (setDate: NgbDateStruct) => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const notFirstOrFifteenth = setDate.day !== FIRST_DAY && setDate.day !== FIFTEENTH_DAY;
        const isDisabled = (setDate.year === currentYear) && (setDate.month === 10 && setDate.day >= 2 || setDate.month === 11 || setDate.month === 12);
        if (this.disableDatePeriod) {
          return notEnoughLeadTime(setDate) || notFirstOrFifteenth || isDisabled|| (setDate.year > maxYear);
        }
        else {
          return notEnoughLeadTime(setDate) || notFirstOrFifteenth || (setDate.year > maxYear);
        }
      },
    };

    if (!forSH) {
      return defaultConfig;
    }

    const config = {
      existing_sh: {
        ...defaultConfig,
        case: DATE_TYPES.EXISTING_SH,
      },
      sh_with_prior_plan: {
        case: DATE_TYPES.SH_WITH_PRIOR_PLAN,
        minDate: new NgbDate(currYear + 1, FIRST_MONTH, FIRST_DAY),
        markDisabled: (setDate: NgbDateStruct) => {
          const notNextYear = setDate.year <= currYear;
          return notEnoughLeadTime(setDate) || notNextYear;
        },
      },
      new_sh: {
        case: DATE_TYPES.NEW_SH,
        minDate: minDate,
        markDisabled: (setDate: NgbDateStruct) => {
          const notOctFirst = !(setDate.month === OCTOBER && setDate.day === FIRST_DAY);
          const notFirstOrFifteenthBeforeOct = !(
            setDate.month < OCTOBER &&
            (setDate.day === FIRST_DAY || setDate.day === FIFTEENTH_DAY)
          );
          return (
            notEnoughLeadTime(setDate) ||
            (notOctFirst && notFirstOrFifteenthBeforeOct)
          );
        },
      },
    };

    if (priorPlan) {
      const { isActiveSH } = priorPlan.questions;
      return isActiveSH ? config.existing_sh : config.sh_with_prior_plan;
    }

    return config.new_sh;
  }
}
