import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs';
import { Count } from 'src/app/shared/enums/common-form.enum';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  progress = new BehaviorSubject(
    parseInt(sessionStorage.getItem('progress')) || 0
  );
  onSave!: Function;
  hasChanges = false;
  onSaving$= new Subject<boolean>();
  changedToQuarterDate$= new BehaviorSubject<boolean>(false);
  companyTypeChanged = new BehaviorSubject<{status:boolean,isContinue:boolean,isNonProfit:boolean | undefined}>({status:false,isContinue:false,isNonProfit:false});
  empChanged = false;
  noChanges = new Subject<boolean>();
  savedCompanyChanged = new Subject<boolean>();
  changedCompanyType = false;
  continuePopUpShow = new BehaviorSubject(parseInt(sessionStorage.getItem('continuePopUpShow')) || 0);
  categoryStatusType = new BehaviorSubject(
    parseInt(sessionStorage.getItem('categoryStatusType')) || 0
  );

  constructor(private http: HttpClient) {}

  updateProgress(step: number) {
    if (step > this.progress.value) {
      this.setProgress(step);
    }
  }

  setProgress(progress: number) {
    if (progress > 1) this.http.put('/plan/status', { progress }).subscribe();
    if(this.categoryStatusType.getValue() === Count.FOUR){
      this.setCompanyTypeChanged(Count.ZERO.toString());
      this.setCompanyTypeStatus(Count.ZERO);
    }
    sessionStorage.setItem('progress', progress.toString());
    this.progress.next(progress);
  }

  regressToStep(step: number) {
    if (this.progress.value > step) this.setProgress(step);
  }

  setCompanyTypeStatus(categoryStatusType): void {
    this.http.put('/plan/type-status', { categoryStatusType }).subscribe();
  }

  setCompanyTypeChanged(type: string): void {
    sessionStorage.setItem('categoryStatusType', type);
    this.categoryStatusType.next(+type);
  }

  companyStatusCompleted(): boolean {
    // return (this.categoryStatusType.getValue() !== 1 && this.categoryStatusType.getValue() !== 2)
    return (this.categoryStatusType.getValue() !== Count.ZERO )
  }

  openCompanyChangePopUp(isNonProfit?:boolean): void {
     if ((this.categoryStatusType.getValue() === Count.TWO || this.categoryStatusType.getValue() === Count.FOUR) &&  this.continuePopUpShow.getValue() === Count.ZERO){
      this.companyTypeChanged.next({status:true,isContinue:false,isNonProfit});
    }
  }
}
