import { Component, OnInit, Input } from '@angular/core';
import { NON_PROFIT } from 'src/app/shared/constants/corptypes';

@Component({
  selector: 'ps-review-employees',
  templateUrl: './review-employees.component.html',
  styleUrls: ['./review-employees.component.scss']
})
export class ReviewEmployeesComponent implements OnInit {
  owners = [];
  ownerRels = [];
  officers = [];
  eeOver120k = [];
  @Input() canEdit!: boolean;
  @Input() employeeData;
  @Input() companyType!:string;
  nonProfit:string = NON_PROFIT;
  constructor() { 
    //
  }

  ngOnInit() {
    this.getEmployeesData();
  }

  getEmployeesData(): void {
    this.owners = this.employeeData.owners.concat(this.employeeData.ownerEntity);
    this.officers = this.employeeData.officers;
    this.eeOver120k = this.employeeData.eeOver120k;
    this.ownerRels = this.employeeData.ownerRels;
  }
}
