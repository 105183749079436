
<ng-container *ngIf="checkingIsNonProfit;else load">
<p class="main-instruction">
  Please complete the authorized signer and admin information below.
</p>
<div class="block authorization">
  <h6 class="section-title">Authorization</h6>
  <div class="item-box">
    <legend>Authorized Signer</legend>
    <p *ngIf="isNonProfit">
      Assign <strong>two</strong> authorized signers that are either Officers or Board Members. 
      These documents will be sent to their email addresses.
    </p>
    <p *ngIf="!isNonProfit">
      Assign <strong>only one</strong> authorized signer. The authorized signer
      for setting up your new 401(k) plan <strong>must </strong>be an owner or
      officer. These documents will be sent to their email address.
    </p>
  </div>
  <form [formGroup] = "authorizationForm"  *ngIf="!isNonProfit">
    <div class="row">
      <div class="col-md-12 col-sm-12 mb-16">
        <app-custom-dropdown (onUpdate) = "patchFormGroup($event)" [data] = "signerOptions" [placeholder] = "'Select from Owners / Officers'" [control] = "authorizationForm.get('fullName')" [key] = "'fullName'"></app-custom-dropdown>
      </div>
      <ng-container *ngIf="authorizationForm.get('firstName').value">
        <div class="col-md-12 mb-16">
          <label>
            <span>Email</span>
            <i
              class="form-error"
              *ngIf="authorizationForm.get('email').touched"
            >
            <ps-error-span [control]="authorizationForm.get('email')"></ps-error-span>
            </i>
          </label>
          <input
            type="text"
            class="form-control"
            formControlName="email"
            name="email"
            aria-label="email-field"
            aria-required="true"
            aria-describedby="email-error"
            placeholder="Required"
          />
        </div>
      </ng-container>
    </div>
  </form>
  <div class="card-outer" *ngIf="isNonProfit" >
    <ng-container  *ngFor="let signer of nonProfitSignerData; let i = index">
        <div class="col-md-6 col-sm-12 mr-b-16">
          <div class="edit-remove-card">
            <div class="top-title">
              <h5 [class.is-place-holder]="signer?.isPlaceHolder">{{ signer.firstName }} {{ signer.lastName }}</h5>           
               <span>
                <button class="edit" aria-label="Edit" (click)="openForm(true,i,(signer?.isPlaceHolder?null:signer))">Edit</button>
              </span>
            </div>
            <ul class="admin-card">
              <li>
                <img src="assets/img/sitemap.svg" alt="sitemap" [class.is-place-holder-svg]="signer?.isPlaceHolder" />
                <p  [class.is-place-holder]="signer?.isPlaceHolder">{{signer.title}}</p>
              </li>
              <li>
                <img src="assets/img/email.svg" alt="percentage" [class.is-place-holder-svg]="signer?.isPlaceHolder" />
                <p  [class.is-place-holder]="signer?.isPlaceHolder">{{signer.email}}</p>
              </li>
            </ul>
          </div>
        </div>
    </ng-container>
  </div>
  <div class="item-box">
    <legend>Plan Administrators</legend>
    <p>
      Assign <strong>at least one </strong>plan administrator. Your authorized
      plan administrator will have access to sensitive plan and employee
      information on the sponsor web portal. Additionally, they will be
      subscribed to our monthly Plan Adopter Insight email.
    </p>
  </div>

  <div class="card-outer"  *ngIf="authPlanAdmin.length>0; else noAuthPlanAdmin">
    <ng-container *ngFor="let admin of authPlanAdmin; let i = index">
      <div class="col-md-6 col-sm-12 mr-b-16">
        <div class="edit-remove-card">
          <div class="top-title">
            <h5>{{ admin.firstName }} {{ admin.lastName }}</h5>
            <span>
              <button class="remove" (click)="openDeletePopUp(i)" aria-label="Remove {{ admin.firstName }} {{ admin.lastName }}">
                Remove
              </button>
              <button class="edit" (click)="openForm(false,i,admin)" aria-label="Edit {{ admin.firstName }} {{ admin.lastName }}">Edit</button>
            </span>
          </div>
          <ul class="admin-card">
            <li>
              <img src="assets/img/sitemap.svg" alt="sitemap" />
              <p>{{ admin.title }}</p>
            </li>
            <li>
              <img src="assets/img/email.svg" alt="percentage" />
              <p>{{ admin.email }}</p>
            </li>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>
  
  <button type="button" class="add-btn" (click)="openForm(false)"  *ngIf="authPlanAdmin.length>0">
    + Add Admin
  </button>
  
  <div class="text-right pt-5">
    <span
      class="actions__button-overlay"
      [ngbTooltip]="continueErrorForTooltip"
      [disableTooltip]="continueEnabled"
      placement="top"
    >
      <cta-button
        [isLoading]="isLoading"
        (ctaClick)="onSubmit()"
        [disabled]="!continueEnabled"
      >
        Save & Next
      </cta-button>
    </span>
  </div>
</div>
</ng-container>
<ps-modal
    class="custom-form-popup"
    #commonForm
    [disableClickOff]="true"
    [closeButton]="false"
  >
    <span class="close-btn"
      ><img src="assets/img/close.svg" alt="close" (click)="closeFormModal()"
    /></span>
    <app-common-form
      [formType]="formTypes.AUTH_PLAN_ADMIN"
      (formValueSave)="commonFormSave($event)"
      [isLoading]="isLoading"
    ></app-common-form>
  </ps-modal>
  <ps-modal class="warning-popup" #removePopup>
    <span class="top-section">
      <img src="assets/img/remove-icon.svg" alt="remove icon" />
      <h2>{{ removeModalHeader }}</h2>
    </span>
    <p>{{ removeModalDesc }}</p>
    <div class="button-outer">
      <button class="btn-cancel" (click)="closeRemovePopup()">Cancel</button>
      <cta-button
        class="btn-remove"
        (click)="deleteData()"
        [isLoading]="isDeleting"
        >Remove</cta-button
      >
    </div>
  </ps-modal>
  <ng-template #noAuthPlanAdmin>
    <div class="card-outer">
    <div class="col-md-6 col-sm-12 mr-b-16">
      <div class="edit-remove-card">
        <div class="top-title">
          <h5 class="is-place-holder">At least one plan administrator</h5>
          <span>
            <button class="edit" (click)="openForm(false)">Edit</button>
          </span>
        </div>
        <ul class="admin-card">
          <li>
            <img src="assets/img/sitemap.svg" alt="sitemap" class="is-place-holder-svg"/>
            <p class="is-place-holder">Title</p>
          </li>
          <li>
            <img src="assets/img/email.svg" alt="percentage" class="is-place-holder-svg"/>
            <p class="is-place-holder">Email</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
  </ng-template>

<ng-template #load>
  <ps-loading-dots></ps-loading-dots>
</ng-template>