<ng-container *ngIf="checkingIsNonProfit; else load">
<p class="main-instruction"> Please identify employees meeting the following criteria.</p>
<ps-accordion class="accordion-main" [accordionData]="accordionData[0]" (backClicked)="goBack($event)"
  (nextClicked)="goNext($event)" [disableNextButton]="ownerInformationPercentage" *ngIf="!isNonProfit">
  <div class="para-sec">
    <p>Owners who own more than 1% of the company.
      <span class="term" ngbTooltip="Total ownership percentage needs to equal 100%.">
        <img src="assets/img/info.svg" alt="info icon" />
      </span>
    </p>
    <div class="info-sec"><b>.</b><span [class.completedColor]="totalPercentage==100">{{totalPercentage}}% </span> <span
        *ngIf="totalPercentage!=100">(Should add up to 100%)</span></div>
  </div>
  <div class="row card-outer">
    <ng-container *ngIf="ownerInformationDetails.length>0; else placeHolder">
      <ng-container *ngFor="let information of ownerInformationDetails;let i=index">
        <div class="col-md-6 mr-b-24">
          <div class="edit-remove-card">
            <div class="top-title">
              <h5> {{information.type == status.INDIVIDUAL? information.firstName + ' ' +
                information.lastName:information.name}}
              </h5>
              <span>
                <button class="remove" (click)="openDeletePopUp(formTypes.OWNER_INFORMATION_FORM,i)">Remove</button>
                <button class="edit" (click)="openForm(formTypes.OWNER_INFORMATION_FORM,i,information)">Edit</button>
              </span>
            </div>
            <ng-container *ngIf="information.relativeContactId > 0">
              <h5 class="title-label">
                Authorized signer
              </h5>
            </ng-container>
            <ul>
              <li *ngIf="information.type === status.INDIVIDUAL">
                <img src="assets/img/sitemap.svg" alt="sitemap" />
                <p>{{information.title}}</p>
              </li>
              <li>
                <img src="assets/img/percentage.svg" alt="percentage" />
                <p>{{information.percentage}} %</p>
              </li>
              <li *ngIf="information.type === status.INDIVIDUAL">
                <img src="assets/img/money.svg" alt="money" />
                <p>{{information.onPayroll?'On payroll':'Not on payroll'}}</p>
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <button type="button" class="add-btn" (click)="openForm(formTypes.OWNER_INFORMATION_FORM,null)"
    *ngIf="ownerInformationDetails.length>0">+ Add
    more</button>
</ps-accordion>

<ps-accordion [accordionData]="accordionData[1]" (backClicked)="goBack($event)" (nextClicked)="goNext($event)"
  [disableNextButton]="checkisLinealRelativeEmployees" *ngIf="!isNonProfit">
  <div class="para-sec">
    <p class="mb-16">Lineal relatives for 401(k) purposes include spouses and children.</p>
  </div>
  <div class="radio-btn">
    <label>
      <input type="radio" class="input-radio" checked name="name" value="no" [formControl]="isLinealRelativeEmployees"
        (click)="linealRelative.length>0?changeStatus($event,formTypes.LINEAL_RELATIVE_FORM):''">
      I do not have any lineal relative employees
    </label>
    <label>
      <input type="radio" class="input-radio" name="name" value="yes" [formControl]="isLinealRelativeEmployees"
        (click)="linealRelative.length>0?'':openForm(formTypes.LINEAL_RELATIVE_FORM,null)">
      Add lineal relative employee
    </label>
  </div>

  <div class="row card-outer">
    <ng-container *ngFor="let lineal of linealRelative;let i=index">
      <div class="col-md-6 mr-b-16">
        <div class="edit-remove-card">
          <div class="top-title">
            <h5>{{lineal.firstName}} {{lineal.lastName}}</h5>
            <span>
              <button class="remove" (click)="openDeletePopUp(formTypes.LINEAL_RELATIVE_FORM,i)">Remove</button>
              <button class="edit" (click)="openForm(formTypes.LINEAL_RELATIVE_FORM,i,lineal)">Edit</button>
            </span>
          </div>
          <ul>
            <li>
              <img src="assets/img/sitemap.svg" alt="sitemap" />
              <p>{{lineal.relationship}}</p>
            </li>
            <li>
              <img src="assets/img/user-icon.svg" alt="percentage" />
              <p>{{lineal.relativeOf}}</p>
            </li>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>
  <button type="button" class="add-btn" (click)="openForm(formTypes.LINEAL_RELATIVE_FORM,null)"
    *ngIf="linealRelative.length>0">+ Add more</button>
</ps-accordion>

<ps-accordion [accordionData]="accordionData[2]" (backClicked)="goBack($event)" (nextClicked)="goNext($event)"
  [disableNextButton]="checkHcesValid">
  <div class="para-sec">
    <p class="mb-16"> Employees with gross compensation of more than {{irsLimits?.highCompLimit}} in
      {{(irsLimits?.year)-1}}. Please note that this compensation limit changes as frequently as every year. If you
      select a plan effective date that is not in the current year, the HCE threshold may be different than the value
      displayed here.</p>
  </div>
  <div class="radio-btn">
    <label>
      <input type="radio" class="input-radio" checked name="name1" value="no" [formControl]="isHCEs"
        (click)="hcesData.length>0?changeStatus($event,formTypes.HCE_FORM):''">
      I do not have any HCEs
    </label>
    <label>
      <input type="radio" class="input-radio" name="name1" value="yes" [formControl]="isHCEs"
        (click)="hcesData.length > 0?'':openForm(formTypes.HCE_FORM, null)">
      Add HCE
    </label>
  </div>
  <div class="row card-outer">
    <ng-container *ngFor="let hce of hcesData;let i=index">
      <div class="col-md-6 mr-b-16">
        <div class="edit-remove-card">
          <div class="top-title">
            <h5>{{hce.firstName}} {{hce.lastName}}</h5>
            <span>
              <button class="remove" (click)="openDeletePopUp(formTypes.HCE_FORM, i)">Remove</button>
              <button class="edit" (click)="openForm(formTypes.HCE_FORM, i, hce)">Edit</button>
            </span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <button type="button" class="add-btn" (click)="openForm(formTypes.HCE_FORM, null)" *ngIf="hcesData.length > 0">+ Add
    more</button>
</ps-accordion>

<ps-accordion [accordionData]="accordionData[3]" (backClicked)="goBack($event)" (nextClicked)="goNext($event)"
  [disableNextButton]="officerValid">
  <div class="para-sec">
    <p class="mb-16">An officer is someone who holds an executive position in active service with the employer, i.e.
      CEO, CFO, President, etc. In addition to individual owners, officers can serve as authorized signers for the plan.
    </p>
  </div>
  <div class="radio-btn">
    <label>
      <input type="radio" class="input-radio" checked name="name2" value="no" [formControl]="isOfficers"
        (click)="officersData.length>0?changeStatus($event,formTypes.OFFICERS_FORM):''">
      I do not have any officers
    </label>
    <label>
      <input type="radio" class="input-radio" name="name2" value="yes" [formControl]="isOfficers"
        (click)="officersData.length>0?'':openForm(formTypes.OFFICERS_FORM, null)">
      Add officer
    </label>
  </div>
  <div class="row card-outer">
    <ng-container *ngFor="let officer of officersData;let i=index">
      <div class="col-md-6 mr-b-16">
        <div class="edit-remove-card">
          <div class="top-title">
            <h5>{{officer.firstName}} {{officer.lastName}}</h5>
            <span>
              <button class="remove" (click)="openDeletePopUp(formTypes.OFFICERS_FORM, i)">Remove</button>
              <button class="edit" (click)="openForm(formTypes.OFFICERS_FORM, i, officer)">Edit</button>
            </span>
          </div>
          <ng-container *ngIf="officer.relativeContactId > 0">
            <h5 class="title-label">
              Authorized signer
            </h5>
          </ng-container>
          <ul>
            <li>
              <img src="assets/img/sitemap.svg" alt="sitemap" />
              <p>{{officer.title}}</p>
            </li>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>
  <button type="button" class="add-btn" (click)="openForm(formTypes.OFFICERS_FORM, null)"
    *ngIf="officersData.length>0">+ Add more</button>
</ps-accordion>
</ng-container>
<ps-modal class="custom-form-popup" #commonForm [disableClickOff]="true" [closeButton]="false">
  <span class="close-btn"><img src="assets/img/close.svg" alt="close" (click)="closeFormModal()"></span>
  <app-common-form [formType]="formType" (formValueSave)="commonFormSave($event)" [relativeOf]="relativeOf"
    [isLoading]="isLoading"></app-common-form>
</ps-modal>

<ps-modal class="warning-popup" #removePopup>
  <span class="top-section">
    <img src="assets/img/remove-icon.svg" alt="remove icon">
    <h2>{{removeModalHeader}}</h2>
  </span>
  <p>{{removeModalDesc}}</p>
  <div class="button-outer">
    <button class="btn-cancel" (click)="closeRemovePopup()">Cancel</button>
    <cta-button class="btn-remove" (click)="deleteData()" [isLoading]="isDeleting">Remove</cta-button>
  </div>
</ps-modal>

<ng-template #placeHolder>
  <div class="col-md-6 mr-b-16">
    <div class="edit-remove-card default-card">
      <div class="top-title">
        <h5>Name</h5>
        <span>
          <button class="edit" (click)="openForm(1,null)">Edit</button>
        </span>
      </div>
      <ul>
        <li>
          <img src="assets/img/sitemap.svg" alt="sitemap" />
          <p>Title</p>
        </li>
        <li>
          <img src="assets/img/percentage.svg" alt="percentage" />
          <p>Percentage</p>
        </li>
        <li>
          <img src="assets/img/money.svg" alt="money" />
          <p>Payroll</p>
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<ps-modal class="warning-popup" [disableClickOff]="true" #officerModal>
  <span class="top-section">
    <img src="assets/img/at-least.svg" alt="at-least">
    <h2>At least one officer is required.</h2>
  </span>
  <p>As your company is owned by an entity rather than an individual (see "Owner Information" section above), you must
    designate at least one individual who is a company officer to serve as the authorized signer for the plan.</p>
  <div class="button-outer">
    <cta-button (ctaClick)="assignOfficer()" class="full-width-btn">Assign an officer</cta-button>
  </div>
</ps-modal>
<ng-template #load>
  <ps-loading-dots></ps-loading-dots>
</ng-template>