<div>
  <p class="calc-title">Please input your employee salaries and adjust the estimated plan participation rate (if
    desired) in the
    section below in order to calculate your estimated tax credits and match cost. This step is optional. If you are
    already clear about your plan's costs and credits, feel free to skip to the next step.</p>
  <h6 class="sub-title"><img src="assets/img/formula.svg" alt="Formula" />selected Match Formula</h6>
  <ng-container *ngIf="planType.value === planTypeValue.SF_QACA">
    <div class="deferral" *ngFor="let messageItem of planType.calcFormula">
      <p>
        <span class="def">{{ messageItem.matchMessage }}</span>
        <span class="comp">{{ messageItem.deferralMessage }}</span>
      </p>
    </div>
    <p class="exp-cls">Example: The company will contribute a maximum of <strong>{{planType.percent}}%</strong> of a
      participant’s
      compensation when they defer <strong>{{planType.deferVal}}%</strong>.</p>
  </ng-container>
  <ng-container *ngIf="planType.value === planTypeValue.SF_MATCH">
    <div class="deferral" *ngFor="let messageItem of planType.calcFormula">
      <p>
        <span class="def">{{ messageItem.matchMessage }}</span>
        <span class="comp">{{ messageItem.deferralMessage }}</span>
      </p>
    </div>
    <p class="exp-cls">Example: The company will contribute a maximum of <strong>{{planType.percent}}%</strong> of a
      participant’s
      compensation when they defer <strong>{{planType.deferVal}}%</strong>.</p>
  </ng-container>
  <ng-container *ngIf="planType.value === planTypeValue.SF_NONELECTIVE">
    <div class="deferral" *ngFor="let messageItem of planType.calcFormula">
      <p>
        <span class="def">{{ messageItem.matchMessage }}</span>
        <span class="comp">{{ messageItem.deferralMessage }}</span>
      </p>
    </div>
    <p class="exp-cls">Example: The company will contribute <strong>{{planType.percent}}%</strong> of all employees'
      salary, regardless of whether an employee elects to participate in the plan.</p>
  </ng-container>
  <ng-container *ngIf="planType.value === planSelectionType.MATCH">
    <div class="deferral">
      <p>
        <span class="def">Match {{ matchPercentage }}% of deferral</span>
        <span class="comp">up to {{ matchDeferral }}% of compensation</span>
      </p>
    </div>
    <p class="exp-cls">Example: The company will contribute a maximum of <strong>{{maxContribution |
        number:'1.0-2'}}%</strong> of a participant’s
      compensation when they defer <strong>{{matchDeferral | number:'1.0-2'}}%</strong>.</p>
  </ng-container>
  <div class="rm-cards" *ngIf="!isCalculated; else calculated">
    <div class="rate-card bb-1"
      *ngIf="planType.value === planTypeValue.SF_QACA || planType.value === planTypeValue.SF_MATCH || planType.value === planSelectionType.MATCH">
      <div class="rate-each pd-btm">
        <div class="rate-left">
          <h5 class="plan-cost-name">Estimated Participation Rate</h5>
          <p class="plan-desc">As all new 401(k) plans are now required to have an automatic enrollment feature, the
            participation rate for your plan is estimated to be 90%. This rate, as well as the actual match cost, will
            vary depending upon participation and deferral rates.</p>
          <div class="slider">
            <ps-asset-slider [max]="100" [isAmount]="false" [value]="sliderCount" [step]="10"
              (update)="onUpdateSlider($event)"></ps-asset-slider>
          </div>
          <div class="slider-label">
            <p>0%</p>
            <p>100%</p>
          </div>
        </div>
      </div>
    </div>
    <div class="rate-card">
      <div class="rate-each eql-pad">
        <div class="rate-left">
          <h5 class="plan-cost-name">Employee Salaries</h5>
          <p class="plan-desc">Please add your employee salaries below to calculate your estimated tax credits and match
            cost. Please note that this tool uses the $22,500 elective deferral limit and $330,000 annual compensation
            limit for calculating the estimated costs and credits.</p>
        </div>
      </div>
      <div class="calculating-from-wrap">
        <div class="calculating-from" *ngFor="let item of employees.controls; index as i" [formGroup]="item">
          <div class="delete-icon">
            <a class="text-danger" (click)="delete(i)">
              <img src="assets/img/remove.svg" alt="">
            </a>
          </div>
          <div class="child-form">
            <div class="count-wrap">
              <div class="count-input">
                <input type="text" [min]="0" class="input" formControlName="empCount" appMaxValue [maxValue]="1000"
                  [textMask]="maskConfigs.number" #count>
              </div>
              <span class="text-box"> employees with salary of </span>
            </div>
            <div class="amount-input">
              <img src="assets/img/$.svg" alt="amt-icon">
              <input type="text" [min]="0" class="input"  appMaxValue [maxValue]="5000000" formControlName="amount" #salary 
                [textMask]="maskConfigs.number">
            </div>
          </div>
        </div>
      </div>
      <button class="add btn btn-text-only" (click)="addSalary()">+ Add Employees</button>
    </div>
    <div class="cal-btn">
      <button class="btn calculate-btn" [class.calculate-btn-valid]="employees.length>0 && employees.valid"
        (click)="getCalculation()"><span *ngIf="!isCalculatedDataLoading">
          Calculate tax credit and match
          <img
            [src]="employees.length>0 && employees.valid?'assets/img/arrow-right-round-active.svg':'assets/img/arrow-right-round.svg'"
            alt="arrow">
        </span>
        <ng-container *ngIf="isCalculatedDataLoading"> 
          <span class="loading-dot-1 dot"></span>
          <span class="loading-dot-2 dot"></span>
          <span class="loading-dot-3 dot"></span>
        </ng-container>
      </button>
    </div>
  </div>

  <ng-template #calculated>
    <div class="rm-cards">
      <div class="rate-each mb-4">
        <div class="rate-left">
          <h5 class="plan-cost-name">Tax Credit</h5>
          <p class="plan-desc">The SECURE 2.0 legislation has created several new tax credits for companies to establish
            a brand new 401(k) plan. In many cases, these credits can make the cost of the plan, including the funding
            of the employer matching contribution, completely free for the first three years. Please see your estimated
            SECURE 2.0 tax credits below based upon your employee demographics and selected plan design.</p>
        </div>
      </div>
      <div class="calculated-data-wrap"  [class.border-none]="isRightBorderNone">
        <div class="calculated-data" (scroll)="onScroll($event)" #scrollDiv  id="div-element" (window:resize)="onResize()" >
          <table id="table-element">
            <ng-container>
              <thead>
                <tr>
                  <th></th>
                  <th *ngFor="let item of tableHeader; let i=index">Year {{item}}</th>
                </tr>
              </thead>
            </ng-container>
            <tbody>
              <tr class="bb-1-gray">
                <td>Plan establishment credit</td>
                <td *ngFor="let autoCredit of calculatedData.years">
                  {{ autoCredit.planEstCredit>0? (autoCredit.planEstCredit| currency:'USD':'symbol':'1.0-0') : 'N/A'}}
                </td>
              </tr>
              <tr class="bb-1-gray">
                <td>Auto enroll credit</td>
                <td *ngFor="let autoCredit of calculatedData.years">
                  {{ autoCredit.autoEnrollCredit>0? (autoCredit.autoEnrollCredit| currency:'USD':'symbol':'1.0-0') :
                  'N/A'}}
                </td>
              </tr>
              <tr class="bb-1-gray">
                <td>Match credit</td>
                <td *ngFor="let autoCredit of calculatedData.years">
                  {{ autoCredit.matchCredit>0? (autoCredit.matchCredit| currency:'USD':'symbol':'1.0-0') : 'N/A'}}
                </td>
              </tr>
              <tr class="bb-1-gray">
                <td>Estimated annual tax credit</td>
                <td *ngFor="let autoCredit of calculatedData.years" class="sub-total">
                  {{ autoCredit.estAnnualCredit>0? (autoCredit.estAnnualCredit| currency:'USD':'symbol':'1.0-0') :
                  'N/A'}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="est-total">
        <div class="estimated-total">
          <div class="estimated-data">
            <p class="estimated-sub-title">Total Estimated 5 Year Tax Credit</p>
            <h4 class="estimated-total-value">{{calculatedData.totalEstCredit | currency:'USD':'symbol':'1.0-0'}}</h4>
          </div>
        </div>
      </div>
      <div class="rate-each m-16">
        <div class="rate-left">
          <h5 class="plan-cost-name">Estimated Total Employer Match</h5>
          <p class="plan-desc">See below for the estimated annual employer matching contribution cost based upon 
            your selected plan design and the estimated participation rate on the previous step.
          </p>
        </div>
      </div>
      <div class="calculated-footer">
        <div class="calculated-item-1">
          <p class="footer-label">ER Match <span class="show-percent">at 100%</span></p>
          <p class="calculation-value">{{ calculatedData.matchInfo.totalEmployerMatch |
            currency:'USD':'symbol':'1.0-0'}}</p>
        </div>
        <div class="calculated-item-2">
          <p  class="footer-label">Est Ptp Rate</p>
          <p class="calculation-value">{{ calculatedData.matchInfo.participationRate }}%</p>
        </div>
        <div>
          <p  class="footer-label">Est Total ER Match</p>
          <p class="calculation-value">{{ calculatedData.matchInfo.employerMatchWithParticipationRate |
            currency:'USD':'symbol':'1.0-0' }}</p>
        </div>
      </div>
      <div class="match-container">
        <p class="match-heading">Saving with Match Credit</p>
        <p class="match-description" *ngIf="(!lesserAmount && !greaterAmount) || (lesserAmount && calculatedData.actualMatchCostDetails.negative)">With the match credit applied, your actual match cost is considerably less than the original full match cost, 
          resulting in substantial savings. Remember, the SECURE 2.0 employer contribution credit only applies to employees 
          making under 100k per year.
        </p>
        <p class="match-description" *ngIf="lesserAmount && !calculatedData.actualMatchCostDetails.negative">With the match credit applied, your actual match cost is considerably less than the original full match cost, resulting in substantial savings. 
          Remember, the SECURE 2.0 employer contribution credit only applies to employees making under 100k per year and the calculation below applies only 
          to the first two years with full match credit.
        </p>
        <p class="match-description" *ngIf="greaterAmount">The Secure 2.0 employer contribution credit 
          only applies to employees making less than $100k per year. Because you have no employees making under $100k, the Secure 2.0 employer contribution credit does not apply.
        </p>
        <div class="calculated-footer">
          <ng-container *ngIf="!calculatedData.actualMatchCostDetails.negative">
            <div class="calculated-item-1">
              <p class="footer-label">Est Total ER Match</p>
              <p class="calculation-value">{{ calculatedData.actualMatchCostDetails.estTotalMatchCost |
                currency:'USD':'symbol':'1.0-0'}}</p>
            </div>
            <div class="calculated-item-2">
              <p  class="footer-label">Match Credit</p>
              <p class="calculation-value">{{ calculatedData.actualMatchCostDetails.matchCredit |
                currency:'USD':'symbol':'1.0-0'}}</p>
            </div>
          </ng-container>
          <div class="calculated-item-3">
            <p  class="footer-label">Actual Match Cost</p>
            <p class="calculation-value" [class.negative-value]="calculatedData.actualMatchCostDetails.negative">{{ (calculatedData.actualMatchCostDetails.negative ? calculatedData.actualMatchCostDetails.ifNegativeDisplayValue  : calculatedData.actualMatchCostDetails.actualMatchCost) |
              currency:'USD':'symbol':'1.0-0' }}*</p>
          </div>
        </div>
        <p class="secure-message">* The projected annual match cost for the first two years of the plan as a result of the secure 2.0 tax credit.</p>
        <div class="match-credit-message" *ngIf="calculatedData.actualMatchCostDetails.matchCredit === commonReusableNumber.ZERO">
          <p>*</p>
          <p>Would you like to leverage this plan design but exclude highly compensated employees 
            from receiving matching contributions? Please click the Get Help button at the bottom right, and someone will contact you for further assistance.
          </p>
        </div>
        <div class="actual-match-container" *ngIf="!lesserAmount && !greaterAmount">
            <div class="actual-match-salary">
              <p class="actual-match-cost">Your actual match cost of {{ calculatedData.actualMatchCostIncludeTwo.actualMatchCost |
                currency:'USD':'symbol':'1.0-0' }} includes two parts.</p>
              <div class="match-salary">
                <div class="employee-salary"><img class="grey-tick-icon" src="assets/img/grey-tick.svg" alt=""><span class="salary-amount">{{calculatedData.actualMatchCostIncludeTwo.lessMatchCost |
                  currency:'USD':'symbol':'1.0-0'}}</span> : match from Employees with salary ≤ 100k
                   </div>
                   <div class="employee-salary"><img class="grey-tick-icon" src="assets/img/grey-tick.svg" alt=""><span class="salary-amount">{{calculatedData.actualMatchCostIncludeTwo.greaterMatchCost |
                     currency:'USD':'symbol':'1.0-0'}}</span> : match from Employees with salary > 100k**
                   </div>
                 </div>
               </div>
               <div class="match-message">
                <p>**</p>
                <p>Would you like to leverage this plan design but exclude highly compensated employees from receiving matching 
                  contributions? Please click the Get Help button at the bottom right, and someone will contact you for further 
                  assistance.
                </p>
              </div>
            </div>
          </div>
      <div class="cal-btn">
        <button class="btn calculate-btn calculate-btn-valid adjust-sal" (click)="isCalculated = false"><img
            src="assets/img/left-round-arrow.svg" alt="arrow"> <span class="clac-bk-btn normal-btn">Adjust employees’
            salary and
            participation rate</span>
          <span class="clac-bk-btn mob-button">Adjust salary &
            participation rate</span>
        </button>
      </div>
    </div>
  </ng-template>