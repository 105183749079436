import { Component } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({ height: 0, paddingTop: 0, paddingBottom: 0, marginBottom: 0 }),
        animate('200ms ease-in-out', style({ height: '*', paddingTop: '*', paddingBottom: '*', marginBottom: '*' }))
      ])
    ])
  ]
})
export class ErrorMessageComponent { }
