import { Match, NonElective } from "../models/contribution";

export function isMatch(type: string) {
  return type ? type.toLowerCase().indexOf('match') > -1 : false;
}

export function condenseMatch(match: Match): Match {
  const matchLimit = match.matchLimit.replace(/\D/g, '') || '0';
  const newMatch: Match = {
    type: match.type,
    vestingId: match.vesting.id,
    matchLimit: match.type === 'match' ? matchLimit : '0',
  };
  if (match.formula.id) {
    newMatch.formulaId = match.formula.id;
  } else {
    newMatch.formula = match.formula;
  }
  return newMatch;
}

export function condenseNonElective({
  type,
  percent,
  vesting,
}: NonElective): NonElective {
  return {
    type,
    percent,
    vestingId: vesting.id,
  };
}
