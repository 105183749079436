import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IdentifyComponent } from 'src/app/pages/identify/identify.component';
import { CompanyInfoComponent } from 'src/app/pages/company-info/company-info.component';
import { EmployeesComponent } from 'src/app/pages/employees/employees.component';
import { PlanBasicsComponent } from 'src/app/pages/plan-basics/plan-basics.component';
import { AuthorizationComponent } from 'src/app/pages/authorization/authorization.component';
import { ReviewComponent } from 'src/app/pages/review/review.component';
import { ConfirmationComponent } from 'src/app/pages/confirmation/confirmation.component';
import { QuestionsSummaryComponent } from 'src/app/pages/questions/questions-summary/questions-summary.component';
import { RetrieveComponent } from 'src/app/pages/retrieve/retrieve.component';
import { ProgressGuard } from 'src/app/shared/guards/progress-guard';
import { EditingGuard } from 'src/app/shared/guards/editing-guard';
import { FeaturesComponent } from 'src/app/pages/features/features.component';
import { FeaturesResolver } from 'src/app/pages/features/features-resolver.service';
import {
  COMPANY_INFO,
  QUALIFICATION,
  EMPLOYEES,
  PLAN_BASICS,
  AUTH,
  REVIEW,
  CONFIRM,
} from 'src/app/shared/constants/steps.data';
import { EmployeeCheckingGuard } from 'src/app/shared/guards/employee-checking.guard'
import { AdminCheckingGuard } from 'src/app/shared/guards/admin-checking.guard';

const routes: Routes = [
  { path: '', component: IdentifyComponent },
  { path: 'retrieve', component: RetrieveComponent },
  { path: 'review-details', component: ReviewComponent},
  {
    path: 'features',
    component: FeaturesComponent,
    resolve: {
      features: FeaturesResolver
    },
  },
  {
    path: COMPANY_INFO.path,
    component: CompanyInfoComponent,
    data: {
      step: COMPANY_INFO.step,
      navName: COMPANY_INFO.title,
      navUrl: COMPANY_INFO.url
    },
    canActivate: [EditingGuard]
  },
  {
    path: EMPLOYEES.path,
    component: EmployeesComponent,
    data: {
      step: EMPLOYEES.step,
      navName: EMPLOYEES.title,
      navUrl: EMPLOYEES.url
    },
    canActivate: [ProgressGuard, EditingGuard]
  },
  {
    path: QUALIFICATION.path,
    component: QuestionsSummaryComponent,
    canActivate: [ProgressGuard, EditingGuard, EmployeeCheckingGuard],
    data: {
      step: QUALIFICATION.step,
      navName: QUALIFICATION.title,
      navUrl: QUALIFICATION.url
    }
  },
  {
    path: PLAN_BASICS.path,
    component: PlanBasicsComponent,
    data: {
      step: PLAN_BASICS.step,
      navName: PLAN_BASICS.title,
      navUrl: PLAN_BASICS.url
    },
    canActivate: [ProgressGuard, EditingGuard, EmployeeCheckingGuard]
  },
  {
    path: AUTH.path,
    component: AuthorizationComponent,
    data: {
      step: AUTH.step,
      navName: AUTH.title,
      navUrl: AUTH.url
    },
    canActivate: [ProgressGuard, EditingGuard, EmployeeCheckingGuard]
  },
  {
    path: REVIEW.path,
    component: ReviewComponent,
    data: {
      step: REVIEW.step,
      navName: REVIEW.title,
      navUrl: REVIEW.url
    },
    canActivate: [ProgressGuard, EmployeeCheckingGuard, AdminCheckingGuard]
  },
  {
    path: CONFIRM.path,
    component: ConfirmationComponent,
    data: {
      step: CONFIRM.step,
      navName: CONFIRM.title,
      navUrl: CONFIRM.url
    },
    canActivate: [ProgressGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
