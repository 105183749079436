import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ps-review-plan',
  templateUrl: './review-plan.component.html',
  styleUrls: ['./review-plan.component.scss']
})
export class ReviewPlanComponent implements OnInit {
  @Input() basics;
  @Input() canEdit: boolean;
  @Input() planDetails;
  planDesignLabel: string;
  vestingSchedule: string;
  contributionRate: string;

  ngOnInit() {
    this.checkValidArray();
  }

  checkValidArray(): void {
    this.planDesignLabel = this.planDetails.matchList?.length > 0 ? this.planDetails.matchList[0]?.description : this.planDetails.nonElectiveList[0]?.description
    this.vestingSchedule = this.planDetails.matchList?.length > 0 ? this.planDetails.matchList[0]?.vesting?.label : this.planDetails.nonElectiveList[0]?.vesting?.label;
    this.contributionRate = this.planDetails.matchList?.length > 0 ? this.planDetails.matchList[0]?.formula?.maxEmployerContribRate : this.planDetails.nonElectiveList[0]?.percent;
  }
}
