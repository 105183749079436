import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs/internal/Subject';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { concatMap } from 'rxjs/internal/operators/concatMap';
import { Observable } from 'rxjs/internal/Observable';
import { zip } from 'rxjs/internal/observable/zip';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/internal/operators/map';
import { IEmployees } from 'src/app/pages/employees/models/get-employee.model';
import { DeleteInformation } from 'src/app/pages/employees/models/delete.information.model';
import { AUTH, REVIEW } from 'src/app/shared/constants/steps.data';
import { ProgressService } from 'src/app/shared/services/progress.service';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  constructor(private http: HttpClient, private progressService: ProgressService) {}
  formModalClosed: Subject<boolean> = new Subject();
  editFormData: Subject<{}> = new Subject();
  editDataIndex = null;
  isSignerForm = false;
  deletedIds = <any>[];

  getHCEs() {
    return this.http.get<any>('/plan/hce');
  }

  updateHCE(owners, ownerEntity, ownerRels, eeOver120k, officers, notes) {
    return this.http.put('/plan/hce', {
      owners,
      ownerEntity,
      ownerRels,
      eeOver120k,
      officers,
      notes,
    });
  }

  deleteContact(id) {
    return this.http.delete<any>(`/plan/contact/${id}`);
  }

  deleteAllOwnersAndOwnerEntities(): Observable<any> {
    return this.getHCEs().pipe(
      concatMap(({ owners = [], ownerEntity = [], ownerRels = [] }) =>
        owners.length || ownerEntity.length || ownerRels.length
          ? zip(
              ...[...owners, ...ownerEntity, ...ownerRels].map((item) =>
                this.deleteContact(item.contactId)
              )
            )
          : of([])
      )
    );
  }

  getNameList() {
    return this.getHCEs().pipe(
      map(({ owners = [], ownerRels = [], eeOver120k = [], officers = [] }) => {
        const list = [...owners, ...ownerRels, ...eeOver120k, ...officers].map(
          (ee) => ee.firstName + ' ' + ee.lastName
        );

        return list;
      })
    );
  }

  deleteContactList(idList: Array<string>): Observable<any> {
    const httpOptions: any = {};
    httpOptions.body = idList;
    return this.http.request<DeleteInformation[]>(
      'delete',
      `/plan/contact/bulk-delete`,
      httpOptions
    );
  }

  getEmployees(): Observable<IEmployees> {
    return this.http.get<IEmployees>('/plan/hce');
  }

  getAuthUsers(): Observable<any> {
    return this.http.get('/plan/auth-user');
  }
  
  deleteAllOwnersAndOwnerEntitiesAndAuthSigner(): Observable<any> {
    const deletedDataAPI = forkJoin([this.getHCEs(), this.getAuthUsers()]);
    this.deletedIds = [];
    return deletedDataAPI.pipe(map((_res) => {
      this.deletedIds = _res[0]?.ownerEntity?.concat(_res[0]?.owners, ..._res[0]?.ownerRels);
      _res[1] ? _res[1]?.signer?.map((sign)=>this.deletedIds?.push(sign)) : null;
      return this.deletedIds = this.deletedIds?.map(emp => emp.contactId);
    }));
  }

  deleteAllContact() : Observable<void>{
  return  this.deleteAllOwnersAndOwnerEntitiesAndAuthSigner().pipe
    (map((_res) => {
      this.progressService.progress.getValue() >= REVIEW.step? this.progressService.regressToStep(AUTH.step): null;
      if(_res.length){
        this.deleteContactList(_res).subscribe();
      }
        else{
          of([]);
      }
    }
    ));
  }
}
