export enum STATUS {
  SUBMITTED = 'submitted',
  IN_PROGRESS = 'inProgress',
  END_WITH_QUALIFICATION = 'unqualified',
  END_WITH_CUSTOM_PLAN = 'custom',
  INDIVIDUAL = 'Individual'
};

export enum HTTP_STATUS {
  ERROR_CODE_1000 = "1000",
  TOKEN_EXPIRED = "token_expired"
};