import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ps-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  readonly defaultTotalStepsCount = 9;

  @Input()
  showingSidebar;

  @Input()
  step;

  @Input()
  progress;

  @Output()
  toggleSidebar: EventEmitter<void>;

  constructor() {
    this.toggleSidebar = new EventEmitter();
  }
}
