<p class="main-instruction">
  Tell us about your company.
</p>
<div class="block">
  <p class="inst-centered" *ngIf="from5500">We've populated some of the information for you, please make any necessary
    changes.</p>
  <form (ngSubmit)="checkCompanyTypeChanged()" [formGroup]="form" class="forms-outer">
    <section class="row padding-cls" formGroupName="company">
      <h6 class="section-title sm-mr-0">BASIC INFORMATION</h6>
      <div class="form-group form-group-padding col-12 col-md-6">
        <label>
          <span>Company Name</span>
          <i class="form-error" *ngIf="form.get('company.name').touched">
            <ps-error-span [control]="form.get('company.name')"></ps-error-span>
          </i>
        </label>
        <input type="text" placeholder="Required" class="form-control" formControlName="name" name="company_name">
      </div>
      <div class="form-group form-group-padding col-12 col-md-6">
        <label>
          <span>Corporation Type</span>
          <i class="form-error" *ngIf="form.get('company.type').touched">
            <ps-error-span [control]="form.get('company.type')"></ps-error-span>
          </i>
        </label>
       <app-custom-dropdown [data]="corpTypes" [control]="form.get('company.type')">
         </app-custom-dropdown>
      </div>
      <div class="form-group form-group-padding col-12 col-md-6">
        <label>
          <span>Number of Employees</span>
          <i class="form-error" *ngIf="form.get('company.employeeCount').touched">
            <ps-error-span [control]="form.get('company.employeeCount')"></ps-error-span>
          </i>
        </label>
        <div class="input-wrapper">
          <input type="text" class="form-control" placeholder="Required" formControlName="employeeCount"
            name="employeeCount" [textMask]="maskConfigs.number">
        </div>
      </div>
    </section>
    <section class="row padding-cls" formGroupName="address">
      <h6 class="section-title">COMPANY ADDRESS</h6>
      <div class="form-group form-group-padding col-12 col-md-6">
        <label>
          <span>Street Line 1</span>
          <i class="form-error" *ngIf="form.get('address.street1').touched">
            <ps-error-span [control]="form.get('address.street1')"></ps-error-span>
          </i>
        </label>
        <input type="text" placeholder="Required" class="form-control" formControlName="street1" name="street1">
      </div>
      <div class="form-group form-group-padding col-12 col-md-6">
        <label>
          <span>Street Line 2</span>
          <i class="form-error"
            *ngIf="form.get('address.street2').touched">
            <ps-error-span [control]="form.get('address.street2')"></ps-error-span>
          </i>
        </label>
        <input type="text" placeholder="Optional" class="form-control" formControlName="street2" name="street2">
      </div>
      <div class="form-group form-group-padding col-12 col-md-6">
        <label>City
          <i class="form-error" *ngIf="form.get('address.city').touched">
            <ps-error-span [control]="form.get('address.city')"></ps-error-span>
          </i>
        </label>
        <input type="text" placeholder="Required" class="form-control" formControlName="city" name="city">
      </div>
      <div class="form-group form-group-padding col-lg-3 col-md-3 col-sm-12">
        <label>
          <span>State</span>
          <i class="form-error"
            *ngIf="form.get('address.state').touched">
            <ps-error-span [control]="form.get('address.state')"></ps-error-span>
          </i>
        </label>
        <app-custom-dropdown [data]="states" [control]="form.get('address.state')"> </app-custom-dropdown>
      </div>
      <div class="form-group form-group-padding col-lg-3 col-md-3 col-sm-12">
        <label>
          <span>Zipcode</span>
          <i class="form-error" *ngIf="form.get('address.zipcode').touched">
            <ps-error-span [control]="form.get('address.zipcode')"></ps-error-span>
          </i>
        </label>
        <input type="tel" placeholder="Required" class="form-control" formControlName="zipcode"
          [textMask]="maskConfigs.zipcode" name="zipcode">
      </div>
    </section>
    <section class="row padding-cls" formGroupName="contact">
      <h6 class="section-title">PRIMARY CONTACT</h6>
      <div class="form-group form-group-padding col-12 col-md-6">
        <label>
          <span>First Name</span>
          <i class="form-error" *ngIf="form.get('contact.firstName').touched">
            <ps-error-span [control]="form.get('contact.firstName')"></ps-error-span>
          </i>
        </label>
        <input type="text" placeholder="Required" class="form-control" formControlName="firstName" inputExclude="\d"
          name="first_name">
      </div>
      <div class="form-group form-group-padding col-12 col-md-6">
        <label>
          <span>Last Name</span>
          <i class="form-error" *ngIf="form.get('contact.lastName').touched">
            <ps-error-span [control]="form.get('contact.lastName')"></ps-error-span>
          </i>
        </label>
        <input type="text" placeholder="Required" class="form-control" formControlName="lastName" inputExclude="\d" 
          name="last_name">
      </div>
      <div class="form-group form-group-padding col-12 col-md-6">
        <label>
          <span>Email</span>
          <i class="uil uil-info-circle" placement="top" ngbTooltip="This email will be used to retrieve saved progress and receive status updates for this application. Please be sure to enter the correct email address."></i>
          <i class="form-error" *ngIf="form.get('contact.email').touched">
              <ps-error-span [control]="form.get('contact.email')"></ps-error-span>            
          </i>
        </label>
        <input type="text" placeholder="Required" class="form-control" formControlName="email" name="email">
      </div>
      <div class="form-group form-group-padding col-12 col-md-6">
        <label>
          <span>Phone Number</span>
          <i class="form-error" *ngIf="form.get('contact.phone').touched">
            <ps-error-span [control]="form.get('contact.phone')"></ps-error-span>            
          </i>
        </label>
        <input type="tel" [textMask]="maskConfigs.phone" placeholder="Required" class="form-control"
          formControlName="phone" name="phone">
      </div>
    </section>
    <error-message *ngIf="errorMsg">{{ errorMsg }}</error-message>
    <div class="text-right pt-18 pt-32">
      <cta-button [isLoading]="isLoading" [disabled]="form.invalid" class="full-width">Save & Next</cta-button>
    </div>
  </form>
</div>
<ps-modal [disableClickOff]="true" #modal>
  <div class="text-instructive">
    <h4><span class="icn-img"><img src="assets/img/note.png" /></span>Confirm Email Address?</h4>
    <p>
      <span>Please confirm your email address is correct. We will send a reference code to </span>
      <b class="text-primary modal-email">{{ repEmail || form?.get('contact.email')?.value }}</b>
      <span>. You can return to your saved progress anytime with the code.</span>
    </p>
  </div>
  <div class="modal-footer">
    <div class="modal-footer-cta">
      <cta-button (ctaClick)="submitData()" [isLoading]="isLoading" class="full-width">Confirm and continue</cta-button>
    </div>
  </div>
</ps-modal>