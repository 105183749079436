import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/internal/operators/tap';
import { ProgressService } from 'src/app/shared/services/progress.service';
import { IdentifyResponse } from 'src/app/shared/models/identify-response';
import { IdentifyRequest } from 'src/app/shared/models/identify-request';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  set repEmail(val: string) {
    sessionStorage.setItem('repEmail', val);
  }

  get repEmail(): string {
    return sessionStorage.getItem('repEmail') || '';
  }

  set isPep(val: boolean) {
    sessionStorage.setItem('isPep', val + '');
  }

  get isPep(): boolean {
    return JSON.parse(sessionStorage.getItem('isPep'));
  }

  set accessToken(val) {
    sessionStorage.setItem('access_token', val);
  }

  get accessToken() {
    return sessionStorage.getItem('access_token') || '';
  }

  constructor(
    private http: HttpClient,
    private progressService: ProgressService
  ) {
    //
   }

  resetApp(): void {
    this.progressService.setProgress(0);
    sessionStorage.clear();
  }

  identify(request: IdentifyRequest): Observable<IdentifyResponse> {
    request.ein = request.ein && request.ein.replace(/\D/, '');
    return this.http.post<IdentifyResponse>('/plan/identify', request).pipe(
      tap(({ accessToken, repEmail, isPep }) => {
        this.accessToken = accessToken;
        this.repEmail = repEmail || '';
        this.isPep = isPep;
      })
    );
  }

  identifyFromReportId(id: string): Observable<IdentifyResponse> {
    return this.http
      .post<IdentifyResponse>('/plan/identify?token=' + id, {})
      .pipe(
        tap(({ accessToken, repEmail, isPep }) => {
          this.accessToken = accessToken;
          this.repEmail = repEmail || '';
          this.isPep = isPep;
        })
      );
  }

  retrieveProgress(docRefId: string, ein?: string): Observable<any> {
    return this.http
      .post('/plan/retrieve', { docRefId , ein: ein?.replace(/\D/, '') })
      .pipe(tap(({ accessToken }) => (this.accessToken = accessToken)));
  }
}
