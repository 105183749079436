<div class="sidebar">
  <div class="first-div">
    <ng-container *ngIf="content === 'start_link'">
      <div class="user-info">
        <h5>Starting a new plan setup?</h5>
        <a routerLink="/">Click here</a>
      </div>
    </ng-container>

    <ng-container *ngIf="content === 'retrieve_link'">
      <div class="user-info">
        <h5>Have saved progress?</h5>
        <a routerLink="/retrieve">Retrieve it here</a>
      </div>
    </ng-container>

    <ng-container *ngIf="content === 'breadcrumb'">
      <div class="user-info">
        <h5>{{ companyService?.companyName }}</h5>
        <span class="ein">
          <span class="badge">EIN</span>
          {{ companyService?.ein | ein }}
        </span>
        <div
          *ngIf="(step && progress > 1) && (status !== statusType.SUBMITTED && status !== statusType.END_WITH_QUALIFICATION && status !== statusType.END_WITH_CUSTOM_PLAN)">
          <button class="btn save-btn" (click)="exit()" [disabled]=" (this.progressService.onSaving$|async) ">Save &
            Exit</button>
        </div>
      </div>
      <ul class="steps" *ngIf="!isRetrieve">
        <li *ngFor="let b of filteredBreadcrumb" [class.active]="step === b.step" [class.done]="b.step < progress"
          [class.none]=" (this.progressService.onSaving$|async) ">
          <a class="step-wrapper" *ngIf="b.step < progress" (click)="checkChangesBeforeNav(b.path)"
            [class.notAllowed]=" ((this.progressService.onSaving$|async) || (status === statusType.SUBMITTED)) && b.name!=='Review'">
            <i [ngClass]="b.step == step ? 'fa fa-circle' : 'fa fa-check'"></i>{{b.name}}
          </a>
          <a class="step-wrapper pending" *ngIf="b.step == progress" (click)="checkChangesBeforeNav(b.path)">
            <i [ngClass]="b.step == step ? 'fa fa-circle' : 'far fa-circle'"></i>{{b.name}}
          </a>
          <span class="step-wrapper" *ngIf="b.step > progress">
            <i class="far fa-circle"></i>{{b.name}}
          </span>
        </li>
      </ul>
    </ng-container>
  </div>
  <div class="ext-link"  (click)="openPrivacyPolicy()">
    <div class="d-flex  align-items-center">
      <div class="lock-icon"> <img src="assets/img/lock-icon.svg" alt="lock-icon"></div>
      <span class="text-color"> Privacy Policy</span></div>
    <div> 
    <img src="assets/img/extrenal-link.svg" alt="extrenal-link" class="external-link"></div>
  </div>
</div>



<ps-modal #changesModal class="warning-popup">
  <span class="top-section">
    <img src="assets/img/delete.svg" alt="remove icon">
    <h2>You have unsaved changes.</h2>
  </span>
  <p>Would you like to continue without saving? Any unsaved data on this page will be discarded.</p>
  <div class="button-outer">
    <button class="border-btn" (click)="changesModal.close()">Cancel</button>
    <cta-button (click)="saveChanges()">Discard changes and continue</cta-button>
  </div>
</ps-modal>