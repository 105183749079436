<ng-container [ngSwitch]="formType">
  <ng-container *ngSwitchCase="formTypes.OWNER_INFORMATION_FORM">
    <h6 class="section-title">Owner information</h6>
    <span class="info-box-bg">
      Please enter company ownership information below. The total ownership
      percentage needs to equal 100%.
    </span>
    <form class="form-outer" [formGroup]="ownerInformationForm">
      <div class="row">
        <div class="form-group col-12 col-md-12">
          <label>
            <span>Owner type</span>
            <i
              class="form-error"
              *ngIf="ownerInformationForm.get('type').touched"
            >
            <ps-error-span [control]="ownerInformationForm.get('type')"></ps-error-span>
            </i>
          </label>
          <app-custom-dropdown (onUpdate)="setOwnerInformationForm($event, false)" *ngIf="!ownerInformationForm.get('contactId').value" [data]="ownerType" [control]="ownerInformationForm.get('type')" [key]="'key'"> </app-custom-dropdown>
          <p
            class="value-text"
            *ngIf="ownerInformationForm.get('contactId').value"
          >
            {{ ownerInformationForm?.get("type").value }}
          </p>
        </div>
      </div>
      <div
        class="row"
        *ngIf="ownerInformationForm.get('type').value == 'Individual'"
      >
        <div class="form-group col-12 col-md-12">
          <label>
            <span>Percent</span>
            <i
              class="form-error"
              *ngIf="ownerInformationForm.get('percentage').touched"
            >
            <ps-error-span [control]="ownerInformationForm.get('percentage')"></ps-error-span>
            </i>
          </label>
          <div class="input-wrapper">
            <input
              type="text"
              placeholder="0"
              class="form-control"
              formControlName="percentage"
              [textMask]="mask.percent"
            />
            <span class="postfix">%</span>
          </div>
        </div>
        <div class="form-group col-12 col-md-6 pad-right">
          <label>
            <span>First name</span>

            <i
              class="form-error"
              *ngIf="ownerInformationForm.get('firstName').touched"
            >
            <ps-error-span [control]="ownerInformationForm.get('firstName')"></ps-error-span>
            </i>
          </label>
          <input
            type="text"
            placeholder="Required"
            class="form-control"
            formControlName="firstName"
            name="firstName"
            [inputExclude]="'\\d'"
          />
        </div>
        <div class="form-group col-12 col-md-6 pad-left">
          <label>
            <span>Last name</span>
            <i
              class="form-error"
              *ngIf="ownerInformationForm.get('lastName').touched"
            >
            <ps-error-span [control]="ownerInformationForm.get('lastName')"></ps-error-span>
            </i>
          </label>
          <input
            type="text"
            placeholder="Required"
            class="form-control"
            formControlName="lastName"
            name="lastName"
            [inputExclude]="'\\d'"
          />
        </div>
        <div class="form-group col-12 col-md-12">
          <label>
            <span>Title</span>
            <i
              class="form-error"
              *ngIf="ownerInformationForm.get('title').touched"
            >
            <ps-error-span [control]="ownerInformationForm.get('title')"></ps-error-span>
            </i>
          </label>
          <input
            type="text"
            placeholder="Director"
            class="form-control"
            formControlName="title"
            name="title"
          />
        </div>
        <div class="form-group col-12 col-md-12">
          <label>
            <span>On payroll</span>
            <i
              class="form-error"
              *ngIf="ownerInformationForm.get('onPayroll').touched"
            >
            <ps-error-span [control]="ownerInformationForm.get('onPayroll')"></ps-error-span>
            </i>
          </label>
          <app-custom-dropdown [data]="onPayroll" [control]="ownerInformationForm.get('onPayroll')" [key]="'key'"> </app-custom-dropdown>
        </div>
      </div>
      <div
        class="row"
        *ngIf="ownerInformationForm.get('type').value == 'Entity'"
      >
        <div class="form-group col-12 col-md-12">
          <label>
            <span>Percent</span>
            <i
              class="form-error"
              *ngIf="ownerInformationForm.get('percentage').touched"
            >
            <ps-error-span [control]="ownerInformationForm.get('percentage')"></ps-error-span>
            </i>
          </label>
          <div class="input-wrapper">
            <input
              type="text"
              placeholder="0"
              class="form-control"
              formControlName="percentage"
              [textMask]="mask.percent"
            />
            <span class="postfix">%</span>
          </div>
        </div>
        <div class="form-group col-12 col-md-12">
          <label>
            <span>Entity name</span>
            <i
              class="form-error"
              *ngIf="ownerInformationForm.get('name').touched"
            >
            <ps-error-span [control]="ownerInformationForm.get('name')"></ps-error-span>
            </i>
          </label>
          <input
            type="text"
            placeholder="Required"
            class="form-control"
            formControlName="name"
            name="name"
            [inputExclude]="'\\d'"
          />
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngSwitchCase="formTypes.LINEAL_RELATIVE_FORM">
    <h6 class="section-title">lineal relative employees</h6>
    <span class="info-box-bg">
      Please enter any employees who are lineal relatives of the company owners.
      Lineal relatives include spouses and children.
    </span>
    <form class="form-outer" [formGroup]="linealRelativeFrom">
      <div class="row">
        <div class="form-group col-12 col-md-6 pad-right">
          <label>
            <span>First name</span>
            <i
              class="form-error"
              *ngIf="linealRelativeFrom.get('firstName').touched"
            >
            <ps-error-span [control]="linealRelativeFrom.get('firstName')"></ps-error-span>
            </i>
          </label>
          <input
            type="text"
            placeholder="Required"
            class="form-control"
            formControlName="firstName"
            name="firstName"
            [inputExclude]="'\\d'"
          />
        </div>
        <div class="form-group col-12 col-md-6 pad-left">
          <label>
            <span>Last name</span>
            <i
              class="form-error"
              *ngIf="linealRelativeFrom.get('lastName').touched"
            >
            <ps-error-span [control]="linealRelativeFrom.get('lastName')"></ps-error-span>
            </i>
          </label>
          <input
            type="text"
            placeholder="Required"
            class="form-control"
            formControlName="lastName"
            name="lastName"
            [inputExclude]="'\\d'"
          />
        </div>
        <div class="form-group col-12 col-md-12">
          <label>
            <span>Relationship</span>
            <i
              class="form-error"
              *ngIf="linealRelativeFrom.get('relationship').touched"
            >
            <ps-error-span [control]="linealRelativeFrom.get('relationship')"></ps-error-span>
            </i>
          </label>
          <app-custom-dropdown [data]="relationship" [control]="linealRelativeFrom.get('relationship')" [key]="'key'"> </app-custom-dropdown>
        </div>
        <div class="form-group col-12 col-md-12">
          <label>
            <span>relative of</span>
            <i
              class="form-error"
              *ngIf="linealRelativeFrom.get('relativeOf').touched"
            >
            <ps-error-span [control]="linealRelativeFrom.get('relativeOf')"></ps-error-span>
            </i>
          </label>
          <app-custom-dropdown [data]="relativeOf" [control]="linealRelativeFrom.get('relativeOf')" [key]="'key'"> </app-custom-dropdown>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container *ngSwitchCase="formTypes.HCE_FORM">
    <h6 class="section-title">HCE</h6>
    <span class="info-box-bg">
      Please enter any employees who are highly compensated employees (HCEs).
    </span>
    <form class="form-outer" [formGroup]="hceForm">
      <div class="row">
        <div class="form-group col-12 col-md-12">
          <label>
            <span>First name</span>
            <i class="form-error" *ngIf="hceForm.get('firstName').touched">
              <ps-error-span [control]="hceForm.get('firstName')"></ps-error-span>
            </i>
          </label>
          <input
            type="text"
            placeholder="Required"
            class="form-control"
            formControlName="firstName"
            name="firstName"
            [inputExclude]="'\\d'"
          />
        </div>
        <div class="form-group col-12 col-md-12">
          <label>
            <span>Last name</span>
            <i class="form-error" *ngIf="hceForm.get('lastName').touched">
              <ps-error-span [control]="hceForm.get('lastName')"></ps-error-span>
            </i>
          </label>
          <input
            type="text"
            placeholder="Required"
            class="form-control"
            formControlName="lastName"
            name="lastName"
            [inputExclude]="'\\d'"
          />
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container *ngSwitchCase="formTypes.OFFICERS_FORM">
    <h6 class="section-title">officers</h6>
    <span class="info-box-bg">
      An officer is someone who is an executive in active service with the
      employer. For example, CEO, CFO, President, etc.
    </span>
    <form class="form-outer" [formGroup]="officersForm">
      <div class="row">
        <div class="form-group col-12 col-md-12">
          <label>
            <span>First name</span>
            <i class="form-error" *ngIf="officersForm.get('firstName').touched">
              <ps-error-span [control]="officersForm.get('firstName')"></ps-error-span>
            </i>
          </label>
          <input
            type="text"
            placeholder="Required"
            class="form-control"
            formControlName="firstName"
            name="firstName"
            [inputExclude]="'\\d'"
          />
        </div>
        <div class="form-group col-12 col-md-12">
          <label>
            <span>Last name</span>
            <i class="form-error" *ngIf="officersForm.get('lastName').touched">
              <ps-error-span [control]="officersForm.get('lastName')"></ps-error-span>
            </i>
          </label>
          <input
            type="text"
            placeholder="Required"
            class="form-control"
            formControlName="lastName"
            name="lastName"
            [inputExclude]="'\\d'"
          />
        </div>
        <div class="form-group col-12 col-md-12">
          <label>
            <span>Title</span>
            <i class="form-error" *ngIf="officersForm.get('title').touched">
              <ps-error-span [control]="officersForm.get('title')"></ps-error-span>
            </i>
          </label>
          <input
            type="text"
            placeholder="Required"
            class="form-control"
            formControlName="title"
            name="title"
          />
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container *ngSwitchCase="formTypes.AUTH_PLAN_ADMIN">
    <h6 class="section-title">{{!eeService.isSignerForm ?'Admin information':'Authorized Signer Information'}}</h6>
    <form class="form-outer" [formGroup]="authPlanAdmin">
      <div class="row">
        <div class="form-group col-12 col-md-6">
          <label>
            <span>First name</span>
            <i
              class="form-error"
              *ngIf="authPlanAdmin.get('firstName').touched"
            >
            <ps-error-span [control]="authPlanAdmin.get('firstName')"></ps-error-span>
          </i>
          </label>
          <input
            type="text"
            placeholder="Required"
            class="form-control"
            formControlName="firstName"
            name="firstName"
            [inputExclude]="'\\d'"
          />
        </div>
        <div class="form-group col-12 col-md-6">
          <label>
            <span>Last name</span>
            <i class="form-error" *ngIf="authPlanAdmin.get('lastName').touched">
              <ps-error-span [control]="authPlanAdmin.get('lastName')"></ps-error-span>
            </i>
          </label>
          <input
            type="text"
            placeholder="Required"
            class="form-control"
            formControlName="lastName"
            name="lastName"
            [inputExclude]="'\\d'"
          />
        </div>
        <div class="form-group col-12 col-md-12">
          <label>
            <span>Title</span>
            <i class="form-error" *ngIf="authPlanAdmin.get('title').touched">
              <ps-error-span [control]="authPlanAdmin.get('title')"></ps-error-span>
            </i>
          </label>
          <input
            type="text"
            placeholder="Required"
            class="form-control"
            formControlName="title"
            name="title"
          />
        </div>
        <div class="form-group col-12 col-md-12">
          <label>
            <span>Email</span>
            <i class="form-error" *ngIf="authPlanAdmin.get('email').touched">
              <ps-error-span [control]="authPlanAdmin.get('email')"></ps-error-span>
            </i>
          </label>
          <input
            type="email"
            placeholder="Required"
            class="form-control"
            formControlName="email"
            name="email"
          />
        </div>
      </div>
    </form>
  </ng-container>

  <div class="align-right">
    <span  [class.nonClick]="!currentForm?.valid">
      <cta-button
      [class.nonClick]="isLoading"
      [isLoading]="isLoading"
      [disabled]="!currentForm?.valid"
      (click)="submit(currentForm?.value)"
      >Save</cta-button
    >
    </span>
  </div>
</ng-container>
