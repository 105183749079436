import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { finalize } from 'rxjs/internal/operators/finalize';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { QuestionService, Question } from 'src/app/shared/services/question.service';
import { ProgressService } from 'src/app/shared/services/progress.service';
import { ContributionsService } from 'src/app/shared/services/contributions.service';
import { PLAN_BASICS } from 'src/app/shared/constants/steps.data';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { Redirect } from 'src/app/shared/utils/redirect.util';
import { Message } from 'src/app/shared/enums/message.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ps-questions-summary',
  templateUrl: './questions-summary.component.html',
  styleUrls: ['./questions-summary.component.scss'],
})
export class QuestionsSummaryComponent implements OnInit, OnDestroy {
  questions: Question[];
  isLoading: boolean;
  isDataLoading: boolean;
  errorMsg: string;
  @ViewChild('infoPopup', { static: true })
  infoPopup: ModalComponent;

  constructor(
    private qService: QuestionService,
    private router: Router,
    private progressService: ProgressService,
    private contributionService: ContributionsService
  ) {
    progressService.onSave = this.onSave.bind(this);
  }

  ngOnInit(): void {
    this.initQuestionAndAnswer();
  }

  ngOnDestroy(): void {
    this.progressService.onSave = null;
  }

  get filteredQuestions(): Question[] {
    return this.questions.filter((q) => q.enabled);
  }

  hasEmptyValue(): boolean {
    return !!(this.questions && this.questions.find((q) => q.value === null));
  }

  setAnswer(qid, value): void {
    this.progressService.hasChanges = true;
    this.qService.setAnswer(qid, value);
  }

  onSave(): Observable<any> {
    this.isLoading = true;
    return this.qService.getAnswers().pipe(
      switchMap((res) =>
        !res ? this.qService.updateQualificationInfo(this.questions) : of(res)
      ),
      switchMap(({ hasPriorPlan }) => {
        const newHasPriorPlan = this.qService.getQuestion('hasPriorPlan').value;
        if (!hasPriorPlan && newHasPriorPlan) {
          this.resetContributions();
        }
        return this.qService.updateQualificationInfo(this.questions);
      }),
      finalize(() => (this.isLoading = false)),
      catchError((e) => {
        this.errorMsg = Message.SOMETHING_WENT_WRONG;
        return throwError(e);
      })
    );
  }

  updateInfo(isCallFromHelp: boolean): void {
    this.progressService.onSaving$.next(true);
    this.onSave().subscribe(() => {
      this.progressService.updateProgress(PLAN_BASICS.step);
      if (!isCallFromHelp) {
        this.progressService.onSaving$.next(false);
        this.router.navigateByUrl(PLAN_BASICS.url);
      } else {
        sessionStorage.clear();
        Redirect.replace(environment.landingPage);
      }
    });
  }

  private resetContributions(): void {
    this.contributionService.resetAutoEnroll();
    this.contributionService.deleteAllContributions();
  }

  helpModal(): void {
    this.infoPopup.open();
  }

  checkQuestionAnswer(): void {
    const answers = this.questions.reduce((answers, curr) => {
      answers[curr.id] = curr.value;
      return answers;
    }, {});
    if (Object.keys(answers).every((k) => !answers[k])) {
      this.updateInfo(false);
    } else {
      this.helpModal();
    }
  }

  initQuestionAndAnswer(): void {
    this.isDataLoading = true;
    this.qService.initQuestionAndAnswer().subscribe((questions) => {
      this.questions = questions;
      this.isDataLoading = false;
    });
  }

}
