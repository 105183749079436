import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ps-error-span',
  templateUrl: './error-span.component.html',
  styleUrls: ['./error-span.component.css'],
})
export class ErrorSpanComponent {
  @Input() control: FormControl;

  get errorMessages(): string[] {
    const errors = this.control.errors;
    if (!errors) {
      return [];
    }
    return Object.keys(errors).map(
      (errorKey) => this.errorMessagesMapping[errorKey]
    );
  }

  errorMessagesMapping = {
    required: 'Required',
    min: 'Invalid',
    max: 'Invalid',
    maxlength: 'Too long',
    email: 'Invalid',
    pattern: 'Invalid',
    invalidEmail: 'Invalid'
  };
}
