import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ps-dropdown-accent',
  templateUrl: './dropdown-accent.component.html',
  styleUrls: ['./dropdown-accent.component.scss'],
})
export class DropdownAccentComponent implements OnChanges {
  @Input() selectedId: string;
  @Input() options: DropdownAccentOption[];
  @Input() readonly: boolean;
  @Input() placeholder: string;
  @Output() onUpdate = new EventEmitter<DropdownAccentOption>();

  option: DropdownAccentOption;

  constructor(dropdown: NgbDropdownConfig) {
    dropdown.placement = ['bottom'];
  }

  ngOnChanges() {
    if (!this.placeholder && this.options && !this.option) {
      let selectedOption;
      if (this.selectedId) {
        selectedOption = this.options.find(({ id }) => id === this.selectedId);
      }
      this.select(selectedOption || this.options[0]);
    }
  }

  select(option: DropdownAccentOption) {
    this.option = option;
    this.onUpdate.emit(option);
  }
}

export interface DropdownAccentOption {
  id: string;
  label: string;
  tag?: string;
  value?: any;
}
