<div class="plans-cards">
  <div class="row">
    <div class="col-md-6 col-sm-12 select-area" *ngFor="let PlanOption of planOptions; let i = index" (click)="onSelectPlanOption(PlanOption)" [ngClass]="{'margin-class': isExpandChild, 'none-class': !isExpandChild, 'disable-class': activeSafeHarbor, 'child-match-cls': PlanOption.labelTag == this.planSelectionType.MATCH}">    
      <input type="radio" id="{{ PlanOption.type }}" name="radio-group" [ngClass]="{'checked': selectedPlanOption == PlanOption}">
      <label for="{{ PlanOption.type}}" class="select-label">
        <div class="plan-card">
          <div class="disable-alert">
            <p>You are unable to select Safe Harbor.<span class="plan-tooltip"><i class="uil uil-info-circle" placement="bottom" ngbTooltip="{{tooltipMessage.SAFE_HARBOR_DISABLE_MESSAGE}}"></i></span></p>
          </div>
          <div class="plan-head" [ngClass]="{'disable-text': activeSafeHarbor && PlanOption.cardLabel === cardLabel.SAFE_HARBOR}">
            <div class="plan-heading">
              <span class="plan-tag" [ngClass]="{'tag-safe': PlanOption.labelTag == this.planSelectionType.SH_MATCH, 'tag-match' : PlanOption.labelTag == this.planSelectionType.MATCH, 'tag-custom' : PlanOption.labelTag == this.planSelectionType.CUSTOM, 'tag-starter' : PlanOption.labelTag == this.planSelectionType.STARTER }">{{ PlanOption.tagLabel }}</span>
              <h4 *ngIf="PlanOption.cardLabel === cardLabel.SAFE_HARBOR">Recommend<img src="{{!activeSafeHarbor? 'assets/img/tick-icon.svg': 'assets/img/gray-tick.svg'}}" alt="tick mark"></h4>
            </div>
            <div class="plan-left">
              <span *ngIf="(PlanOption.labelTag !== this.planSelectionType.SH_MATCH)" [ngClass]="{'tag-match' : PlanOption.labelTag == this.planSelectionType.MATCH, 'tag-custom' : PlanOption.labelTag == this.planSelectionType.CUSTOM, 'tag-starter' : PlanOption.labelTag == this.planSelectionType.STARTER }" class="icn-img">
                <img [src]="PlanOption.icon" alt="{{PlanOption.tagLabel}}" />
              </span>
              <span *ngIf="(PlanOption.labelTag === this.planSelectionType.SH_MATCH)" class="icn-img tag-safe">
                <img *ngIf="!activeSafeHarbor" [src]="PlanOption.icon" alt="Safe Harbor" />
                <img *ngIf="activeSafeHarbor" src="assets/img/d-sh-icon.svg" alt="Safe Harbor" />
              </span>
              <h4 class="plan-name">{{ PlanOption.cardLabel }}</h4>
            </div>
          </div>
          <div class="plan-desc-card" [ngClass]="{'disable-text': activeSafeHarbor && PlanOption.cardLabel === cardLabel.SAFE_HARBOR}">
            <div class="plan-desc">
              <p>{{ PlanOption.description }}</p>
            </div>
          </div>
          <div class="plan-btm" [ngClass]="{'disable-text': activeSafeHarbor && PlanOption.cardLabel === cardLabel.SAFE_HARBOR}">
            <div class="plan-high" >
              <label>Maximum company contribution</label>
              <h6>{{ PlanOption.companyContribution }}</h6>
            </div>
            <div class="plan-high">
              <label>Vesting</label>
              <h6 [class.vest-label]="PlanOption.vesting.label.length > 0">
                <a [ngClass]="getLabelColor(i)" (click)="openVestingPopUp(i)" *ngFor="let i of PlanOption.vesting.label">{{i}}</a>
              </h6>
            </div>
          </div>
        </div>
      </label>
    </div>
  </div>
  <div class="text-left mt-6" *ngIf="!isExpandChild">
    <a class="btn-text-primary" (click)="showFullData()">{{presentStep === currentStage.CURRENT_STEP_TWO ? button_value.SEE_OTHER_PLAN_DESIGNS : button_value.SEE_OTHER_TYPES}}</a>
  </div>
</div>
<ps-modal #vesting class="calc-c" [disableClickOff]="true" [closeButton]="true">
  <app-vesting-schedule [vestingContent]="vestingDetails"></app-vesting-schedule>
</ps-modal>