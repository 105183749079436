export enum URLS {
  STARTUP_TAX_CREDIT_URL = "https://www.irs.gov/retirement-plans/retirement-plans-startup-costs-tax-credit",
  SMALL_EMPLOYER_TAX_CREDIT = "https://www.asppa.org/news/where-credit-due-tax-credits-small-employer-plans-under-secure-20",
  RETRIEVE = "/retrieve",
  REVIEW_DETAILS = "review-details"
};

export enum URL_VALIDATION {
  HTTP = "http"
};
