export const DEF_LIMIT = 19500;
export const CATCH_UP_LIMIT = 6500;
export const TOTAL_CONT_LIMIT = 57000;
export const COMP_LIMIT = 285000;
export const HCE_THRESHOLD = 130000;
export const OWNER_INFORMATION_LIMIT = 100;
export const LAST_STEP = 4;

export const LIMITS = {
  DEF_LIMIT,
  CATCH_UP_LIMIT,
  TOTAL_CONT_LIMIT,
  COMP_LIMIT,
  HCE_THRESHOLD,
  OWNER_INFORMATION_LIMIT,
  LAST_STEP
};