import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';

@Component({
  selector: 'ps-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  inputValue: boolean;
  isLoading: boolean;

  @Output() valueSubmitted = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  @ViewChild('helpPopup', { static: true })
  helpPopup: ModalComponent;

  helpModal(): void {
    this.helpPopup.open();
  }

  confirmPlanSubmission(value: boolean){
    this.isLoading = true;
    this.valueSubmitted.emit(value);
  }

}
