import { DecimalPipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbModule,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { TextMaskModule } from 'angular2-text-mask';
import { LayoutModule } from '@angular/cdk/layout';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { IdentifyComponent } from 'src/app/pages/identify/identify.component';
import { CompanyInfoComponent } from 'src/app/pages/company-info/company-info.component';
import { QuestionsComponent } from 'src/app/pages/questions/questions.component';
import { EmployeesComponent } from 'src/app/pages/employees/employees.component';
import { PlanBasicsComponent } from 'src/app/pages/plan-basics/plan-basics.component';
import { AuthorizationComponent } from 'src/app/pages/authorization/authorization.component';
import { ReviewComponent } from 'src/app/pages/review/review.component';
import { ConfirmationComponent } from 'src/app/pages/confirmation/confirmation.component';
import { CtaButtonComponent } from 'src/app/shared/components/cta-button/cta-button.component';
import { ErrorMessageComponent } from 'src/app/shared/components/error-message/error-message.component';
import { QuestionsSummaryComponent } from 'src/app/pages/questions/questions-summary/questions-summary.component';
import { BlockBackBtnComponent } from 'src/app/shared/components/block-back-btn/block-back-btn.component';
import { QuestionRowComponent } from 'src/app/shared/components/question-row/question-row.component';
import { InputBoolComponent } from 'src/app/shared/components/input-bool/input-bool.component';
import { DropdownAccentComponent } from 'src/app/shared/components/dropdown-accent/dropdown-accent.component';
import { ReviewCompanyComponent } from 'src/app/pages/review/review-company/review-company.component';
import { ReviewEmployeesComponent } from 'src/app/pages/review/review-employees/review-employees.component';
import { ReviewUsersComponent } from 'src/app/pages/review/review-users/review-users.component';
import { ReviewPlanComponent } from 'src/app/pages/review/review-plan/review-plan.component';
import { CustomDateAdapter } from 'src/app/shared/utils/custom-date-adapter';
import { ContributionCardComponent } from 'src/app/shared/components/contribution-card/contribution-card.component';
import { SummaryComponent } from 'src/app/pages/summary/summary.component';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { RetrieveComponent } from 'src/app/pages/retrieve/retrieve.component';
import { CustomDateFormatter } from 'src/app/shared/utils/custom-date-formatter';
import { EinPipe } from 'src/app/shared/pipes/ein.pipe';
import { InputExcludeDirective } from 'src/app/shared/directives/input-exclude.directive';
import { interceptorProvider } from 'src/app/shared/interceptor/interceptor';
import { SidebarComponent } from 'src/app/core/sidebar/sidebar.component';
import { NavbarComponent } from 'src/app/core/navbar/navbar.component';
import { MessageWarningComponent } from 'src/app/shared/components/message-warning/message-warning.component';
import { EntrySchedulePipe } from 'src/app/shared/pipes/entry-schedule.pipe';
import { FeaturesComponent } from 'src/app/pages/features/features.component';
import { AssetSliderComponent } from 'src/app/shared/components/asset-slider/asset-slider.component';
import {
  PasswordToggleDirective,
  PasswordToggleComponent,
} from 'src/app/shared/directives/password-toggle.directive';
import { MatchCalculatorComponent } from 'src/app/pages/plan-basics/match-calculator/match-calculator.component';
import { GetHelpWidgetComponent } from 'src/app/shared/components/get-help-widget/get-help-widget.component';
import { LoadingDotsComponent } from 'src/app/shared/components/loading-dots/loading-dots.component';
import { AccordionComponent } from 'src/app/shared/components/accordion/accordion.component';
import { CommonFormComponent } from 'src/app/shared/components/common-form/common-form.component';
import { PlanCardsComponent } from 'src/app/pages/plan-basics/plan-cards/plan-cards.component';
import { CompanyContributionComponent } from 'src/app/pages/plan-basics/company-contribution/company-contribution.component';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { HelpCardComponent } from 'src/app/shared/components/help-card/help-card.component';
import { ErrorSpanComponent } from 'src/app/shared/components/error-span/error-span.component';
import { CustomDropdownComponent } from 'src/app/shared/components/custom-dropdown/custom-dropdown.component';
import { VestingScheduleComponent } from 'src/app/pages/plan-basics/plan-cards/modal/vesting-schedule/vesting-schedule.component';
import { MaxValueDirective } from 'src/app/shared/directives/max-value.directive';
import { CompanyTypeChangeModalComponent } from 'src/app/shared/components/company-type-change-modal/company-type-change-modal.component';


@NgModule({
  declarations: [
    AppComponent,
    IdentifyComponent,
    CompanyInfoComponent,
    QuestionsComponent,
    EmployeesComponent,
    PlanBasicsComponent,
    AuthorizationComponent,
    ReviewComponent,
    ConfirmationComponent,
    CtaButtonComponent,
    ErrorMessageComponent,
    QuestionsSummaryComponent,
    BlockBackBtnComponent,
    QuestionRowComponent,
    InputBoolComponent,
    DropdownAccentComponent,
    ReviewCompanyComponent,
    ReviewEmployeesComponent,
    ReviewUsersComponent,
    ReviewPlanComponent,
    ContributionCardComponent,
    SummaryComponent,
    ModalComponent,
    RetrieveComponent,
    EinPipe,
    InputExcludeDirective,
    SidebarComponent,
    NavbarComponent,
    MessageWarningComponent,
    EntrySchedulePipe,
    FeaturesComponent,
    AssetSliderComponent,
    PasswordToggleDirective,
    PasswordToggleComponent,
    MatchCalculatorComponent,
    GetHelpWidgetComponent,
    LoadingDotsComponent,
    AccordionComponent,
    CommonFormComponent,
    PlanCardsComponent,
    CompanyContributionComponent,
    ConfirmModalComponent,
    HelpCardComponent,
    ErrorSpanComponent,
    CustomDropdownComponent,
    VestingScheduleComponent,
    MaxValueDirective,
    CompanyTypeChangeModalComponent
  ],
  entryComponents: [PasswordToggleComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    TextMaskModule,
    LayoutModule,
    NgSelectModule
  ],
 
  providers: [
    interceptorProvider,
    { provide: NgbDateAdapter, useClass: CustomDateAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateFormatter },
    DecimalPipe
  ],
  bootstrap: [AppComponent]
})

export class AppModule {}
