import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateAdapter extends NgbDateAdapter<string> {
  fromModel(dateStr: string): NgbDateStruct {
    const date = new Date(dateStr);
    return (date && date.getFullYear) ? {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()} : null;
  }

  toModel(date: NgbDateStruct): string {
    return date && date.year && date.month ? `${date.month}/${date.day}/${date.year}` : null;
  }
}