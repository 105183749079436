import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { pluck } from 'rxjs/internal/operators/pluck';
import { tap } from 'rxjs/internal/operators/tap';
import { concatMap } from 'rxjs/internal/operators/concatMap';
import { ICalculation, ICalculationResponse, IEmpSalaries, IRSLimits } from 'src/app/shared/models/irs-limits.model';
import { PlanService } from 'src/app/shared/services/plan.service';

@Injectable({
  providedIn: 'root'
})
export class IrsLimitsService {

  constructor(
    private http: HttpClient,
    private planService: PlanService
  ) { }

  getLimitsByEffectiveDate(): Observable<IRSLimits> {
    let effectiveDate: string;
    return this.planService.getPlanBasics().pipe(
      pluck('effectiveDate'),
      tap(res => effectiveDate = res ? new Date(res).getFullYear().toString() : new Date().getFullYear().toString()),
      concatMap(res => this.get(effectiveDate))
    ).pipe(
      tap(limits => limits.differentYearLimits = limits.year !== effectiveDate)
    );
  }

  get(year: string): Observable<IRSLimits> {
    return this.http.get<IRSLimits>(`/plan/irs-limits/${year}`);
  }

  getTaxCredit(calc: ICalculation): Observable<ICalculationResponse> {
    return this.http.post<ICalculationResponse>(`/plan/taxCredit`,calc);
  }

  getEmployeeCountBySalary(): Observable<IEmpSalaries[]> {
    return this.http.get<IEmpSalaries[]>(`/plan/stp-emp-count`);
  }
}
