import { Component, OnChanges, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { CompanyService } from 'src/app/shared/services/company.service';
import { PlanService } from 'src/app/shared/services/plan.service';
import { ProgressService } from 'src/app/shared/services/progress.service';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { STATUS } from 'src/app/shared/enums/status.enum';
import { Message } from 'src/app/shared/enums/message.enum';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { Count } from 'src/app/shared/enums/common-form.enum';
import { COMMON_LINKS } from 'src/app/shared/constants/external-link.const';

@Component({
  selector: 'ps-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnChanges {
  @Input() currentPath!: string;
  @Input() step!: number;
  @Input() progress!: number;
  @Input() isRetrieve!: boolean;
  breadcrumb!: any[];
  filteredBreadcrumb!: any[];
  content!: string;
  nextPath!: string;
  statusType = STATUS;
  @ViewChild('changesModal', { static: true })
  modal!: ModalComponent;
  status!: string;
  get nonProfit$(): Observable<boolean> {
    return this.companyService.nonProfit;
  }

  constructor(
    private router: Router,
    public companyService: CompanyService,
    private planService: PlanService,
    public progressService: ProgressService,
    private employeeService: EmployeeService
  ) { }

  ngOnChanges(): void {
    if (this.currentPath === undefined) {
      return;
    }

    if (this.currentPath === Message.RETRIEVE) {
      this.content = Message.START_LINK;
    } else if (this.currentPath === Message.EMPTY_PATH) {
      this.content = Message.RETRIEVE_LINK;
    } else {
      this.content = Message.BREADCRUMB;

      this.planService.getStatus().subscribe(status => {
        this.status = status;
        if (!this.breadcrumb) {
          this.initBreadcrumb();
        }
        this.filteredBreadcrumb = this.breadcrumb;
      });
    }
  }

  initBreadcrumb() {
    this.breadcrumb = this.router.config
      .filter(({ data }) => data && data.navName !== undefined)
      .map(({ data }) => ({
        name: data.navName,
        path: data.navUrl,
        step: data.step
      }));
  }

  checkChangesBeforeNav(path): void {
    if (this.progressService.hasChanges && this.progress > 1) {
      this.nextPath = path;
      this.modal.open();
    } else {
      this.router.navigateByUrl(path);
    }
  }

  onCloseModal(): void {
    this.modal.close();
  }

  saveChanges(): void {
    this.modal.close();
    this.router.navigateByUrl(this.nextPath);
  }

  exit(): void {
    if (!this.progressService.onSave || this.progressService.changedToQuarterDate$.getValue()) {
      this.reset();
    } else {
      this.progressService.onSave().subscribe(() => {
        if (this.progressService.changedCompanyType || 
          this.progressService.categoryStatusType.getValue() >= Count.THREE || 
          this.progressService.categoryStatusType.getValue() === Count.ONE)
          this.setCompanyTypeStatus();
        else
          this.reset();
      });
    }
  }

  openPrivacyPolicy(): void {
    window.open(COMMON_LINKS.PRIVACY_POLICY, "_blank");
  }

  // set company type status 
  setCompanyTypeStatus(): void {
    if(this.progressService.changedCompanyType || this.progressService.categoryStatusType.getValue() === Count.ONE)
        this.progressService.setCompanyTypeStatus(Count.TWO);
    
    if(this.progressService.categoryStatusType.getValue() === Count.THREE && !this.progressService.changedCompanyType)
      this.progressService.setCompanyTypeStatus(Count.FOUR);
      this.companyService.isNonProfit ? this.employeeService.deleteAllContact().subscribe(() => { this.reset(); }) : this.reset();
      }

  reset(): void {
    sessionStorage.clear();
    location.replace('/');
  }
}
