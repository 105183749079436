import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { IPlanFeatures } from 'src/app/pages/features/models/plan-features.model';
import { PlanService } from 'src/app/shared/services/plan.service';

@Injectable({
  providedIn: 'root',
})
export class FeaturesResolver implements Resolve<IPlanFeatures> {
  constructor(private planService: PlanService) { }

  resolve() {
    return this.planService.getPlanFeatures();
  }
}
