<div class="app-wrapper">
  <ps-navbar [step]="step" [progress]="progress" [showingSidebar]="showSidebar"
    (toggleSidebar)="showSidebar = !showSidebar">
    <button class="pricing-btn d-sm-inline-block" (click)="openPricingModal()" *ngIf = "step && progress > 1 || isRetrieve">Pricing</button>
    <!-- <button class="nav__btn" [class.active]="showSummary" (click)="toggleSummary()" *ngIf="step && progress > 1">
      <i class='uil uil-check mr-1'></i>
      <span>Progress saved</span>
    </button> -->
  </ps-navbar>
  <div class="body">
    <div class="sidebar" *ngIf = "showSidebar" @slide>
      <ps-sidebar [currentPath]="currentPath" [step]="step" [progress]="progress" [isRetrieve]="isRetrieve"></ps-sidebar>
    </div>
    <div class="content" #content>
      <div class="view-container">
        <router-outlet></router-outlet>
      </div>
    </div>
    <ps-summary *ngIf = "step && progress > 1" [show]="showSummary" (clickOff)="toggleSummary()"></ps-summary>
  </div>
  <ps-get-help-widget></ps-get-help-widget>
  <div class="footer d-none d-sm-block">
    <span>Copyright © 2020 Slavic Integrated Administration, Inc</span>
  </div>
</div>
<ps-modal maxWidth="800px" #pricingModal class="pricing-modal">
  <ps-features *ngIf= "step||isRetrieve "></ps-features>
</ps-modal>
<app-company-type-change-modal *ngIf= "step && progress > 1" [progress]="progress" #companyTypeModal></app-company-type-change-modal>