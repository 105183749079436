<div class="block">
  <button class="btn block__edit" [routerLink]="['/authorization']" *ngIf="canEdit">Edit</button>
  <h6 class="section-title">authorized signer</h6>
  <div class="row">
    <div class="user-card col-12 col-md-6" *ngFor="let sign of users?.signer">
        <div class="info-container">
          <p class="full-name break-word">{{sign?.firstName}} {{sign?.lastName}}</p>
          <p class="break-word">{{sign?.email}}</p>
        </div>
    </div>
  </div>
  <div class="signer-row"></div>
  <h6 class="section-title">Plan admins</h6>
  <div class="row">
    <div class="user-card col-12 col-md-6" *ngFor="let admin of users?.authUser">
      <div class="info-container">
        <p class="full-name break-word">{{admin?.firstName}} {{admin?.lastName}}</p>
        <p class="break-word">{{admin?.email}}</p>
      </div>
    </div>
  </div>
</div>