import { Component, Input } from '@angular/core';
import { IAuthData } from 'src/app/shared/models/auth-user.model';
@Component({
  selector: 'ps-review-users',
  templateUrl: './review-users.component.html',
  styleUrls: ['./review-users.component.scss']
})
export class ReviewUsersComponent {

  @Input() users: IAuthData;
  @Input() canEdit: boolean;

}
