
export const NON_PROFIT = 'Non-profit';

export const CORPTYPES: string[] = [
  'Corporation',
  'LLC',
  'Sole Proprietor',
  'S Corporation',
  'Partnership',
  NON_PROFIT,
  'Prof. Assoc'
];
