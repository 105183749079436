import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { filter } from 'rxjs/internal/operators/filter';
import { PlanService } from 'src/app/shared/services/plan.service';
import { ProgressService } from 'src/app/shared/services/progress.service';
import { CONFIRM } from 'src/app/shared/constants/steps.data';
import { CompanyService } from 'src/app/shared/services/company.service';
import { ICompanyInformation } from 'src/app/shared/models/company-info-model';
import { EmployeeService } from 'src/app/shared/services/employee.service';
import { ContributionsService } from 'src/app/shared/services/contributions.service';
import { IAuthData } from 'src/app/shared/models/auth-user.model';
import { ApiResponse } from 'src/app/shared/enums/api-response.enum';
import { STATUS } from 'src/app/shared/enums/status.enum'
import { Message } from 'src/app/shared/enums/message.enum';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Redirect } from 'src/app/shared/utils/redirect.util';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ps-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {
  canEdit: boolean;
  isLoading: boolean;
  companyInfo: ICompanyInformation;
  employeeData;
  authUsers: IAuthData;
  plan;
  planDetails;
  isDataLoading: boolean = true;
  statusType = STATUS;
  endStatus: string;
  message = Message;
  token: string;
  docRefId: string;
  submittedDate: string;
  isRetrieve: boolean;
  constructor(
    private planService: PlanService,
    private router: Router,
    private progressService: ProgressService,
    private companyService: CompanyService,
    private eeService: EmployeeService,
    private contService: ContributionsService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.isLoading = false;
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.isRetrieve = event.url.includes('review-details');
    });
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams['token'];
    if (this.token) {
      this.retrieveData();
    }
    else {
      this.initData();
    }
  }

  onSubmit(): void {
    this.isLoading = true;
    this.progressService.onSaving$.next(true);
    this.planService.submitApplication().subscribe(() => {
      this.progressService.updateProgress(CONFIRM.step);
      this.progressService.onSaving$.next(false);
      this.router.navigate(['/confirmation']);
    });
  }

  getData(): void {
    this.isDataLoading = true;
    const company = this.companyService.getCompanyInfo();
    const employees = this.eeService.getHCEs();
    const authUsers = this.planService.getAuthUsers();
    const plan = this.planService.getPlanBasics();
    const planDetails = this.contService.getContributions();
    forkJoin([company, employees, authUsers, plan, planDetails])
      .subscribe(res => {
        this.companyInfo = res[0];
        this.employeeData = res[1];
        this.authUsers = res[2];
        this.plan = res[3];
        this.planDetails = res[4];
        this.isDataLoading = false;
      }, err => {
        this.isDataLoading = false;
      });
  }

  initData(): void {
    this.planService.getPlanStatus().subscribe((data) => {
      this.canEdit = data.status === ApiResponse.INPROGRESS;
      this.endStatus = data.status;
      this.submittedDate = data.timestamps.submitted;
      this.docRefId = data.docRefId;
      if (this.endStatus === this.statusType.SUBMITTED || this.endStatus === this.statusType.IN_PROGRESS) {
        this.getData();
      }
      else {
        this.isDataLoading = false;
      }
    }, err => { this.isDataLoading = false; });
  }

  retrieveData(): void {
    this.isDataLoading = true;
    this.authService.identifyFromReportId(this.token).subscribe(
      (data) => {
        this.companyService.peoId = data.peoId;
        this.companyService.peoName = data.peo;
         this.companyService.ein = data.ein;
        this.authService
          .retrieveProgress(data.planRefId).subscribe(res => {
            this.initData();
          }, err => {
            this.isDataLoading = false;
          })
      },
      ({ error }) => {
        this.isDataLoading = false;
      }
    );
  }

  returnToSlavic(): void {
    sessionStorage.clear();
    Redirect.replace(environment.slavic401kUrl);
  }
}
